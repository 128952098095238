<template>
        <!-- Modal -->
        <div class="modal fade"  id="userEmployeeCountryModal" style="z-index:9999999999"  aria-labelledby="userEmployeeCountryModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userEmployeeCountryModalLabel" v-if="this.employee_country_view_type=='edit'">Edit Employee Linked To Country</h5>
                <h5 class="modal-title" id="userEmployeeCountryModalLabel" v-else-if="this.employee_country_view_type=='view'">View Employee Linked To Country</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
              <div v-if="this.employee_country_view_type=='add' || this.employee_country_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                    
        
                  <span>
                  
                    <div class="row">

                      <div class="col">
                        <div class="form-group">
                          <label>User</label>

                          <input
                            type="text"
                            class="form-control"
                            style="min-width:100%"
                            readonly
                            :class="{ form_error: user_fullnameErr}"
                            placeholder="Full Name"
                            @blur="user_fullnameVal($event.target.value)" 
                            v-model.trim="formData.user_fullname"
                          />
                          <div
                            :class="{ text_error: user_fullnameErr  }"
                           v-if="user_fullnameErr">
                          {{ user_fullnameErr }} 
                          </div>
                        </div>
                      </div>
                    </div>





                  <div class="row">
                                             <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Country</label>
                          <!-- <select
                            class="form-control country"
                            :class="{ form_error: countryErr }"
                            ref="count"
                            @change="countryVal($event.target.value)"
                            v-model.trim="formData.country_id">
                            <option value="">--Choose a Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select> -->
                          <!-- <Select2 v-model="formData.country_id" :options="getCountries"  :settings="{ placeholder: 'Specifies the placeholder through settings', width: '50%', ajax: ajax }" @change="countryVal($event.target.value)"  /> -->
                         <v-select :options="getCountries"  class="form-control select1" v-model="formData.country_id"  :reduce="country => country.id" label="country" option:selected="countryVal($event.target.value)" />

                        
                           

                          
                        
                          <div
                            :class="{ text_error: countryErr }"
                            v-if="countryErr"
                          >
                            {{ countryErr }}
                          </div>
                        </div>
                      </div>





                  


                  

       



                        <div class="col">
                        <div class="form-group">
                          <label>Is Active</label>
                          <select
                            class="form-control"
                            style="min-width:100%"
                            :class="{ form_error: is_activeErr}"
                             @change="is_activeVal($event.target.value)"
                            v-model.trim="formData.is_active">
                            <option value="">--Choose An Option--</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          
                          <div
                            :class="{ text_error: is_activeErr}"
                            v-if="is_activeErr"
                          >
                            {{ is_activeErr }}
                          </div>
                        </div>
                      </div>


                    
                       






                        


                           






               


                    </div>



                
                  </span>
                

                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" >Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="this.employee_country_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="userEmployeeCountryModalLabel" data-bs-dismiss="modal" v-if="this.employee_country_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>




                  <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>User Employee</td>
                                <td></td>
                                </tr>

                               <tr>
                                <td>Linked Country</td>
                                <td></td>
                                </tr>



                                <tr>
                                <td>Is Active</td>
                                <td></td>
                                </tr>

          

                              </tbody>
                                  </table>






            </div>
                      </div>
                  </div>
            </div>
        </div>
        </div>
</template>



<script>
 import axios from 'axios';
 //import SpinnerComp from "@/components/SpinnerComp.vue";
 import { mapMutations } from 'vuex';
 import FieldValidateService from "@/services/FieldValidateService";
 import notificationBox from "@/services/notificationBox";
 import vSelect from 'vue-select';
import { LOADING } from '@/store/storeconstants';

     export default {
        components:{vSelect},
        name:'EmployeeUserCountry',
         props: {
          employee_country_view_type:{
            require: true,
            type: String,
          },

          employee_user_id:{
            required: true,
          },

         },

                async created() {

          const user_data = JSON.parse(localStorage.getItem("users"));
          this.user_id = user_data.id;
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");

    
   try{
       let response = await axios.get("getCountries");
      // console.log(response.data.data);
         
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }

     },


              data: function () {
    return {
              loading:false,
              app_portal: "",
              user_id:"",
              portal_id:"",
               errorMess: "",
              succMess:"",
              SearcherrorServers:"",
              clear_user: true,
              clear_all: true,
              errorClient:[],
              errorServers:[],
              getCountries:[],
              
              modal:"modal",

              formData: {
                 user_fullname:"",
                 employee_user_country_id:"",
                 portal:"",
                 user_employee_id:"",
                 country_id:null,
                 is_active:"",
              },

            user_fullnameErr:"",
            countryErr:"",
            is_activeErr:"",



    };

              },


                    methods: {


         ...mapMutations({
       showLoading:LOADING,
     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




               async reg(){
                    
                       
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];

         this.user_fullnameVal(this.formData.user_fullname);
         this.countryVal(this.formData.country_id);
         this.is_activeVal(this.formData.is_active);

          if(this.errorClient.length > 0)
       {
        // clearTimeout(bbb);
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
         // console.log(this.errorClient);
          return true;
       }
       else{

          if(this.employee_country_view_type == "edit"){

            try{

              const response = await axios.patch(`gerdd_employee_countries/${this.formData.employee_user_country_id}`,this.formData);
              const getDataMessage = response.data.message;
                
              if(response.status == 200){
                   this.showLoading(false); 
                  notificationBox.succNotifications(getDataMessage);

                  this.$emit("userView",this.portal_id,this.formData.user_employee_id);

                 

              } 

            }catch(error){

               console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

            }

          }

       }


               },

















                getServerError(){
             if(this.portal == "Provider"){
      this.providerErr = 'provider' in this.errorServers[0] ? this.errorServers[0].provider[0] : '';
      this.provider_countryErr = 'provider_country_id' in this.errorServers[0] ? this.errorServers[0].provider_country_id[0] : '';
      this.provider_gps_addressErr = 'provider_gps_address' in this.errorServers[0] ? this.errorServers[0].provider_gps_address[0] : '';
      this.provider_cityErr = 'provider_city' in this.errorServers[0] ? this.errorServers[0].provider_city[0] : '';
      this.provider_contactErr = 'provider_contact' in this.errorServers[0] ? this.errorServers[0].provider_contact[0] : '';
      this.provider_emailErr = 'provider_email' in this.errorServers[0] ? this.errorServers[0].provider_email[0] : '';
      this.health_careErr = 'health_care_id' in this.errorServers[0] ? this.errorServers[0].health_care_id[0] : '';
     }

       if(this.formData.user_level == "LOCAL" || this.formData.user_level == "COUNTRY"){
        this.linkErr = 'link' in this.errorServers[0] ? this.errorServers[0].link[0] : '';
       }

      this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
      this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
      this.countryErr = 'country_id' in this.errorServers[0] ? this.errorServers[0].country_id[0] : '';
      this.cityErr = 'city' in this.errorServers[0] ? this.errorServers[0].city[0] : '';
      this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
      this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
      this.otpErr = 'reg_otp' in this.errorServers[0] ? this.errorServers[0].reg_otp[0] : '';
      this.passwordErr = 'password' in this.errorServers[0] ? this.errorServers[0].password[0] : '';
      this.password_confirmationErr = 'password_confirmation' in this.errorServers[0] ? this.errorServers[0].password_confirmation[0] : '';
      this.user_levelErr = 'user_level' in this.errorServers[0] ? this.errorServers[0].user_level[0] : '';

     
           return true;
        },






              
                user_fullnameVal(value){

             let field = "User Employee";

              if(this.clear_user  && FieldValidateService.reqVal(value,field)){
                this.user_fullnameErr = FieldValidateService.reqVal(value,field);
                this.errorClient.push(this.user_fullnameErr);
              }else{
                this.user_fullnameErr =""; 
                this.errorClient.pop(this.user_fullnameErr);
              }

            },
                       



                       countryVal(value){

             let field = "Country";

              if(this.clear_user  && FieldValidateService.reqVal(value,field)){
                this.countryErr = FieldValidateService.reqVal(value,field);
                this.errorClient.push(this.countryErr);
              }else{
                this.countryErr =""; 
                  this.errorClient.pop(this.countryErr);
              }

            },




                is_activeVal(value){

             let field = "Country";

              if(this.clear_user  && FieldValidateService.reqVal(value,field)){
                this.is_activeErr= FieldValidateService.reqVal(value,field);
                this.errorClient.push(this.is_activeErr);
              }else{
                this.is_activeErr=""; 
                  this.errorClient.pop(this.is_activeErr);
              }

            },



          async viewUserCountryData(id){

            if(this.employee_country_view_type == "edit" || this.employee_country_view_type == "view")
            {
              this.showLoading(true);
              const response = await axios.get(`gerdd_employee_countries/${id}`);
              const getData = response.data.data;
             this.showLoading(false);
             this.formData.user_fullname = getData.fullname+" - "+getData.contact+" - "+getData.email;
             this.formData.employee_user_country_id = id;
             this.formData.user_employee_id = getData.user_employee_id;
             this.formData.country_id = getData.country_id;
             this.formData.is_active = getData.is_active;
             this.portal_id = getData.portal_id;


            }

          },


            clear_all_data(){
                      this.errorServers = [];
                      this.errorClient=[];
                      this.clear_user = false;
                      this.clear_all = false
                      this.user_employee_id = null
                      this.country_id = null
                      this.active = null
                   },

                    },




          watch:{

              employee_country_view_type(value){
                  if(value == "add"){

                    this.clear_all_data;

                  }
              },


              employee_user_id(value){
                this.viewUserCountryData(value);
              }


          }







     }
 </script>