<template>
    <div class="col-2 text-end">
                    
        <div class="dropdown ml-4">
            <button v-if="num_checked > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                With checked ({{ num_checked }})
            </button>
            <div class="dropdown-menu" v-if="portall==''">
               
                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                    Delete
                </a>
                    <a :href="url"  class="dropdown-item" type="button">
                    Export
                </a>
            </div>

                <div class="dropdown-menu" v-else-if="portall=='provider'">
               
                <a  onclick="confirm('Are You Sure You Want To Approve These Provider(s)?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="approveProviders">
                    Approve
                </a>

                 <a  onclick="confirm('Are You Sure You Want To Reject These Provider(s)?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="rejectProviders">
                    Reject
                </a>
                    <a :href="url"  class="dropdown-item" type="button">
                    Export
                </a>
            </div>




                       <div class="dropdown-menu" v-else-if="portall=='payAll'">
               
                <a   href="#" class="dropdown-item" type="button" @click.prevent="payBills">
                    Pay All
                </a>

            </div>


    


        </div>
     </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name:'ActionButton',
        props:["num_checked","portall"],
        computed:{
            ...mapState('dataTable',['url'])
        },

        methods:{
            approveProviders(){
                this.$emit("massApproveProvider");
            },

            rejectProviders(){
                this.$emit("massRejectProvider");
            },

            payBills(){
                this.$emit("payBills");
            }
        }
        
    }
</script>

<style scoped>

</style>