import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import './axios';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';



/*
Font Awesome CSS
*/
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

/*
Bootstrap CSS, JS
*/
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";



import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import 'intl-tel-input/build/css/intlTelInput.css';
// import 'intl-tel-input/build/js/intlTelInput.js';


//import 'vue-multiselect/dist/vue-multiselect.css'
//import Select2 from 'vue3-select2-component';

// import VueTelInput from 'vue3-tel-input'
// import 'vue3-tel-input/dist/vue3-tel-input.css'

// const VueTelInputOptions = {
//     mode: "international",
//  //   onlyCountries: ['NG', 'GH', "GB", "US", "CA"]
//   }

import 'vue-select/dist/vue-select.css';


import "@/style2.css";


const globalOptions = {
    mode: 'international',
    preferredCountries: ['GH','US','NG','CA','GB'],
    dropdownOptions:{
        showSearchBox:true,
        showDialCodeInSelection:true
    },
    inputOptions:{
        showDialCode:false,
        autocomplete: 'off'

    }
  };



const app = createApp(App);
app.use(VueSweetalert2);
app.use(store);
app.use(VueTelInput,globalOptions);
app.use(router);
app.mount("#app");