<template>
  <div class="unix-login">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="login-content">
            <div class="login-logo">
              <img
                src="@/assets/eMedipay-logo-small.png"
                alt=""
                style="height: 55px"
              />
            </div>
            <div class="card login-form">
              <div class="card shadow-sm" id="stylized">
              <h4>Register - {{ app_portal }}</h4>

              <form class="register-form" @submit.prevent="reg()">
                <!-- <div id="cover-spin" v-if="loading"></div> -->
                  <div class="card-body">
                
              
                  <div  v-if="app_portal === 'Provider'">
                  <p></p>
                  <h1>Provider Info </h1><br/>
                   <p></p>
                  <span>
                     
                     

                    <div class="row"  style="margin-bottom:30px">
                      
                        <div class="col">
                        <label><span class="text_error">*</span>Provider</label>

                        <div class="input-group">

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: providerErr}"
                            placeholder="Full Name"
                            @blur="providerVal($event.target.value)"
                            v-model.trim.lazy="formData.provider"
                          />
                          
                        </div>

                         <div v-if="provider_nameLoading">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span :class="{ text_error: providerErr}" v-if="providerErr">{{ providerErr }} </span>
                        <span v-if="search_provider_name_fail  && view_type=='add'" class="text_error : search_provider_name_fail">{{ search_provider_name_fail }}</span>

                      </div>








                      
                           <div class="col">
                          <label><span class="text_error">*</span>Provider's Country</label>
                        <div class="input-group">
                          <select
                            class="form-control"
                            :class="{ form_error: provider_countryErr}"
                            @change="provider_countryVal($event.target.value)"
                            v-model.trim="formData.provider_country_id">
                            <option value="">--Select Provider's Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select>
                          </div>
                          
                          <div
                            :class="{ text_error: provider_countryErr}"
                            v-if="provider_countryErr"
                          >
                            {{ provider_countryErr }} 
                          </div>
                        </div>
                      
                          

                         <div class="col">
                          <label>GPS Address</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: provider_gps_addressErr  }"
                            placeholder="Address"
                            @blur="provider_gps_addressVal($event.target.value)"
                            v-model.trim="formData.provider_gps_address"
                          />
                          
                        </div>
                        <div
                            :class="{ text_error: provider_gps_addressErr}"
                            v-if="provider_gps_addressErr"
                          >
                            {{ provider_addressErr }} 
                          </div>
                      </div>




                      


                         <div class="col">
                          <label><span class="text_error">*</span>Provider's  City/Town</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: provider_cityErr}"
                            placeholder="Provider's City"
                            @blur="provider_cityVal($event.target.value)"
                            v-model.trim="formData.provider_city"
                          />
                        </div>
                             <div :class="{ text_error: provider_cityErr }" v-if="provider_cityErr">
                            {{ provider_cityErr }}
                          </div>
                      </div>

                        </div>


                

                      <div class="row">
           
                   
          


                       <div class="col">
                        <label><span class="text_error">*</span>Provider's Contact</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="provider_telephone"
                            :class="{ form_error: provider_contactErr }"
                            placeholder="Provider's Contact"
                            @blur="provider_contactVal($event.target.value)"
                            v-model.trim="formData.provider_contact"
                          /> -->
                              <div style="width: 250px;">
                          <vue-tel-input v-model.lazy="formData.provider_contact" @blur="provider_contactVal" ></vue-tel-input>
                          </div>
                         
                        </div>
                         <div
                            :class="{ text_error: provider_contactErr  }"
                            v-if="provider_contactErr"
                          >
                            {{ provider_contactErr }} 
                          </div><br/>

                         <div v-if="provider_contactLoading">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_provider_contact_fail && view_type=='add'" class="text_error : search_provider_contact_fail">{{ search_provider_contact_fail }}</span>


                      </div>




           




                        <div class="col">
                        <label>Provider's Email</label>
                        <div class="input-group">
                          
                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: provider_emailErr  }"
                            placeholder="Provider's Email"
                            @blur="provider_emailVal($event.target.value)"
                            v-model.trim="formData.provider_email"
                          />
                         
                        </div>
                           <div
                            :class="{ text_error: provider_emailErr  }"
                            v-if="provider_emailErr "
                          >
                            {{ provider_emailErr }} 
                          </div>

                           <div v-if="provider_emailLoading">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_provider_email_fail  && view_type=='add'" class="text_error : search_provider_email_fail">{{ search_provider_email_fail }}</span>


                      </div>



                           <div class="col">
                            <label><span class="text_error">*</span>Healthcare</label>
                        <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: health_careErr }"
                            @change="health_careVal($event.target.value)"
                            v-model.trim="formData.health_care_id">
                            <option value="">--Select Health Care--</option>
                            <option v-for="health in getHealthCares" :key="health.id" :value="health.id">
                              {{ health.health_care }} 
                            </option>
                          </select>
                          </div>
                          
                          <div
                            :class="{ text_error: health_careErr }"
                            v-if="health_careErr"
                          >
                            {{ health_careErr }}
                          </div>
                        </div>






                           
 </div>



                    

                  </span>
                  </div>
              
               




                       <p></p>
                       <h1 v-if="app_portal === 'Provider'">Health Professional Personal Info </h1>
                       <h1 v-else>Personal Info </h1>
                       <br/>
                      <p></p>
                  <span>
              

                    <div class="row">
                       <div class="col">
                        <label><span class="text_error">*</span>Fullname</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: fullnameErr  }"
                           v-if="fullnameErr">
                          {{ fullnameErr }} 
                          </div>
                      </div>





                          <div class="col">
                          <label><span class="text_error">*</span>Contact</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <vue-tel-input v-model.lazy="formData.contact" @blur="contactVal" ></vue-tel-input>
                          
                           

                        </div>
                        <div
                            :class="{ text_error: contactErr }"
                            v-if="contactErr"
                          >
                            {{ contactErr }}
                          </div><br/>

                           <div v-if="contactLoading && !user_idd">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_contact_info && user_idd && view_type=='add'" class="text-success">{{search_contact_info}}</span>
                        <span v-if="search_contact_fail && !user_idd && view_type=='add'" class="text_error : search_contact_fail">{{ search_contact_fail }}</span>

                      </div>







                                  <div class="col">
                        <label>Email</label>
                        <div class="input-group">
                          
                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: emailErr}"
                            placeholder="Email"
                            @blur="emailVal($event.target.value)"
                            v-model.trim.lazy="formData.email"
                          />
                         
                            
                        </div>
                         <div
                            :class="{ text_error: emailErr}"
                            v-if="emailErr"
                          >
                            {{ emailErr }} 
                          </div><br/>

                             <div v-if="emailLoading && !user_idd && !search_email_fail">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_email_info && user_idd && view_type=='add'" class="text-success">{{search_email_info}}</span>
                        <span v-if="search_email_fail && !user_idd && view_type=='add'" class="text_error : search_email_fail">{{ search_email_fail }}</span>

                      </div>


                    
                 

                    <!-- <div class="row" v-if="checkLoading && !has_password">
                          <div class="col-4">
                        </div>
                        <div class="col-4">
                       <div class="centered">Checking...</div><img src="../assets/loading.gif" style="height:120px" alt="" title="checking...." class="d-block m-auto">

                        </div>
                        <div class="col-4">

                        </div>
                      </div>   -->
                      



        

                    </div>
                   


                    <div class="row" v-if="(!user_idd && view_type=='add') || view_type=='edit'">

            
                              <div class="col">
                                <label><span class="text_error">*</span>Country</label>
                             <div class="input-group">
                          
                          <!-- <select
                            class="form-control country"
                            :class="{ form_error: countryErr }"
                            ref="count"
                            @change="countryVal($event.target.value)"
                            v-model.trim="formData.country_id">
                            <option value="">--Choose a Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select> -->
                          <!-- <Select2 v-model="formData.country_id" :options="getCountries"  :settings="{ placeholder: 'Specifies the placeholder through settings', width: '50%', ajax: ajax }" @change="countryVal($event.target.value)"  /> -->
                         <v-select :options="getCountries"  class="form-control select1" v-model="formData.country_id"  :reduce="country => country.id" label="country" @option:selected="countryVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: countryErr }"
                            v-if="countryErr"
                          >
                            {{ countryErr }}
                          </div>

                      </div>


                      <div class="col">
                              <label>City/Town</label>
                              <div class="input-group">
                          
                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: cityErr }"
                            placeholder="City"
                            @blur="cityVal($event.target.value)"
                            v-model.trim="formData.city"
                          />
                         
                        </div>
                            <div :class="{ text_error: cityErr }" v-if="cityErr">
                            {{ cityErr }} 
                          </div>

                      </div>

                              <div class="col">
                              <label>Gender</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: genderErr}"
                             @change="genderVal($event.target.value)"
                            v-model.trim="formData.gender">
                            <option value="">--Choose An Option--</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: genderErr}"
                            v-if="genderErr"
                          >
                            {{ genderErr }}
                          </div>
                      </div>

            

           

                    </div>


                  <div class="row" v-if="this.user_idd && this.view_type== 'add'">

                      <div class="col">
                        <label><span class="text_error">*</span>Please Enter The OTP/Verification Code Sent To Your Mail/Contact</label>
                        <div class="input-group">
                          
                          <input
                            type="text"
                            style="width:900px"
                            :readonly="!user_idd"
                            class="form-control"
                            :class="{ form_error: otpErr }"
                            placeholder="OTP"
                             @blur="otpVal($event.target.value)"
                            v-model.trim="formData.reg_otp"
                          />
                          <!-- <div :class="{ text_error: otpErr }" v-if="otpErr">
                            {{ otpErr }} 
                          </div><br/> -->

                        

                         

                        </div>
                        <div v-if="count_down && !otp_success_message" class="text-success">Count Down: {{count_down}} To Input Otp <button class="btn btn-primary" @click.prevent="verify_otp()">Verify Otp</button></div>
                        <div v-else-if="show_otp_button && !otp_success_message" class="text-success"><button class="btn btn-primary" @click.prevent="resend_otp()">Resend Otp</button></div>
                        
                        <span v-if="otp_success_message" class="text-success">{{otp_success_message}}</span>
                        <span v-else-if="otpErr" class="text_error">{{otpErr}}</span><br/>
                      </div>
              
                    
                  </div>

                  </span>

              
              
              <span  v-if="!user_idd && !has_password">
                 <p></p>
                    <h1>Login Info </h1>
                       <br/>
                      <p></p>
                  
                    <div class="row">

                <div class="col">
                          <label><span class="text_error">*</span>Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: passwordErr }"
                            placeholder="Password"
                             @blur="passwordVal($event.target.value)"
                            v-model.trim="formData.password"
                          />
                              <i class="fa" @click="showPassword = !showPassword"   :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>
                         
                        </div>
                         <div
                            :class="{ text_error: passwordErr}"
                            v-if="passwordErr"
                          >
                            {{ passwordErr }}
                          </div>
                      </div>




                       <div class="col">
                        <label><span class="text_error">*</span>Confirm Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showConfPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: password_confirmationErr  }"
                            placeholder="Confirm Password"
                            @blur="password_confirmationVal($event.target.value)"
                            v-model.trim="formData.password_confirmation"
                          />
                           <i class="fa" @click="showConfPassword = !showConfPassword"   :class="[showConfPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>

                    
                        </div>
                              <div
                            :class="{ text_error: password_confirmationErr }"
                            v-if="password_confirmationErr"
                          >
                            {{ password_confirmationErr }}
                          </div>
                      </div>



                    </div>
                  
                  </span>

                <span class="buttons show-buttons">
                  <button
                    type="submit"
                    class="btn btn-primary btn-flat m-b-30 m-t-30"
                    v-if="!user_idd">
                    Register
                  </button>
                       <button
                    type="submit"
                    class="btn btn-primary btn-flat m-b-30 m-t-30"
                    v-else>
                    Click To Link To This Portal
                  </button>
                  <!-- <div class="social-login-content">
                                        <div class="social-button">
                                            <button type="button" class="btn btn-primary bg-google-plus btn-flat btn-addon m-b-10"><i class="ti-google"></i>Register with google</button>
                                            <button type="button" class="btn btn-primary bg-facebook btn-flat btn-addon m-t-10"><i class="ti-facebook"></i>Register with facebook</button>
                                            <button type="button" class="btn btn-primary bg-linkedin btn-flat btn-addon m-t-10"><i class="ti-linkedin"></i>Register with linkedin</button>
                                            <button type="button" class="btn btn-primary bg-twitter btn-flat btn-addon m-t-10"><i class="ti-twitter"></i>Register with twitter</button>
                                        </div>
                                    </div> -->
                  <div class="register-link m-t-15 text-center">
                    <p>
                      Already have account ?
                      <router-link to="/provider_login" v-if="app_portal == 'Provider'"> Login</router-link>
                      <router-link to="/support_login" v-else-if="app_portal == 'Support'"> Login</router-link>
                    </p>
                    <p>Go To ? <router-link to="/"> Portal</router-link></p>
                  </div>
                </span>
                  </div>
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
import SpinnerComp from "@/components/SpinnerComp.vue";
import vSelect from 'vue-select'
import {mapMutations, mapState } from 'vuex';
import { LOADING } from '@/store/storeconstants';

export default {
  name: "RegisterT",

    components: {
      SpinnerComp,
      vSelect
    
  },

   mounted(){
   


   // console.log(input2);
    
  },

  async created() {
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");



    switch(this.app_portal) {
  case 'Dependent':
    this.formData.user_level = "DEPENDENT";
    break;
  case 'Support':
    this.formData.user_level = "SUPPORT";
    break;
  case 'Telemedicine':
    this.formData.user_level = "TELEMEDICINE";
    break;
  case 'Provider':
    this.formData.user_level = "ADMIN";
    break;
  default:
    this.formData.user_level = "";
}

    

//     const configHeaders = {
//   "content-type": "application/json",
//   "Accept": "application/json"
// };

    try{
       let response = await axios.get("getCountries");
      // console.log(response.data.data);
         
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }



  if(this.app_portal === 'Provider')
  {
        try{
       let response = await axios.get("getHealthCares");
      // console.log(response.data.data);
         
        this.getHealthCares =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }
  }
  
  },

  data: function () {
    return {
       //userModal: null,
      link_val:"",
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      search: "",
      searchErr: "",
      loading:false,
      getCountries:[],
      getProviders:[],
      country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      contactLoading:false,
      emailLoading:false,
      provider_contactLoading: false,
      provider_emailLoading: false,
      search_fail:"",
      search_contact_info:"",
      search_email_info:"",
      search_contact_fail:"",
      search_email_fail:"",
     search_provider_contact_fail:"",
     search_provider_email_fail:"",
     provider_nameLoading:"", 
     search_provider_name_fail:"",

      contact_edit_typed:"NO",
      email_edit_typed:"NO",
      otpServerError:"",
      user_idd:"",
      otp_success_message: "",
      count_down:"",
      show_otp_button: false,
      found_data:false,
      has_password:false,
      employee_user_country_portal:[],
      employee_user_provider_portal:[],
      showPassword: false,
      showConfPassword: false,
      view_type:"add",

            getHealthCares:[],
      providerLoader:false,
      providerExistMessage:"",


      //Provider
 
    

      formData:{
           link:[],   //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        user_level:"",
        portal:"",
        is_logged_in:"NO",
        status:"PENDING",
        otp_code:"",
        user_idy:"",
        user_portal_idy:"",
        action_type:"",

        provider_id:"",
        reg_otp:"",
        fullname: "",
        gender: "",
        country_id: null,
        gps_address: "",
        city:  "",
        contact: "",
        email:  "",
        password: "",
        password_confirmation: "",
        portal_id: "",
        health_care_id:"",


      provider: "",
      provider_country_id: "",
      provider_gps_address: "",
      provider_city: "",
      provider_contact: "",
      provider_email: "",


        view_portal:"",
      },

    //Errors  
      fullnameErr: "",
      genderErr: "",
      countryErr: "",
      cityErr: "",
      contactErr: "",
      emailErr: "",
      otpErr:"",
      passwordErr: "",
      password_confirmationErr: "",
      user_levelErr:"",
      linkErr:"",

      //Providers Err
      providerErr:"",
      provider_countryErr:"",
      provider_gps_addressErr:"",
      provider_cityErr:"",
      provider_contactErr:"",
      provider_emailErr:"",
      health_careErr:"",






    };
  },
  methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


  

   

    async reg(){

   
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];

       this.fullnameVal(this.formData.fullname);
       this.contactVal(this.formData.contact);
       this.emailVal(this.formData.email);
       this.countryVal(this.formData.country_id);
       this.cityVal(this.formData.city);
       this.genderVal(this.formData.gender);
       this.otpVal(this.formData.reg_otp);
       this.user_levelVal(this.formData.user_level);
       this.linkVal(this.formData.link);
       this.passwordVal(this.formData.password);
       this.password_confirmationVal(this.formData.password_confirmation);

        if(this.app_portal === 'Provider'){

           this.providerVal(this.formData.provider);
           this.provider_countryVal(this.formData.provider_country_id);
           this.provider_gps_addressVal(this.formData.provider_gps_address);
           this.provider_cityVal(this.formData.provider_city);
           this.provider_contactVal(this.formData.provider_contact);
           this.provider_emailVal(this.formData.provider_email);
           this.health_careVal(this.formData.health_care_id);

        }

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          
          return true;
       }
 
      else{

                  if(!this.user_idd && this.view_type=="add")
          {

            try{
               
             const response = await axios.post("user_portal_user_prov",this.formData);

            
                  
               this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
               this.showLoading(false);
            if(this.getMessage == 'success')
            {
         this.clearFormData();
        //notificationBox.succNotifications("User Added Successfully");
         notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account")

        this.$emit("userAdded");
            }

            }catch(error){

            console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

            }

          }



                 else if(this.user_idd && this.view_type=="add"){

                   try{
               
             const response = await axios.post("user_portal_user_exist_prov",this.formData);

            
                  
               this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
               this.showLoading(false);
            if(this.getMessage == 'success')
            {
         this.clearFormData();
       // notificationBox.succNotifications("User Added Successfully");
        notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account")

        this.$emit("userAdded");
            }

            }catch(error){


                   console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

             if(this.errorServers.length > 0)
            {
          
            this.getServerError();
           }

            }

         }






      }



      
              
    
    },






       getServerError(){
             if(this.portal == "Provider"){
      this.providerErr = 'provider' in this.errorServers[0] ? this.errorServers[0].provider[0] : '';
      this.provider_countryErr = 'provider_country_id' in this.errorServers[0] ? this.errorServers[0].provider_country_id[0] : '';
      this.provider_gps_addressErr = 'provider_gps_address' in this.errorServers[0] ? this.errorServers[0].provider_gps_address[0] : '';
      this.provider_cityErr = 'provider_city' in this.errorServers[0] ? this.errorServers[0].provider_city[0] : '';
      this.provider_contactErr = 'provider_contact' in this.errorServers[0] ? this.errorServers[0].provider_contact[0] : '';
      this.provider_emailErr = 'provider_email' in this.errorServers[0] ? this.errorServers[0].provider_email[0] : '';
      this.health_careErr = 'health_care_id' in this.errorServers[0] ? this.errorServers[0].health_care_id[0] : '';
     }

       if(this.formData.user_level == "LOCAL" || this.formData.user_level == "COUNTRY"){
        this.linkErr = 'link' in this.errorServers[0] ? this.errorServers[0].link[0] : '';
       }

      this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
      this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
      this.countryErr = 'country_id' in this.errorServers[0] ? this.errorServers[0].country_id[0] : '';
      this.cityErr = 'city' in this.errorServers[0] ? this.errorServers[0].city[0] : '';
      this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
      this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
      this.otpErr = 'reg_otp' in this.errorServers[0] ? this.errorServers[0].reg_otp[0] : '';
      this.passwordErr = 'password' in this.errorServers[0] ? this.errorServers[0].password[0] : '';
      this.password_confirmationErr = 'password_confirmation' in this.errorServers[0] ? this.errorServers[0].password_confirmation[0] : '';
      this.user_levelErr = 'user_level' in this.errorServers[0] ? this.errorServers[0].user_level[0] : '';

     
           return true;
        },





          clearFormData(){

        this.link =[];   //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        this.user_level = "";
       // this.portal = "";
        this.is_logged_in = "NO";
        this.status = "PENDING";
        this.otp_code = "";
        this.user_idy = "";
        this.user_portal_idy = "";
        this.action_type = "";

        // if(this.portal != "Provider")
        // {
        // this.formData.provider_id = "";
        // }
        this.formData.reg_otp = "";
        this.formData.fullname = "";
        this.formData.gender = "";
        this.formData.country_id = null;
        this.formData.gps_address = "";
        this.formData.city =  "";
        this.formData.contact = "";
        this.formData.email =  "";
        this.formData.user_level =  "";
        this.formData.password = "";
        this.formData.password_confirmation = "";



              this.formData.provider = "";
      this.formData.provider_country_id = "";
      this.formData.provider_gps_address = "";
      this.formData.provider_city = "";
      this.formData.provider_contact = "";
      this.formData.provider_email = "";
     // this.user_idd = "";
      this.formData.health_care_id = "";
      this.existing_user = "";
      this.formData.status = "PENDING";
      this.formData.user_idy = this.user_idd;



        this.errorClient = [];
      this.errorServers = [];
      this.search_fail = "";
      this.search_contact_info = "";
      this.search_email_info = "";
      this.search_contact_fail = "";
      this.search_email_fail = "";
      this.contact_edit_typed = "NO";
      this.otpServerError = "";
      this.user_idd = "";
      this.otp_success_message = "";
      this.count_down = "";
      this.show_otp_button = false;
      this.found_data = false;
      this.has_password = false;

        //view_portal:"",

        },






    // clear_main_user(){
    //     this.errorServers = [];
    //   this.clear_user = false;
    //   this.formData.fullname = "";
    //   this.formData.gender = "";
    //   this.country_name = "";
    //   this.formData.gps_address = "";
    //   this.formData.country_id = "";
    //   this.formData.city = "";
    //   this.formData.contact = "";
    //   this.formData.email = "";
    //   this.formData.password = "";
    //   this.formData.password_confirmation = "";
    //   this.formData.reg_otp = "";
    //   this.reset_user_id({});
    //   this.clear_user = true;
    // },


    // clear_all_user(){
    //   this.clear_main_user();
    //   this.clear_all = false;
    //   this.formData.provider = "";
    //   this.formData.provider_country_id = "";
    //   this.formData.provider_gps_address = "";
    //   this.formData.provider_city = "";
    //   this.formData.provider_contact = "";
    //   this.formData.provider_email = "";
    //  // this.user_idd = "";
    //   this.formData.health_care_id = "";
    //   this.existing_user = "";
    //   this.formData.status = "PENDING";
    //   this.formData.user_idy = this.user_idd;

    //     // if(this.app_portal === 'Provider')
    //     // {
    //     // //this.formData.user_level = "";
    //     // this.provider_id = this.provider_idd;
    //     // }
    //     // else
    //     // {
    //     //    this.provider_id = "";
    //     // }
    //      this.formData.provider_id = "";
    //     this.formData.is_logged_in = "";
    //     this.formData.reg_otp = "";
    //   this.search_fail = "",
    //   this.search_contact_info = "",
    //   this.search_email_info = "",
    //   this.otpServerError = "",
    //     this.clear_all = true;
    // },




    existing_userVal(value) {

       let field = "Existing User";

      if(this.clear_all && FieldValidateService.reqVal(value,field)){
        this.existing_userErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.existing_userErr);
      }else{
        this.existing_userErr =""; 
          this.errorClient.pop(this.existing_userErr);
      }
    },

    // searchVal(value) {

    //   let field = "Search";
     
    //  if(this.clear_user && this.is_existing_user && FieldValidateService.reqValSearch(value, field))
    //  {
    //    this.searchErr = FieldValidateService.reqValSearch(value,field);      
    //     this.errorClient.push(this.searchErr);
    //  }else{

    //        this.searchErr =""; 
    //       this.errorClient.pop(this.searchErr);

    //  }
    // },

           fullnameVal(value){

      let max = 500;
      let field = "Full Name"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{


             if(this.errorClient.includes(this.fullnameErr))
           {
            let pos = this.errorClient.indexOf(this.fullnameErr);
           this.errorClient.splice(pos,1);
            this.fullnameErr =""; 
           }

     }


    },



     genderVal(value) {

      let max = 100;
      let field = "Gender";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.genderErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.genderErr);
     }else{

           if(this.errorClient.includes(this.genderErr))
           {
            let pos = this.errorClient.indexOf(this.genderErr);
           this.errorClient.splice(pos,1);
            this.genderErr =""; 
           }
          
          

     }
    },







   countryVal(value){

             let field = "Country";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.countryErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.countryErr);
      }else{
        if(this.errorClient.includes(this.countryErr))
        {
        let pos = this.errorClient.indexOf(this.countryErr);
        this.errorClient.splice(pos,1);
        }
        this.countryErr =""; 
      }

    },

   
    

    cityVal(value){

           let max = 250;
           let field = "City"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.cityErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.cityErr);
     }else{

             if(this.errorClient.includes(this.cityErr))
        {
        let pos = this.errorClient.indexOf(this.cityErr);
        this.errorClient.splice(pos,1);
        }
           this.cityErr =""; 
        

     }


    },



    

   contactVal(){

            let field = "Contact"

           let value = this.formData.contact;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.contactErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.contactErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.contactErr =""; 

              if(this.view_type =="add")
           {
           this.getUser(value,"contact");
           }
          

     }

    },




        emailVal(value){


                 let field = "Email"

         if(FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.emailErr =""; 
           if(this.view_type =="add")
           {
            this.getUser(value,"email");
           }
         

     }


    },





     otpVal(value){

           let field = "Otp"
          // console.log(this.user_idd)

         if(this.user_idd && this.view_type== "add" && FieldValidateService.reqValNum(value, field))
     {
       this.otpErr = FieldValidateService.reqValNum(value,field);
        this.errorClient.push(this.otpErr);
     }
     else if(!this.otp_success_message){
      this.otpErr = "Wrong Otp Code"
      this.errorClient.push(this.otpErr);
     }
     else{

          if(this.errorClient.includes(this.otpErr))
        {
        let pos = this.errorClient.indexOf(this.otpErr);
        this.errorClient.splice(pos,1);
        }
           this.otpErr =""; 
          

     }

    },

    

    passwordVal(value){

      let field = "Password"

         if(FieldValidateService.reqValPassword(value, field))
     {
       this.passwordErr = FieldValidateService.reqValPassword(value,field);
       this.errorClient.push(this.passwordErr);
     }else{

          if(this.errorClient.includes(this.otpErr))
        {
        let pos = this.errorClient.indexOf(this.otpErr);
        this.errorClient.splice(pos,1);
        }
           this.passwordErr =""; 
          

     }


    },


    password_confirmationVal(value){

           
          

      if(FieldValidateService.valPasswordConfirm(value, this.formData.password))
     {
       this.password_confirmationErr = FieldValidateService.valPasswordConfirm(value,this.formData.password);
        this.errorClient.push(this.password_confirmationErr);
     }else{

            if(this.errorClient.includes(this.password_confirmationErr))
        {
        let pos = this.errorClient.indexOf(this.password_confirmationErr);
        this.errorClient.splice(pos,1);
        }
           this.password_confirmationErr =""; 

     }


   },




     user_levelVal(value) {

       let field = "User Level";

      if(FieldValidateService.reqVal(value,field)){
        this.user_levelErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.user_levelErr);
      }else{

          if(this.errorClient.includes(this.user_levelErr))
        {
        let pos = this.errorClient.indexOf(this.user_levelErr);
        this.errorClient.splice(pos,1);
        }
        this.user_levelErr =""; 
          
      }
    },








       linkVal(value){

             let field = "Link";

   
                

              if(typeof value == "object" && value!=null)
             {
             value.push(value.id);
             // value = value.id;
           //  this.formData.link.push(value)
             }

                      

                        
           
          //   console.log(this.formData.link)

      if((this.view_type=='add' && !this.user_idd && FieldValidateService.reqVal(value,field))  && (this.formData.user_level == "LOCAL" || this.formData.user_level == "COUNTRY")){
        this.linkErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.linkErr);
      }else{
        this.linkErr =""; 
          this.errorClient.pop(this.linkErr);
      }

    },








    






       providerVal(value) {

       let field = "Provider";
       let max = 499;
       

      if(this.clear_all && this.app_portal === 'Provider' && FieldValidateService.reqMaxStringVal(value,field,max)){
        this.providerErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.providerErr);
      }else{

            if(this.errorClient.includes(this.providerErr))
        {
        let pos = this.errorClient.indexOf(this.providerErr);
        this.errorClient.splice(pos,1);
        }
        this.providerErr =""; 
                 if(this.view_type =="add")
           {
            this.getProvider(value,"provider_name");
           }
         
      }
    },





        provider_countryVal(value) {

       let field = "Provider's Country";
      

      if(this.clear_all && this.app_portal === 'Provider' && FieldValidateService.reqVal(value,field)){
        this.provider_countryErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.provider_countryErr);
      }else{

            if(this.errorClient.includes(this.provider_countryErr))
        {
        let pos = this.errorClient.indexOf(this.provider_countryErr);
        this.errorClient.splice(pos,1);
        }
        this.provider_countryErr =""; 
          
      }
    },





    
        provider_gps_addressVal(value) {

       let field = "Provider's GPS Address";
      

      if(this.clear_all && this.app_portal === 'Provider' && FieldValidateService.onlyString(value,field)){
        this.provider_gps_addressErr = FieldValidateService.onlyString(value,field);
        this.errorClient.push(this.provider_gps_addressErr);
      }else{

          if(this.errorClient.includes(this.provider_gps_addressErr))
        {
        let pos = this.errorClient.indexOf(this.provider_gps_addressErr);
        this.errorClient.splice(pos,1);
        }
        this.provider_gps_addressErr =""; 
          
      }
    },







      provider_cityVal(value) {

       let field = "Provider's City";
       let max = 199;
      

      if(this.clear_all && this.app_portal === 'Provider' && FieldValidateService.reqMaxStringVal(value,field,max)){
        this.provider_cityErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.provider_cityErr);
      }else{

          if(this.errorClient.includes(this.provider_cityErr))
        {
        let pos = this.errorClient.indexOf(this.provider_cityErr);
        this.errorClient.splice(pos,1);
        }
        this.provider_cityErr =""; 
          
      }
    },








      provider_contactVal() {


        let value = this.formData.provider_contact;

        
       let field = "Provider's Contact";
      

      if(this.app_portal === 'Provider' && FieldValidateService.reqValNum(value, field)){
        this.provider_contactErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.provider_contactErr);
      }else{

         if(this.errorClient.includes(this.provider_contactErr))
        {
        let pos = this.errorClient.indexOf(this.provider_contactErr);
        this.errorClient.splice(pos,1);
        }


                    this.provider_contactErr =""; 
                    if(this.view_type =="add")
           {
            this.getProvider(value,"provider_contact");
           }


      }
    },





   provider_emailVal(value){


                 let field = "Provider's Email"

         if(this.app_portal === 'Provider' && FieldValidateService.onlyEmail(value, field))
     {
       this.provider_emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.provider_emailErr);
     }else{

          if(this.errorClient.includes(this.provider_emailErr))
        {
        let pos = this.errorClient.indexOf(this.provider_emailErr);
        this.errorClient.splice(pos,1);
        }
           this.provider_emailErr =""; 
            if(this.view_type =="add")
           {
            this.getProvider(value,"provider_email");
           }
          

     }


    },



      health_careVal(value) {

       let field = "Health Care";
      

      if(this.app_portal === 'Provider' && FieldValidateService.reqVal(value,field)){
        this.health_careErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.haelth_careErr);
      }else{

               if(this.errorClient.includes(this.health_careErr))
        {
        let pos = this.errorClient.indexOf(this.health_careErr);
        this.errorClient.splice(pos,1);
        }
        this.health_careErr =""; 
          
      }
    },



   async getUser(value,field){

    if(field == "contact")
    {
      this.contactLoading = true;
      this.emailLoading = false;
    }
    else if(field == "email"){
        this.contactLoading = false;
      this.emailLoading = true;
    }

     const data = {
      search: value,
      portal: this.formData.portal,
      provider: this.formData.provider_id

     };


      try{
         

       let response = await axios.post('getUserPerPortal',data);
       const getMessage = JSON.parse(JSON.stringify(response.data.success_data));
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
     this.dependentLoading = false;
        const getUser =  JSON.parse(JSON.stringify(response.data.data));
        //this.dependent =  getCartData.fullname+" - "+getCartData.email+" - "+getCartData.contact;
      
        if(getMessage != "Not Found")
        {
            this.user_idd = getUser.id;
            this.formData.user_idy = getUser.id;
        this.formData.fullname = getUser.fullname;
        this.formData.gender = getUser.gender;
        this.formData.country_id = getUser.country_id;
        this.formData.gps_address = getUser.gps_address;
        this.formData.city = getUser.city;
        this.formData.contact = getUser.contact;
        this.formData.email = getUser.email;  
        this.formData.password = getUser.password;      
        this.formData.password_confirmation = getUser.password; 
       // this.execOtpCountDown();
 
        this.found_data = true;   
        
        this.execOtpCountDown();

        }  
       else{
       
        this.user_idd = "";
         if(this.found_data == true)
         {

        this.formData.fullname = "";
        this.formData.gender = "";
        this.formData.country_id = "";
        this.formData.gps_address = "";
        this.formData.city = "";
        if(field == "contact")
        {
       this.formData.email = ""; 
        }
         else if(field == "email")
         {
          this.formData.contact = ""; 
         }
        this.formData.password = getUser.password;      
        this.formData.password_confirmation = getUser.password; 

        this.found_data = false;

         }
       }

        if(field === "contact"){
                this.contactLoading = false;
                this.emailLoading = false;
          this.search_contact_info = getMessage;
          this.search_email_info = "";
           this.search_contact_fail = "";
        }
           if(field === "email"){
                    this.contactLoading = false;
                    this.emailLoading = false;
          
          this.search_email_info = getMessage;
          this.search_contact_info = "";
            this.search_email_fail = ""
        }
        
      
     
       
      
    }catch(error)
    {

      console.log(error);
      if(field === "contact"){
            
        this.search_contact_fail = JSON.parse(JSON.stringify(error.response.data.error));

      }
      else if(field === "email"){
        this.search_email_fail = JSON.parse(JSON.stringify(error.response.data.error));
      
      }

      // this.searchDependentErr = JSON.parse(JSON.stringify(error.response.data.error));
      // error.response.data.error;
    }



   },














   async getProvider(value,field){

    if(field == "provider_contact")
    {
      this.provider_contactLoading = true;
      this.provider_emailLoading = false;
      this.provider_nameLoading = false; 
    }
    else if(field == "provider_email"){
        this.provider_contactLoading = false;
      this.provider_emailLoading = true;
      this.provider_nameLoading = false;
    }
      else if(field == "provider_name"){
        this.provider_contactLoading = false;
      this.provider_emailLoading = false;
      this.provider_nameLoading= true; 
    }

     const data = {
      search: value,


     };


      try{
         

       let response = await axios.post('getProviderInfo',data);
       const getMessage = JSON.parse(JSON.stringify(response.data.success_data));
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        //this.dependent =  getCartData.fullname+" - "+getCartData.email+" - "+getCartData.contact;
      
        if(getMessage == "Not Found")
        {

              if(field === "provider_contact"){
                this.provider_contactLoading = false;
                this.provider_emailLoading = false;
                this.provider_nameLoading = false;

           this.search_provider_contact_fail = "";
        }
           if(field === "provider_email"){
                    this.provider_contactLoading = false;
                    this.provider_emailLoading = false;
                    this.provider_nameLoading = false;
          
            this.search_provider_email_fail = ""
        }

            if(field === "provider_name"){
                    this.provider_contactLoading = false;
                    this.provider_emailLoading = false;
                    this.provider_nameLoading = false;
          
            this.search_provider_name_fail = ""
        }
        

        }  
     
     
       
      
    }catch(error)
    {

            this.provider_contactLoading = false;
            this.provider_emailLoading = false;
      if(field === "provider_contact"){
            
        this.search_provider_contact_fail = JSON.parse(JSON.stringify(error.response.data.error));

      }
      else if(field === "provider_email"){
        this.search_provider_email_fail = JSON.parse(JSON.stringify(error.response.data.error));
      
      }

      else if(field === "provider_name"){
        this.search_provider_name_fail = JSON.parse(JSON.stringify(error.response.data.error));
        this.provider_nameLoading = false; 
      
      }

      // this.searchDependentErr = JSON.parse(JSON.stringify(error.response.data.error));
      // error.response.data.error;
    }



   },










   async resend_otp(){

    try{

      const otpData = {
        user_id : this.user_idd
      }

      const response = await axios.post("resend_otp",otpData);
      console.log(response);
      this.execOtpCountDown();

    }catch(error){
   console.log(error);
    }


   },



  async send_empty_otp(){


    try{

      const otpData = {
        user_id : this.user_idd
      }

      const response = await axios.post("send_empty_otp",otpData);
      console.log(response);

    }catch(_){
   console.log(_);
    }




  },





      execOtpCountDown(){
          

          this.count_down = 60;
        let refreshId =    setInterval(() => {
  
     this.count_down--;
     if(this.count_down <= 0)
     {
        this.count_down = "" ;  
        this.show_otp_button = true;  
         this.send_empty_otp();
         clearInterval(refreshId);
        
     }
   }, 1000);




      
   },




    

   async verify_otp(){
    
    try{

      const otpData = {
        user_id: this.user_idd,
        otp: this.formData.reg_otp
      }

      const response = await axios.post("verifyUserExistOtp",otpData);
      console.log(response);
      this.show_otp_button = false;
      this.otp_success_message = JSON.parse(JSON.stringify(response.data.message));
      


    }catch(error){
      console.log(error);

      this.otpErr = error.response.data.error;
        
      this.otp_success_message = "";

    }

   },




    // async providerExistval(value){

    //     const  data = {
    //         search : value,
    //       }


    //               this.providerLoader = true;
    //   await this.verify_provider_exist(data).catch(error => {
    //     this.providerLoader = false;
         
    //        console.log(error);
    //        this.providerExistMessage = error.response.data.error;
    

    //    });

    //        if(this.verify_provider_exist)
    //    {
    //       this.providerLoader = false;
    //    }

    // }
        
        



  },


  computed:{
    ...mapState('user',['search_user_result','search_message']),
    ...mapState('provider', ['provider_idd','provider_exist_success_mess']) 
  },  




  watch: {

  //   async "formData.contact"(curValue) {
  //   //  this.searchVal(curValue);
  //     let contact_field = "contact";
  //     let email_field = null;
  //   this.searchContEm(curValue,contact_field ,email_field)

  // //console.log(curValue);
 
    

   
 
  //   },



    //     async "formData.email"(curValue) {
  
    //   let contact_field = null;
    //   let email_field = "email";
    //  this.searchContEm(curValue,contact_field,email_field)

  
 
    

   
 
    // },


    // async 'formData.provider'(curValue){
    //   this.providerExistval(curValue)
    // }


  },
};
</script>
