<template>
  <div class="header">
    <div class="container-fluid">
      <div class="row" style="max-height: 72px;">
        <div class="col-md-1">
          <div class="hamburger sidebar-toggle" :class="{ 'is-active' : isActive }" @click="toggleHamburger()">
            <!--<i v-if="isActive" class="fa-solid fa-arrow-right fa-2x m-2 ms-4" style="font-size: 25px;"></i>
            <i v-else class="fa-solid fa-bars-staggered fa-2x fa-flip-horizontal m-2 ms-4" style="font-size: 25px;"></i> -->
            
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
        <div class="col-md-8 h6 d-flex justify-content-end align-items-center" style="height: 72px;">
             {{ portal_name   }}  {{ getdateTime }}
        </div>
        <div class="col-md-3">
            <div class="text-end">
                <NotificationsComp/>
                <MessagesAlertComp/>
                <UserDropDownComp :first_name="fname"/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery"
import NotificationsComp from "@/components/NotificationsComp.vue"
import MessagesAlertComp from "@/components/MessagesAlertComp.vue"
import UserDropDownComp from "@/components/UserDropDownComp.vue"

const $ = jQuery;
window.$ = $;

export default {
  name: "NavBarComp",
  components: {
      NotificationsComp, MessagesAlertComp, UserDropDownComp
  },


 created(){

  const user_data = JSON.parse(localStorage.getItem("users"));
   this.fname = user_data.fname;

   const provider_data = JSON.parse(localStorage.getItem("provider_data"));

   if(localStorage.getItem("Portall") == 'Gerdd'){
      this.portal_name = "Gerrds";
   }
   else if(localStorage.getItem("Portall") == 'Provider')
   {
     this.portal_name = provider_data[0].provider;
   }
   else if(localStorage.getItem("Portall") == 'Dependent')
   {
     this.portal_name = "Dependent";
   }
     else if(localStorage.getItem("Portall") == 'Support')
   {
     this.portal_name = "Support";
   }
   
 },

  data() {
    return {
      isActive: false,
      portal_name:"",
      getdateTime:"",
      dateTimeInterval:"",
      fname:""
    }
  },
  methods: {
   toggleHamburger: function() {
      this.isActive = !this.isActive;
      if(this.isActive == true){
        document.body.classList.add('sidebar-hide');
      } else {
        document.body.classList.remove('sidebar-hide');
      }
    },


   execDateTime:function(){
             this.dateTimeInterval = setInterval(() => {
           
            const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
           this.changes = date +' '+ time;
          

          const x = new Date()
          const x1=x.toUTCString();
          this.changes = x1;


       }, 1000);
   }

  },


 computed:{

        changes : {
            get : function(){
                return this.getdateTime;
            },
            set : function(v){
                this.getdateTime =  v;
            }
        }
    },

  mounted () {
    this.execDateTime();
  },
}
</script>

<style scoped>
</style>