<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Payments" pageDesc="make payment"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton :num_checked="checked.length" @payBills="payAllBills"  portall="payAll" />









                                
                                    <div class="col-2 text-end">
                                        <a style="display:none" class="btn btn-outline-success" ref="singlePayment" data-bs-toggle="modal"  href="#makeFinalSinglePaymentModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="makeAllPayment" data-bs-toggle="modal"  href="#makeFinalAllPaymentModal">
                                            <i class="fas fa-plus"></i> All Payment
                                        </a>   

                                         <a class="btn btn-outline-success" style="display:none" ref="detailBill" data-bs-toggle="modal" href="#detailBillModule" >
                                            <i class="fas fa-plus"></i> Detail Bill
                                        </a>    

                                          <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a> 
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('billings.trans_date')">Transaction Date</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='billings.trans_date'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='billings.trans_date'">&darr;</span>
                                                </th>



                                                           <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Dependent Full Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider_contact')">Provider Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider_contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('billings.bill_number')">Billing Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='billings.bill_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='billings.bill_number'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('emedi_bal')">Emedi Charge</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_bal'">&darr;</span>
                                                </th>


                                               <th scope="col">
                                                <a href="#" @click.prevent="change_sort('prov_bal')">Provider's Charge</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_bal'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('total_bal')">Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>


                                  
    


                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(sb) in supportBilling" :key="sb.billing_id" :class="isChecked(sb.billing_id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="sb.billing_id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{sb.trans_date}}</td>
                                                    <td>{{sb.fullname}}</td>
                                                    <td>{{ sb.contact }}</td>
                                                    <td>{{ sb.provider }}</td>
                                                    <td>{{ sb.provider_contact }}</td>
                                                    <td>{{ sb.bill_number }}</td>
                                                    <td>{{ sb.emedi_bal }}</td>
                                                    <td>{{ sb.prov_bal }}</td>
                                                    <td>{{ sb.total_bal }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="View Detail" @click="viewDetail(sb.billing_id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </div>                                                  
                                                                <div  class="btn" title="Make Payment" @click="makeSinglePayment(sb.billing_id,sb.emedi_bal,sb.prov_bal,sb.total_bal)">
                                                                    <i class="fa-solid fa-dollar"></i>
                                                                </div>                                                  
                                                         
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Total</td>
                                                     <td>{{overall_emedi_bal}}</td>
                                                    <td>{{overall_prov_bal}}</td>
                                                    <td>{{ overall_total_bal }}</td>
                                                    <td><button class="btn btn-primary" @click="payy_all">Pay All</button></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div>


        <MakeFinalSinglePayment @paySucc="paySucc" :billing_id="billing_id" :emedi_bal="emedi_bal" :prov_bal="prov_bal"  :total_cost="total_cost" :support_id="support_id" :view_type="view_type" />
     
        <MakeFinalAllPayment @paySucc="paySucc" :billing_id="billing_id"   :emedi_bal="emedi_bal" :prov_bal="prov_bal"   :total_cost="total_cost"   :support_id="support_id" :view_type="view_type" />
        
        
        
        <DetailBill :billing_detail_item="billing_detail_item" :billing_sum="billing_sum" :b_emedi_charge="b_emedi_charge" :t_bal="t_bal" />
        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import MakeFinalSinglePayment from "@/components/modalForms/MakeFinalSinglePayment"
import MakeFinalAllPayment from "@/components/modalForms/MakeFinalAllPayment"
import DetailBill from "@/components/modalForms/DetailBill"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "MakePaymentView",
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    MakeFinalSinglePayment,
    MakeFinalAllPayment,
    DetailBill
  },



  data(){
    return {
        supportBilling:{},
        showUserModals:false,
        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        checked:[],
        user_id:"",
        view_type:"",
        support_id:"",
        selectPage:false,
        billing_id:"",
        total_cost:"",
        overall_total_bal:"",
        overall_emedi_bal:"",
        overall_prov_bal:"",

        billing_detail_item:[],
        billing_sum:"",
        b_emedi_charge:"",
        t_bal:"",
        emedi_bal:"",
        prov_bal:"",





        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',
    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('all_support_billings/all?support_id=' + this.support_id)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){

        this.table_sort_field_direction(field);
        this.getSupportBilling();

    },



   makeSinglePayment(billing_id,emedi_bal,prov_bal,total_bal){
    this.billing_id = billing_id;
    this.total_cost = total_bal;
    this.emedi_bal = emedi_bal;
    this.prov_bal = prov_bal;
    this.$refs.singlePayment.click();
    this.view_type = "single_pay";
    //console.log(id);

    //    axios.delete(`user_portals?bill_id=${id}&support_id=${this.support_id}`)
    //    .then( (response) => {
    //     this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
    //     //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
    //     console.log(response.data.success)
    //     notificationBox.succNotifications("Payment Done Successfully");
    //      this.getUser();
    //    }).catch(error => {
    //     console.log(error.message)
    //      notificationBox.errorNotifications(error)
    //    })


   },



   
payAllBills(){
    this.billing_id = this.checked;
    this.emedi_bal = this.overall_emedi_bal;
    this.prov_bal = this.overall_prov_bal;
    this.total_cost = this.overall_total_bal;
    this.$refs.makeAllPayment.click();
    this.view_type = "pay_all";
},
  


payy_all(){
   this.checked = [];
       //  this.table_checked([]);
const trufy = true;
        if(trufy){

            const users = JSON.parse(JSON.stringify(this.supportBilling));
            
            users.forEach(user=>{
                this.checked.push(user.billing_id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }
   this.payAllBills();
},

   deleteRecords(){
      axios.delete(`user_portals/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Users were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getUser();

         }
      })
   },








   async viewDetail(billing_id)
   {


     const response = await axios.get(`getDetailBilling?billing_id=${billing_id}`);
        this.billing_detail_item = response.data.detail_items;
        this.billing_sum = response.data.total_bill_sum;
        this.b_emedi_charge = response.data.emedi_charge_bal;
        this.t_bal = response.data.total_bal;


        this.$refs.detailBill.click();
  



   },



 


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getSupportBilling();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getSupportBilling(){
   
        axios.get(
            'transaction_processes/support_billings?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&support_id=' + this.support_id 
            )
        .then(response => {

            this.supportBilling = response.data.data;
            this.getSupportBal();
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getSupportBilling();

    },


    searchval(value){

         this.table_search(value);
        this.getSupportBilling();

    },

    statusval(value){

        this.table_status(value);
        this.getSupportBilling();

    },

 

   userEdit(portal_id,user_id){

    this.user_portal_id = portal_id;
    this.user_id = user_id;
    this.getUserPort(portal_id);
    this.view_type = "edit";
    this.$refs.add_edit_user.click();

   },



   //user_employee_country_updated(portal_id,user_id)



   userView(portal_id,user_id){

    this.user_portal_id = portal_id;
    this.user_id = user_id;
    this.view_type = "view";
    this.getUserPort(portal_id);
    
    this.$refs.add_edit_user.click();

   },





              async getUserPort(user_portal_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`user_portals/${user_portal_id}`);
            this.showLoading(false);
                this.getUserPortalData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },


   


     paySucc(){
        this.getSupportBilling();
     },





    async getSupportBal(){

        const response = await axios.get(`get_support_bal?support_id=${this.support_id}`);
        this.overall_total_bal = response.data.total_data;
        this.overall_emedi_bal = response.data.emedi_data;
        this.overall_prov_bal = response.data.prov_data;



      },
    
  
  },





 async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
          this.provider_id = user_data.provider_id;
         // console.log(this.provider_id);
          let user_idd = user_data.id;
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");



      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
 
        this.support_id =  JSON.parse(JSON.stringify(response.data.data));



          let responsee = await axios.post("getDependentId",user_dataa);
 
        this.dependent_id =  JSON.parse(JSON.stringify(responsee.data.data));
      
       
       
       
      


    //this.currentPage = 1;
    this.table_current_page(1);
    this.getSupportBilling();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const users = JSON.parse(JSON.stringify(this.supportBilling));
            
            users.forEach(user=>{
                this.checked.push(user.billing_id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "user_portals/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>