<template>
    <div class="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
        <div class="nano">
            <div class="nano-content">
                <div class="logo shadow-lg" style="max-height: 72px;">
                    <router-link to="/dashboard">
                        <img src="@/assets/eMedipay-logo-all-white-icon.png" style="height: 45px;"/>
                        <span class="font-white">eMedipay</span>
                    </router-link>
                </div>
                <ul class="nav nav-pills flex-column mb-auto mt-3">
                    <li class="label">Main</li>
                    <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link" v-if="user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' || user_level=='ADMIN' || user_level=='SUPPORT'">
                            <i class="ti-home"></i> Dashboard
                            <!--<span class="badge badge-primary">2</span>-->
                        </router-link>
                    </li>

                    <li class="label">Apps</li>


                     <li class="nav-item" v-if="user_level=='GLOBAL'">

                        <router-link to="/health_care" class="nav-link" data-bs-toggle="collapse" data-bs-target="#settings-collapse" aria-expanded="false" v-if="this.$route.name=='HealthCareView'">
                        <i class="fa-solid fa-cogs me-1"></i>
                        Settings
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>


                        <router-link to="/percentage_charge" class="nav-link" data-bs-toggle="collapse" data-bs-target="#settings-collapse" aria-expanded="false" v-else-if="this.$route.name=='PercentageChargeView'">
                        <i class="fa-solid fa-cogs me-1"></i>
                        Settings
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>



                                 <router-link to="/percentage_charge" class="nav-link" data-bs-toggle="collapse" data-bs-target="#settings-collapse" aria-expanded="false" v-else>
                        <i class="fa-solid fa-cogs me-1"></i>
                        Settings
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                   

                        <div class="collapse" id="settings-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item"> 
                            <router-link to="/health_care" class="nav-link ps-5">
                                Health Care
                            </router-link>
                            </li>
                         <li class="nav-item">
                            <router-link to="/percentage_charge" class="nav-link ps-5">
                                Percentage Charge
                            </router-link>
                            </li>
                        </ul>
                        </div>
                    </li>






                    <li class="nav-item" v-if="user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' || user_level=='ADMIN'">
                        <router-link to="/providers" class="nav-link" data-bs-toggle="collapse" data-bs-target="#active-providers-collapse" aria-expanded="false">
                        <i class="fa-solid fa-hospital me-1"></i>
                        Providers
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <div class="collapse" id="active-providers-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='ADMIN' || user_level=='LOCAL'"> 
                            <router-link to="/providers" class="nav-link ps-5">
                                Manage Providers
                            </router-link>
                            </li>
                         <!-- <li class="nav-item" v-if="user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' || user_level=='ADMIN'">
                            <router-link to="/active-providers" class="nav-link ps-5">
                                Active Providers
                            </router-link>
                            </li> -->
                        </ul>
                        </div>
                    </li>





                  <li class="nav-item" v-if="user_level=='ADMIN' || user_level=='SUPPORT'">
                        <router-link to="/transact" class="nav-link" data-bs-toggle="collapse" data-bs-target="#active-transactions-collapse" aria-expanded="false" v-if="this.$route.name=='TransactView'">
                        <i class="fa-solid fa-dollar me-1"></i>
                        Transaction
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <router-link to="/make_payment" class="nav-link" data-bs-toggle="collapse" data-bs-target="#active-transactions-collapse" aria-expanded="false" v-else-if="this.$route.name=='MakePaymentView'">
                        <i class="fa-solid fa-dollar me-1"></i>
                        Transaction
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>


                           <router-link to="/transact" class="nav-link" data-bs-toggle="collapse" data-bs-target="#active-transactions-collapse" aria-expanded="false" v-else>
                        <i class="fa-solid fa-dollar me-1"></i>
                        Transaction
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>





                        <div class="collapse" id="active-transactions-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_level =='ADMIN'"> 
                            <router-link to="/transact" class="nav-link ps-5">
                                Bill Dependent Transaction
                            </router-link>
                            </li>

                         <li class="nav-item" v-if="user_level == 'SUPPORT'"> 
                            <router-link to="/make_payment" class="nav-link ps-5">
                                Make Payment
                            </router-link>
                            </li>
  
                        </ul>
                        </div>
                    </li>


                    
                    <li class="nav-item" v-if="user_level=='GLOBAL'  || user_level=='COUNTRY'">
                        <router-link to="/support-members" class="nav-link">
                        <i class="fa-solid fa-coins me-1"></i>
                        Support Members
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="user_level=='SUPPORT' || user_level=='SUPPORT' || user_level=='GLOBAL'">
                        <router-link to="/dependants" class="nav-link">
                        <i class="fa-solid fa-hospital-user me-1"></i>
                        Dependants
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="user_level=='GLOBAL' || user_level=='ADMIN' || user_level=='SUPPORT'">
                        <router-link to="/users" class="nav-link" data-bs-toggle="collapse" data-bs-target="#users-collapse" aria-expanded="false">
                        <i class="fa-solid fa-users me-1"></i>
                        Manage Users
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>
                        <div class="collapse" id="users-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li class="nav-item">
                            <router-link to="/users" class="nav-link ps-5">
                                Users
                            </router-link>
                            </li>
                            <!-- <li class="nav-item">
                            <router-link to="/link-users-to-providers" class="nav-link ps-5">
                                Link Users to Providers
                            </router-link>
                            </li> -->
                        </ul>
                        </div>
                    </li>




                    <li class="nav-item" v-if="user_level=='ADMIN' ||  user_level=='ACCOUNTANT'  || user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' ||  user_level=='SUPPORT'">
                        <router-link to="/report" class="nav-link" data-bs-toggle="collapse" data-bs-target="#reports-collapse" aria-expanded="false">
                        <i class="fa-solid fa-chart-column me-1"></i>
                        Report
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>
                        <div class="collapse" id="reports-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li class="nav-item" v-if="user_level=='ADMIN' ||  user_level=='ACCOUNTANT'  || user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL'">
                            <router-link to="/debtors" class="nav-link ps-5">
                                Debtors
                            </router-link>
                            </li>

                            <li class="nav-item" v-if="user_level=='ADMIN' ||  user_level=='ACCOUNTANT'  || user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' ||  user_level=='SUPPORT'">
                            <router-link to="/report" class="nav-link ps-5">
                                Reports
                            </router-link>
                            </li>
                            <!-- <li class="nav-item">
                            <router-link to="/link-users-to-providers" class="nav-link ps-5">
                                Link Users to Providers
                            </router-link>
                            </li> -->
                        </ul>
                        </div>
                    </li>




                    <!-- <li class="nav-item">
                        <router-link to="/form" class="nav-link">
                        <i class="fas fa-chart-column me-1"></i>
                        Reports
                        </router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "SideBarComp",
  created(){
    const user_data = JSON.parse(localStorage.getItem("users"));
    this.user_level = user_data.user_level;
  },

  data(){
    return {
        user_level:"",
    }
  }

};
</script>

<style scoped>
.nav-link{
  color: #bdbdc7;
  border-radius: 0 !important;
  padding: 1em 1.5em;
}

.nav-link.router-link-active, .nav-link:hover{  
    background-color: #6b51df !important;
    color: #fff;
}
</style>