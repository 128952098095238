<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
            <div class="main">
                <div class="container-fluid">
                    <HeaderComp page="Dashboard" :pageDesc="current_year"/>
                    
                    <section id="main-content">
                        <div class="row" v-if="formData.user_level === 'COUNTRY' || formData.user_level === 'LOCAL'">
                           <div class="col-lg-12">

                                <div class="register-form card shadow-sm" id="stylized">
                                     <div class="card-body">

                                <label v-if="formData.user_level === 'COUNTRY'">Select Country</label>
                                <label v-if="formData.user_level === 'LOCAL'">Select Provider</label>
                            <div class="input-group">
                          
                          <select class="form-control" v-model="formData.user_level_section" v-if="formData.user_level === 'COUNTRY'">
                            <option  v-for="(count) in getCountries" :key="count.id" :value="count.id">{{count.country}}</option>
                          
                          </select>

                          <select class="form-control" v-model="formData.user_level_section" v-if="formData.user_level === 'LOCAL'">
                            <option  v-for="(prov) in getProviders" :key="prov.id" :value="prov.id">{{prov.prov_info}}</option>
                          
                          </select>
                          
                        </div>

                                     </div>
                                    </div>

                           </div>
                        </div>
                        <div class="row">
                             <input type="hidden" v-model="formData.user_level" />
                            <input type="hidden" v-model="formData.app_portal" />
                            <div class="col-lg-3" v-if="formData.app_portal === 'Gerdd' ||  formData.app_portal === 'Provider'"> 
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-money color-success border-success"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Amount Received</div>
                                            <div class="stat-digit">{{total_amount_receive}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                              <div class="col-lg-3"> 
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-money color-danger border-danger"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text" v-if="formData.app_portal != 'Support'">Total Debtors</div>
                                            <div class="stat-text" v-else>Total Amount Owe</div>
                                            <div class="stat-digit">{{total_debtors}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                             <div class="col-lg-3" v-if="formData.app_portal === 'Support'"> 
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-money color-success border-success"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Amount Paid</div>
                                            <div class="stat-digit">{{total_amount_receive}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3" v-if="formData.app_portal === 'Gerdd' ||  formData.app_portal === 'Provider'">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="fa-solid fa-coins me-1 color-primary border-primary"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Active Support</div>
                                            <div class="stat-digit">{{ total_active_support }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3" v-if="formData.app_portal === 'Gerdd' && (formData.user_level === 'GLOBAL' || formData.user_level === 'COUNTRY')">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="fa-solid fa-coins color-pink border-pink"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Pending Support</div>
                                            <div class="stat-digit">{{ total_pending_support }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="fa-solid fa-hospital-user me-1 color-danger border-danger"></i></div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Dependents</div>
                                            <div class="stat-digit">{{ total_active_dependent }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import SideBarComp from "@/components/SideBarComp.vue";
import NavBarComp from "@/components/NavBarComp.vue";
import {  mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';
import axios from "axios";


export default {
    name: "DashboardView",
    beforeRouteEnter(to,from,next){
      let user_level =  null;
const user_data = JSON.parse(localStorage.getItem("users"));
  user_level = user_data.user_level;


  
    
    if(user_level=='ADMIN' || user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' ||  user_level=='SUPPORT'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },

   async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
        this.formData.app_portal = localStorage.getItem("Portall");

           if(this.formData.app_portal === "Provider"){
          this.formData.provider_id = user_data.provider_id;
           }
         // console.log(this.provider_id);
          let user_idd = user_data.id;
          this.formData.login_user_id = user_idd;
          this.formData.user_level = user_data.user_level;
          let now = new Date();
          let year = now.getFullYear();
          this.current_year = "data for Year - "+year;

 

    },

    async beforeMount(){
     this.showLoading(true);
     if(this.formData.user_level === 'COUNTRY')
     {
    this.getCountriesRe();
    this.formData.user_level_section = await this.getSingleCountries();
     }

     else if(this.formData.user_level === 'LOCAL')
     {
    this.getLocalProviders();
    this.formData.user_level_section = await this.getSingleProviders();
     }

     if(this.formData.app_portal === 'Support'){
        this.formData.support_id = await this.getSupportId()
     }

         this.getDashbordInfo();




   },


       mounted(){
     this.showLoading(false);
   },

    data:  function() {
        return {
            total_amount_receive:null,
            total_active_support:null,
            total_pending_support:null,
            total_active_dependent:null,
            total_debtors:null,
            error_mess: null,
            current_year: null,
            pageDesc:null,
            getCountries:[],
            getProviders:[],

            formData:{
            app_portal: null,
            user_level: null,
            login_user_id:null,
            provider_id:null,
            support_id:null,
            user_level_section:0,
            }
        }
    },
    components: {
        HeaderComp,
        FooterComp,
        SideBarComp, 
        NavBarComp,
    },
    props: {
  },






    methods:{

       ...mapMutations({
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),



  async getDashbordInfo(){

      

                 try{

         //   console.log(this.formData)



            let response = await axios.post("getDashBoardInfo",this.formData);

            if(response.status !== 200)
            {
                throw new Error("Something Went Wrong")
            }

          //total_debtors
               this.total_amount_receive = response.data.total_bal;
               this.total_active_support = response.data.total_active_support;
               this.total_pending_support = response.data.total_pending_support;
               this.total_active_dependent = response.data.total_active_dependent;
               this.total_debtors = response.data.total_debtors;
              
                 }catch(error){
                   console.log(error)
                 }

   },


    async getCountriesRe(){
           try{
       let response = await axios.get(`getCountriesRe?user_id=${this.formData.login_user_id}&&user_level=${this.formData.user_level}`);
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
       // console.log(this.getCountries);
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},





     async getSingleCountries(){
           try{
       let response = await axios.get(`getSingleCountries?user_id=${this.formData.login_user_id}&&user_level=${this.formData.user_level}`);
        return JSON.parse(response.data.data.id);
       //console.log(this.formData.user_level_section);
      
    }catch(error){
        console.log(error);
    }
},






    async getLocalProviders(){
           try{
       let response = await axios.get(`getLocalProvider?user_id=${this.formData.login_user_id}`);
        this.getProviders =  response.data.data;
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},





 async getSingleProviders(){
           try{
       let response = await axios.get(`getSingleLocalProvider?user_id=${this.formData.login_user_id}`);
        return JSON.parse(response.data.data.id);
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},






async getSupportId(){
       
try{
             const  user_dataa = {
            user_id : this.formData.login_user_id
        }
       let response = await axios.post("getSupportId",user_dataa);
 
        return JSON.parse(response.data.data);

}catch(error){
    console.log(error);
}
        
}



    }






};
</script>