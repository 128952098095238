import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import LoginOtp from "@/views/LoginOtp.vue";
import DashboardView from "@/views/DashboardView.vue";
//import RegisterView from "@/views/RegisterView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import RegistrationInfoPage from "@/views/RegistrationInfoPage.vue";
import ActivationView from "@/views/ActivationView.vue";
import SelectPortalView from "@/views/SelectPortalView.vue";
import ProvidersView from "@/views/ProvidersView.vue";
import HealthCareView from "@/views/HealthCareView.vue";
import PercentageChargeView from "@/views/PercentageChargeView.vue";
import SupportMembersView from "@/views/SupportMembersView.vue";
import DependantsView from "@/views/DependantsView.vue";
import AllUsersView from "@/views/AllUsersView.vue";
import ReportView from "@/views/ReportView.vue";
import LinkUsersToProvidersView from "@/views/LinkUsersToProvidersView.vue";
import TransactView from "@/views/TransactView.vue";
import MakePaymentView from "@/views/MakePaymentView.vue";
import AllDebtorsView from "@/views/AllDebtorsView.vue";
import RegisterT from "@/views/RegisterT.vue";
import NotFound from "@/views/NotFound.vue";
import UserNotAuthorise from "@/views/UserNotAuthorise.vue";
//import { isLoggedIn } from "@/services/AuthService";

const routes = [{
    path: "/",
    name: "SelectPortalView",
    component: SelectPortalView,
},
    {
        path: "/register",
        name: "RegisterT",
        component: RegisterT,
    },
    {
        path: "/support_login",
        name: "support_login",
        component: LoginView,
    },
    {
        path: "/provider_login",
        name: "provider_login",
        component: LoginView,
    },
    {
        path: "/dependent_login",
        name: "dependent_login",
        component: LoginView,
    },
    {
        path: "/telemedicine_login",
        name: "telemedicine_login",
        component: LoginView,
    },
    {
        path: "/em_login",
        name: "em_login",
        component: LoginView,
    },
    {
        path: "/login_otp",
        name: "login_otp",
        component: LoginOtp,
    },
    {
        path: "/dashboard",
        name: "DashboardView",
        component: DashboardView,
    },

    {
        path: "/forgot-password",
        name: "ForgotPasswordView",
        component: ForgotPasswordView,
    },
    {
        path: "/reset/:code",
        name: "ResetPasswordView",
        component: ResetPasswordView,
    },

    {
        path: "/reg-info",
        name: "RegistrationInfoPage",
        component: RegistrationInfoPage,
    },


    

    {
        path: "/activation/:code/:port/:prov_id",
        name: "ActivationView",
        component: ActivationView,
    },



    {
        path: "/health_care",
        name: "HealthCareView",
        component: HealthCareView,
    },




    
    {
        path: "/percentage_charge",
        name: "PercentageChargeView",
        component: PercentageChargeView,
    },

    
   
    {
        path: "/providers",
        name: "ProvidersView",
        component: ProvidersView,
    },

    {
        path: "/transact",
        name: "TransactView",
        component: TransactView,
    },

    {
        path: "/support-members",
        name: "SupportMembersView",
        component: SupportMembersView,
    },
    {
        path: "/dependants",
        name: "DependantsView",
        component: DependantsView,
    },

    {
        path: "/make_payment",
        name: "MakePaymentView",
        component: MakePaymentView,
    },

    {
        path: "/users",
        name: "AllUsersView",
        component: AllUsersView,
    },



        {
        path: "/report",
        name: "Report",
        component: ReportView,
    },



    {
        path: "/debtors",
        name: "AllDebtorsView",
        component: AllDebtorsView,
    },


    {
        path: "/link-users-to-providers",
        name: "LinkUsersToProvidersView",
        component: LinkUsersToProvidersView,
    },
    {
        path: "/user-authorise-access",
        name: "user-not-authorise",
        component: UserNotAuthorise,
    },
    {path: '/:NotFound(.*)*', name: "pagge-not-found", component: NotFound}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});






export default router;