<template>
  <section id="main-content">
      <div class="row">
          <div class="col-lg-12">
              <div id="extra-area-chart"></div>
              <div id="morris-line-chart"></div>
              <div class="footer">
                  <p>2022 © eMedipay Admin. -
                      <a href="http://emedipay.com">emedipay.com</a>
                  </p>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>

<style scoped></style>