<template>
    <div class="col-3 text-end">
        <div class="d-flex align-items-center ml-4">
            <label for="paginate" class="text-nowrap mr-2 mb-0" v-if="portall=='yesno'"><strong>FilterBy Is Active</strong></label>
            <label for="paginate" class="text-nowrap mr-2 mb-0" v-else><strong>FilterBy Status</strong></label>
            <select v-model="status" class="form-control form-control-sm" v-if="portall==''">
                <option value="">All Status</option>
                <option value="PENDING">PENDING</option>
                <option value="APPROVED">APPROVED</option>
                <option value="REJECTED">REJECTED</option>
                <option value="BAN">BAN</option>
            </select>
            <select v-model="status" class="form-control form-control-sm" v-else-if="portall=='provider'">
                <option value="">All Status</option>
                <option value="PENDING">PENDING</option>
                <option value="APPROVED">APPROVED</option>
                <option value="REJECTED">REJECTED</option>
            </select>

            <select v-model="status" class="form-control form-control-sm" v-else-if="portall=='support'">
                <option value="">All Status</option>
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
                <option value="PENDING">PENDING</option>
            </select>



 


            <select v-model="status" class="form-control form-control-sm" v-else-if="portall=='debtors'">
                <option value="">DEBTORS</option>
                <option value="BILLS">BILLS</option>
            </select>


             <select v-model="status" class="form-control form-control-sm" v-else-if="portall=='yesno'">
                <option value="">ALL</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
            </select>
            

        </div>
     </div>
</template>

<script>
    export default {
        name:'TableFilter',
        props:['portall',"status_prop"],
        emits:['statusValue'],
        data(){
            return{
                status:""
            }
        },


        watch:{

            status(value){
                this.$emit('statusValue',value)
            },

            status_prop(value){
                this.status = value;
            }
        }
        
    }
</script>

<style scoped>

</style>