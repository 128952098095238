<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Report" pageDesc="report"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>


                            <div class="card shadow-sm" id="stylized">
                              <form class="register-form" @submit.prevent="searchConvert()">

                                <div class="card-body">



                                                                  <div class="row form_space" style="margin-bottom:10px">


                                  <div class="col" v-if="formData.user_level == 'LOCAL'">

                            <label><span class="text_error">*</span>Providers</label>
                             <div class="input-group">
                          

                         <v-select :options="getProviders" class="form-control select1" v-model="formData.provider_id"  :reduce="providers => providers.id" label="prov_info" @option:selected="providerVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: providerErr }"
                            v-if="providerErr"
                          >
                            {{ providerErr }}
                          </div>

                                  </div>











                                          <div class="col" v-if="formData.user_level == 'COUNTRY'">

                            <label><span class="text_error">*</span>Countries</label>
                             <div class="input-group">
                          

                         <v-select :options="getCountries" class="form-control select1" v-model="formData.country_id"  :reduce="countries => countries.id" label="country" @option:selected="countryVal" />



                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: countryErr }"
                            v-if="countryErr"
                          >
                            {{ countryErr }}
                          </div>

                                  </div>


                                </div>



                              <div class="row form_space" style="margin-bottom:10px">
                
                          <div class="col">
                                <label><span class="text_error">*</span>Report Category</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getReportCate"   class="form-control select1" v-model="formData.report_cate"  :reduce="report_cate => report_cate.report_cate" label="report_cate_by_act_name" @option:selected="report_cateVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: report_cateErr }"
                            v-if="report_cateErr"
                          >
                            {{ report_cateErr }}
                          </div>

                      </div>








                      
                                 <div class="col" v-if="formData.report_cate=='SupportPayment' || formData.report_cate=='SupportPaymentBetweenDate' || formData.report_cate=='DependentsVisit' || formData.report_cate == 'ProvidersDependentsList' || formData.report_cate == 'ProvidersDebtors' || formData.report_cate == 'ProvidersDebtorsBetweenDate'">
                                <label><span class="text_error">*</span>Dependents</label>
                             <div class="input-group">
                          

                         <v-select :options="getDependents" class="form-control select1" v-model="formData.dependent_id"  :reduce="dependents => dependents.id" label="full_name" @option:selected="dependentVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: dependentErr }"
                            v-if="dependentErr"
                          >
                            {{ dependentErr }}
                          </div>
                        </div>







                                 <div class="col" v-if="formData.report_cate=='ListOfSupport'">
                                <label><span class="text_error">*</span>Supports</label>
                             <div class="input-group">
                          

                         <v-select :options="getSupports" class="form-control select1" v-model="formData.support_id"  :reduce="supports => supports.id" label="full_name" @option:selected="supportVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: supportErr }"
                            v-if="supportErr"
                          >
                            {{ supportErr }}
                          </div>
                        </div>












                                    <div class="col" v-if="formData.report_cate=='ListOfDependent' ||  formData.report_cate=='GerrdsDebtors' ||  formData.report_cate=='GerrdsDebtorsBetweenDate'">
                                <label><span class="text_error">*</span>Dependents</label>
                             <div class="input-group">
                          

                         <v-select :options="getDependents" class="form-control select1" v-model="formData.dependent_id"  :reduce="dependents => dependents.id" label="full_name" @option:selected="dependentVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: dependentErr }"
                            v-if="dependentErr"
                          >
                            {{ dependentErr }}
                          </div>
                        </div>












                              <div class="col">
                              <label>View Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: view_typeErr}"
                             @change="view_typeVal($event.target.value)"
                            v-model.trim="formData.view_type">
                            <option value="">--Choose An Option--</option>
                            <option value="GRID">GRID</option>
                            <option value="REPORT">REPORT</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: view_typeErr}"
                            v-if="view_typeErr"
                          >
                            {{ view_typeErr }}
                          </div><br/>


                      </div>




                 
                 


                    </div>




      <div class="row form_space" style="margin-bottom:10px" v-if="formData.report_cate == 'SupportsDependentList'  || formData.report_cate == 'SupportPayment' || formData.report_cate == 'SupportPaymentBetweenDate' || formData.report_cate == 'ProvidersDebtors' || formData.report_cate == 'ProvidersDebtorsBetweenDate' || formData.report_cate == 'ProvidersPaymentsReceived' || formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate' || formData.report_cate == 'GerrdsDebtors'  || formData.report_cate == 'GerrdsDebtorsBetweenDate'    || formData.report_cate == 'PaymentsReceived'  || formData.report_cate == 'PaymentsReceivedBetweenDate'">
                
                          <div class="col" v-if="formData.report_cate == 'SupportsDependentList'">



                            <label>Status</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: statusErr}"
                             @change="is_activeVal($event.target.value)"
                            v-model.trim="formData.status">
                            <option value="">--Choose An Option--</option>
                            <option value="ALL">ALL</option>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: statusErr}"
                            v-if="statusErr"
                          >
                            {{ statusErr }}
                          </div><br/>




                          </div>



                         

                      <div class="col" v-if="formData.report_cate == 'SupportPaymentBetweenDate' || formData.report_cate == 'ProvidersDebtorsBetweenDate' || formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate' || formData.report_cate == 'GerrdsDebtorsBetweenDate' | formData.report_cate == 'PaymentsReceivedBetweenDate'">
                        <div class="form-group">
                          <label>From Date</label>
                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: from_dateErr }"
                            placeholder="From Date"
                            @blur="from_dateVal($event.target.value)"
                            v-model.trim="formData.from_date"
                          />
                          <div :class="{ text_error: from_dateErr }" v-if="from_dateErr">
                            {{ from_dateErr }} 
                          </div>
                        </div>
                      </div>



                          




                        
                      <div class="col" v-if="formData.report_cate == 'SupportPayment' || formData.report_cate == 'SupportPaymentBetweenDate' || formData.report_cate == 'ProvidersDebtors' || formData.report_cate == 'ProvidersDebtorsBetweenDate' || formData.report_cate == 'ProvidersPaymentsReceived' || formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate'   || formData.report_cate == 'GerrdsDebtors' || formData.report_cate == 'GerrdsDebtorsBetweenDate'  || formData.report_cate == 'PaymentsReceived' || formData.report_cate == 'PaymentsReceivedBetweenDate'">
                        <div class="form-group">
                          <label>To Date</label>
                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: to_dateErr }"
                            placeholder="To Date"
                            @blur="to_dateVal($event.target.value)"
                            v-model.trim="formData.to_date"
                          />
                          <div :class="{ text_error: to_dateErr }" v-if="to_dateErr">
                            {{ to_dateErr }} 
                          </div>
                        </div>
                      </div>




                          </div>











                                <button type="submit" class="btn btn-primary">Generate Report</button>

                         
                                   
                                </div>
                                 </form>


                            <!-- Table Goes Here -->

                            </div>

                            

                              <div class="col-md-12" v-if="formData.view_type=='GRID' && view_grid"> 
                              <div class="row">

                                   
                                    <PerPageComponent @perpageValue="perpageval" />

                                    <!-- <TableFilter @statusValue="statusval" portall="" /> -->

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                         <div class="col-5 text-end">
                        

                                        <div v-if="view_grid && formData.report_cate == 'SupportCreditors'">Total Amount Owe:{{ total_bal }} </div>
                                        <div v-else-if="view_grid && formData.report_cate == 'DependentsVisit'">Total Number Of Providers Visited: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                        <div v-else-if="view_grid && formData.report_cate == 'SupportsDependentList'">
                                          <div>Total Number Of Dependents: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Status: {{ formData.status }}</div>

                                        </div>



                                        <div v-else-if="view_grid && formData.report_cate == 'SupportPayment'">
                                          <div>Total Number Of Payments: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Paid: {{ total_bal }}</div>

                                        </div>




                                        <div v-else-if="view_grid && formData.report_cate == 'SupportPaymentBetweenDate'">
                                          <div>Total Number Of Payments: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Paid: {{ total_bal }}</div>

                                        </div>



                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>

                                        </div>


                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id != 'ALL'">
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                          <div>Total Number Of Support Under Selected Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>

                                        </div>






                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersDebtors' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependents Owing: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : "" }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>


                                        </div>


                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersDebtors' && formData.dependent_id != 'ALL'">
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                          <div>Total Number Of Support Under Selected Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>

                                        </div>




                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependents Owing: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : "" }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>


                                        </div>



                                          <div v-else-if="view_grid && formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id != 'ALL'">
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                          <div>Total Number Of Support Under Selected Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : ""  }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>

                                        </div>


                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersPaymentsReceived'">
                                          <div>Total Number Of Payments Received: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Received: {{ total_bal ? total_bal.t_amount_paid : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>
                                        </div>


                                        <div v-else-if="view_grid && formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate'">
                                          <div>Total Number Of Payments Received: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Received: {{ total_bal ? total_bal.t_amount_paid : ""  }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>
                                        </div>



                                        
                                        <div v-else-if="view_grid && formData.report_cate == 'ListOfSupport' && formData.support_id == 'ALL'">
                                          <div>Total Number Of Supports: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Support: ALL</div>
                                        </div>

                                        <div v-else-if="view_grid && formData.report_cate == 'ListOfSupport' && formData.support_id != 'ALL'">
                                          <div>Total Number Of Dependent Under Support: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Support: {{ this.getSingleSupportInfo ? this.getSingleSupportInfo : "" }}</div>
                                        </div>






                                        <div v-else-if="view_grid && formData.report_cate == 'ListOfDependent' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependents: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Dependent: ALL</div>
                                        </div>

                                        <div v-else-if="view_grid && formData.report_cate == 'ListOfDependent' && formData.support_id != 'ALL'">
                                          <div>Total Number Of Support Under Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                        </div>





                                        
                                        <div v-else-if="view_grid && formData.report_cate == 'GerrdsDebtors' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependents Owing: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : "" }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>


                                        </div>





                                            <div v-else-if="view_grid && formData.report_cate == 'GerrdsDebtors' && formData.dependent_id != 'ALL'">
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                          <div>Total Number Of Support Under Selected Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>

                                        </div>










                                        <div v-else-if="view_grid && formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id == 'ALL'">
                                          <div>Total Number Of Dependents Owing: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : "" }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>


                                        </div>



                                          <div v-else-if="view_grid && formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id != 'ALL'">
                                          <div>Dependent: {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</div>
                                          <div>Total Number Of Support Under Selected Dependent: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Owing: {{ total_bal ? total_bal.total_bal : ""  }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>

                                        </div>





                                           <div v-else-if="view_grid && formData.report_cate == 'PaymentsReceived'">
                                          <div>Total Number Of Payments Received: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Received: {{ total_bal ? total_bal.t_amount_paid : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>
                                        </div>




                                              <div v-else-if="view_grid && formData.report_cate == 'PaymentsReceivedBetweenDate'">
                                          <div>Total Number Of Payments Received: {{ grid_converts ? grid_converts.length : 0 }}</div>
                                          <div>Total Amount Received: {{ total_bal ? total_bal.t_amount_paid : ""  }}</div>
                                          <div>From Date: {{ formData.from_date ? formData.from_date : ""  }}</div>
                                          <div>To Date: {{ formData.to_date ? formData.to_date : ""  }}</div>
                                        </div>


                                       
                                        <div v-if="getsearch">Filter Text : {{ getsearch }} </div>
                                        <!-- <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>    -->

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                        </div>






                                  <div class="card shadow-sm">
                                  <div class="card-body" v-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'SupportCreditors'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>


                                              <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('providers.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Providers Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.provider}}</td>
                                                    <td>{{cov.provider_contact }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>








                                       <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'DependentsVisit'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>


                                              <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('providers.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>

                                            



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.provider}}</td>
                                                    <td>{{cov.provider_contact }}</td>
                                                    <td>{{cov.fullname }}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>













                           <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'SupportsDependentList'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>

                                            



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>


















                        <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'SupportPayment'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.bill_amount')">Bill Amount</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.bill_amount'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.bill_amount'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.amount_paid')">Amount Paid</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.amount_paid'">&darr;</span>
                                                </th>





                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.trans_date')">Transaction Date</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.trans_date'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.trans_date'">&darr;</span>
                                                </th>


                                            



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.bill_amount }}</td>
                                                    <td>{{cov.amount_paid }}</td>
                                                    <td>{{cov.trans_date }}</td>
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>














                                <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'SupportPaymentBetweenDate'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.bill_amount')">Bill Amount</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.bill_amount'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.bill_amount'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.amount_paid')">Amount Paid</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.amount_paid'">&darr;</span>
                                                </th>





                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.trans_date')">Transaction Date</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.trans_date'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.trans_date'">&darr;</span>
                                                </th>


                                            



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.bill_amount }}</td>
                                                    <td>{{cov.amount_paid }}</td>
                                                    <td>{{cov.trans_date }}</td>
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>













                     <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDependentsList' && formData.dpendent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>











        <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDependentsList' && formData.dpendent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Suport Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.email'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.gender')">Support Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.gender'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.city')">City</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.city'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.country }}</td>
                                                    <td>{{cov.city }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>















                                 <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDebtors' && formData.view_type == 'GRID' && formData.dependent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>











                                <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDebtors' && formData.view_type == 'GRID' && formData.dependent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>











        <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.view_type == 'GRID' && formData.dependent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>














                                      <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.view_type == 'GRID' && formData.dependent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>











                                        <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersPaymentsReceived' && formData.view_type == 'GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.receipt_num')">Receipt Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.receipt_num'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.receipt_num'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.prov_amount_paid')">Amount Received</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.prov_amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.prov_amount_paid'">&darr;</span>
                                                </th>

                                            



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.depp_fullname}}</td>
                                                    <td>{{cov.depp_contact }}</td>
                                                    <td>{{cov.supp_fullname }}</td>
                                                    <td>{{cov.supp_contact }}</td>
                                                    <td>{{cov.receipt_num }}</td>
                                                    <td>{{cov.prov_amount_paid }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>










                              

                                <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate' && formData.view_type == 'GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.receipt_num')">Receipt Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.receipt_num'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.receipt_num'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.prov_amount_paid')">Amount Received</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.prov_amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.prov_amount_paid'">&darr;</span>
                                                </th>

                                            



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.depp_fullname}}</td>
                                                    <td>{{cov.depp_contact }}</td>
                                                    <td>{{cov.supp_fullname }}</td>
                                                    <td>{{cov.supp_contact }}</td>
                                                    <td>{{cov.receipt_num }}</td>
                                                    <td>{{cov.prov_amount_paid }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>




















                            <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ListOfSupport' && formData.support_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.email'">&darr;</span>
                                                </th>



                                                    <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supports.status')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supports.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supports.status'">&darr;</span>
                                                </th>



                                                

                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.status }}</td>
                                                
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>









                                       <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ListOfSupport' && formData.support_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dependent_supports.status')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dependent_supports.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dependent_supports.status'">&darr;</span>
                                                </th>



                                                

                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.status }}</td>
                                                
                                              
                                             
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>








                                <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ListOfDependent' && formData.dependent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>



                                                    <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dependents.is_active')">Is Active</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dependents.is_active'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dependents.is_active'">&darr;</span>
                                                </th>



                                                

                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.is_active }}</td>
                                                
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>









                                       <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'ListOfDependent' && formData.dependent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.email'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dependent_supports.status')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dependent_supports.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dependent_supports.status'">&darr;</span>
                                                </th>



                                                

                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.status }}</td>
                                                
                                              
                                             
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>























<div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'GerrdsDebtors' && formData.view_type == 'GRID' && formData.dependent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>













                                  <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'GerrdsDebtors' && formData.view_type == 'GRID' && formData.dependent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>

















                                  <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.view_type == 'GRID' && formData.dependent_id == 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.email')">Dependent Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>














                                      <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.view_type == 'GRID' && formData.dependent_id != 'ALL'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Support Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi - Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>

                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('total_bal')">Total Amount Owe</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <td>{{cov.email }}</td>
                                                    <td>{{cov.prov_total_bal }}</td>
                                                    <td>{{cov.emedi_total_bal }}</td>
                                                    <td>{{cov.total_bal }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>















                                 <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'PaymentsReceived' && formData.view_type == 'GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.receipt_num')">Receipt Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.receipt_num'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.receipt_num'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.emedi_amount_paid')">Amount Received</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.emedi_amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.emedi_amount_paid'">&darr;</span>
                                                </th>

                                            



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.depp_fullname}}</td>
                                                    <td>{{cov.depp_contact }}</td>
                                                    <td>{{cov.supp_fullname }}</td>
                                                    <td>{{cov.supp_contact }}</td>
                                                    <td>{{cov.receipt_num }}</td>
                                                    <td>{{cov.emedi_amount_paid }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>



















                                  <div class="card-body" v-else-if="formData.view_type=='GRID' && view_grid && formData.report_cate == 'PaymentsReceivedBetweenDate' && formData.view_type == 'GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                         
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.fullname')">Dependent Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('dep_user.contact')">Dependent Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dep_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dep_user.contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.fullname')">Support Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.fullname'">&darr;</span>
                                                </th>


                                                 <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('supp_user.contact')">Support Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='supp_user.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='supp_user.contact'">&darr;</span>
                                                </th>


                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.receipt_num')">Receipt Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.receipt_num'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.receipt_num'">&darr;</span>
                                                </th>



                                                
                                                  <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('payments.emedi_amount_paid')">Amount Received</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='payments.emedi_amount_paid'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='payments.emedi_amount_paid'">&darr;</span>
                                                </th>

                                            



                                             



                                     

                                               


                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.depp_fullname}}</td>
                                                    <td>{{cov.depp_contact }}</td>
                                                    <td>{{cov.supp_fullname }}</td>
                                                    <td>{{cov.supp_contact }}</td>
                                                    <td>{{cov.receipt_num }}</td>
                                                    <td>{{cov.emedi_amount_paid }}</td>
                                              
                                                    <!-- <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>





      <a href="#" @click="printDiv" style="width:100px" class="btn btn-secondary" v-if="view_report">
        <i class="icon-printer"></i> Print
    </a>

     <vue-easy-print tableShow ref="easyPrint"  v-if="view_report"> 
<div class="card-body"  v-if="formData.view_type=='REPORT' && view_report">

    <table width="100%" v-if="formData.view_type == 'REPORT' && app_portal == 'Support'">
    <tr>
        <!-- <td valign="top"><img src="{{base_path().'/public/image/logo.png'}}" alt="" width="150"/></td> -->
        <td align="right">
            <b>Report</b>
            <pre>


              {{ getSupportData.fullname }}
              {{ getSupportData.contact }}
              {{ getSupportData.email }}
            </pre>
        </td>
        
    </tr>

  </table>





     <table width="100%" v-else-if="formData.view_type == 'REPORT' && app_portal == 'Provider'">
    <tr>
        <!-- <td valign="top"><img src="{{base_path().'/public/image/logo.png'}}" alt="" width="150"/></td> -->
        <td align="right">
            <b>Report</b>
            <pre>


              {{ getProviderData.provider }}
              {{ getProviderData.provider_contact }}
              {{ getProviderData.provider_email }}
            </pre>
        </td>
        
    </tr>

  </table>




     <table width="100%" v-else-if="formData.view_type == 'REPORT' && app_portal == 'Gerrd'">
    <tr>
        <!-- <td valign="top"><img src="{{base_path().'/public/image/logo.png'}}" alt="" width="150"/></td> -->
        <td align="right">
            <b>Report</b>
            <pre>


              Emedi Pay
              <!-- {{ getProviderData.provider_contact }}
              {{ getProviderData.provider_email }} -->
            </pre>
        </td>
        
    </tr>

  </table>

<br/>
   <div class="rep_heading" v-if="formData.report_cate == 'SupportCreditors'"><h5 style="text-align:center">Providers That Support(s) Is Owing</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'DependentsVisit'"><h5 style="text-align:center">Providers That Dependents Have Visited</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'SupportsDependentList'"><h5 style="text-align:center">List Of Dependent</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id == 'ALL'"><h5 style="text-align:center">List Of Dependent</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id != 'ALL'"><h5 style="text-align:center">List Of Support Under Dependent</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id == 'ALL'"><h5 style="text-align:center">List Of Dependent Debtors</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id != 'ALL'"><h5 style="text-align:center">List Of Support Under Dependent Debtors</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id == 'ALL'"><h5 style="text-align:center">List Of Dependent Debtors Between Date</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id != 'ALL'"><h5 style="text-align:center">List Of Support Under Dependent Debtors Between Date</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersPaymentsReceived'"><h5 style="text-align:center">Payments Received</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate'"><h5 style="text-align:center">Payments Received Between Dates</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ListOfSupport' && formData.support_id == 'ALL'"><h5 style="text-align:center">List Of Supports</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ListOfSupport' && formData.support_id != 'ALL'"><h5 style="text-align:center">List Of Dependents Under Suppport</h5><hr/></div>
      <div class="rep_heading" v-else-if="formData.report_cate == 'ListOfDependent' && formData.support_id == 'ALL'"><h5 style="text-align:center">List Of Dependents</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'ListOfDependent' && formData.support_id != 'ALL'"><h5 style="text-align:center">List Of Supports Under Dependent</h5><hr/></div>
      <div class="rep_heading" v-else-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id == 'ALL'"><h5 style="text-align:center">List Of Dependent Debtors</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id != 'ALL'"><h5 style="text-align:center">List Of Support Under Dependent Debtors</h5><hr/></div>
      <div class="rep_heading" v-else-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id == 'ALL'"><h5 style="text-align:center">List Of Dependent Debtors Between Date</h5><hr/></div>
   <div class="rep_heading" v-else-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id != 'ALL'"><h5 style="text-align:center">List Of Support Under Dependent Debtors Between Date</h5><hr/></div>
      <div class="rep_heading" v-else-if="formData.report_cate == 'PaymentsReceived'"><h5 style="text-align:center">Payments Received</h5><hr/></div>
         <div class="rep_heading" v-else-if="formData.report_cate == 'PaymentsReceivedBetweenDate'"><h5 style="text-align:center">Payments Received Between Dates</h5><hr/></div>



  <br/>

    <table width="100%" v-if="formData.report_cate == 'SupportCreditors'">
    <tr>
      <td><strong>Total Providers Support Is Owing:</strong> {{ report_converts.length}}</td>
      <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>




      <table width="100%" v-else-if="formData.report_cate == 'DependentsVisit'">
    <tr>
      <td><strong>Total Number Of Providers Visited:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td ><strong>All Dependent:</strong> {{ formData.dependent_id=='ALL' ? 'YES' : 'NO' }}</td>
      <!-- <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td> -->
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>





  <table width="100%" v-else-if="formData.report_cate == 'SupportsDependentList'">
    <tr>
      <td><strong>Total Number Of Dependents:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td ><strong>Status:</strong> {{formData.status}}</td>
      <!-- <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td> -->
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>






  <table width="100%" v-else-if="formData.report_cate == 'SupportPayment'">
    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td ><strong>Total Amount Paid:</strong> {{total_bal}}</td>
      <!-- <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td> -->
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>





    <table width="100%" v-else-if="formData.report_cate == 'SupportPaymentBetweenDate'">
       <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : '' }}</td>
      <td ><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : '' }}</td>
      <!-- <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td> -->
  </tr>
    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td ><strong>Total Amount Paid:</strong> {{total_bal}}</td>
      <!-- <td><strong>Total Amount Owing:</strong> {{ total_bal }}</td> -->
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>






     <table width="100%" v-else-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>







     <table width="100%" v-else-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of Support Under Selected Dependent:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>






   <table width="100%" v-else-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>


  <tr>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>








     <table width="100%" v-else-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>


  <tr>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>










     <table width="100%" v-else-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>








       <table width="100%" v-else-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
        <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>







       <table width="100%" v-else-if="formData.report_cate == 'ProvidersPaymentsReceived'">

    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Total Amount Received:</strong> {{ total_bal ? total_bal.t_amount_paid : ""  }}</td>
  </tr>


  <tr>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>







         <table width="100%" v-else-if="formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate'">

    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Total Amount Received:</strong> {{ total_bal ? total_bal.t_amount_paid : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>








       <table width="100%" v-else-if="formData.report_cate == 'ListOfSupport' && formData.support_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of Supports:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Support:</strong> ALL</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>







     <table width="100%" v-else-if="formData.report_cate == 'ListOfSupport' && formData.support_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of Dependents Under Support:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Support:</strong> {{ this.getSingleSupportInfo ? this.getSingleSupportInfo : "" }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>







         <table width="100%" v-else-if="formData.report_cate == 'ListOfDependent' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of Dependents:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> ALL</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>






    <table width="100%" v-else-if="formData.report_cate == 'ListOfDependent' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of Supports Under Dependent:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>













  <table width="100%" v-else-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>


  <tr>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>








     <table width="100%" v-else-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
  </tr>


  <tr>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>













 <table width="100%" v-else-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id == 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
      <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>








       <table width="100%" v-else-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id != 'ALL'">

    <tr>
      <td><strong>Total Number Of  Dependent Owing:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Dependent:</strong> {{ this.getSingleDependentInfo ? this.getSingleDependentInfo : "" }}</td>
        <td><strong>Total Amount Owe:</strong> {{ total_bal ? total_bal.total_bal : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>











      <table width="100%" v-else-if="formData.report_cate == 'PaymentsReceived'">

    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Total Amount Received:</strong> {{ total_bal ? total_bal.t_amount_paid : ""  }}</td>
  </tr>


  <tr>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>















           <table width="100%" v-else-if="formData.report_cate == 'PaymentsReceivedBetweenDate'">

    <tr>
      <td><strong>Total Number Of Payments:</strong> {{ report_converts ? report_converts.length : 0 }}</td>
      <td><strong>Total Amount Received:</strong> {{ total_bal ? total_bal.t_amount_paid : ""  }}</td>
  </tr>


  <tr>
      <td><strong>From Date:</strong> {{ formData.from_date ? formData.from_date : ""  }}</td>
      <td><strong>To Date:</strong> {{ formData.to_date ? formData.to_date : ""  }}</td>
  </tr>
    <!-- <tr>
        <td><strong>From:</strong> </td>
        <td><strong>To:</strong> </td>
    </tr>
    <tr>
      <td><strong>Total Cost:</strong> </td>
      <td></td>
  </tr> -->

  </table>





  





  <br/>

 


    <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'SupportCreditors'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Providers</th>
        <th>Contact</th>
        <th>Emedi Total Bal</th>
        <th>Providers Total Bal</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.provider}}</td>
        <td>{{cov.provider_contact}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>




      <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'DependentsVisit'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Providers</th>
        <th>Contact</th>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.provider}}</td>
        <td>{{cov.provider_contact}}</td>
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
      </tr>

      
    </tbody>

 
  </table>











        <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'SupportsDependentList'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
      </tr>

      
    </tbody>

 
  </table>







    <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'SupportPayment'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Bill Amount</th>
        <th>Amount Paid</th>
        <th>Transaction Date</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.bill_amount}}</td>
        <td>{{cov.amount_paid}}</td>
        <td>{{cov.trans_date}}</td>
      </tr>

      
    </tbody>

 
  </table>







      <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'SupportPaymentBetweenDate'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Bill Amount</th>
        <th>Amount Paid</th>
        <th>Transaction Date</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.bill_amount}}</td>
        <td>{{cov.amount_paid}}</td>
        <td>{{cov.trans_date}}</td>
      </tr>

      
    </tbody>

 
  </table>






        <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
      </tr>

      
    </tbody>

 
  </table>









    <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDependentsList' && formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Support Gender</th>
        <th>Support Country</th>
        <th>Support City</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.gender}}</td>
        <td>{{cov.country}}</td>
        <td>{{cov.city}}</td>
      </tr>

      
    </tbody>

 
  </table>








          <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>








           <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDebtors' && formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Support Country</th>
        <th>Support City</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.country}}</td>
        <td>{{cov.city}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>









            <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>








             <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersDebtorsBetweenDate' && formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Support Country</th>
        <th>Support City</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.country}}</td>
        <td>{{cov.city}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>











              <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersPaymentsReceived'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Receipt Number</th>
        <th>Amount Received</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.depp_fullname}}</td>
        <td>{{cov.depp_contact}}</td>
        <td>{{cov.supp_fullname}}</td>
        <td>{{cov.supp_contact}}</td>
        <td>{{cov.receipt_num}}</td>
        <td>{{cov.prov_amount_paid}}</td>
      </tr>

      
    </tbody>

 
  </table>










                <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ProvidersPaymentsReceivedBetweenDate'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Receipt Number</th>
        <th>Amount Received</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.depp_fullname}}</td>
        <td>{{cov.depp_contact}}</td>
        <td>{{cov.supp_fullname}}</td>
        <td>{{cov.supp_contact}}</td>
        <td>{{cov.receipt_num}}</td>
        <td>{{cov.prov_amount_paid}}</td>
      </tr>

      
    </tbody>

 
  </table>













             <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ListOfSupport' && this.formData.support_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.status}}</td>
      </tr>

      
    </tbody>

 
  </table>










               <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ListOfSupport' && this.formData.support_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.status}}</td>
      </tr>

      
    </tbody>

 
  </table>














      <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ListOfDependent' && this.formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Is Active</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.is_active}}</td>
      </tr>

      
    </tbody>

 
  </table>












        <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'ListOfDependent' && this.formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.status}}</td>
      </tr>

      
    </tbody>

 
  </table>













   <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>








           <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'GerrdsDebtors' && formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Support Country</th>
        <th>Support City</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.country}}</td>
        <td>{{cov.city}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>













       <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id == 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Dependent Email</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>








             <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'GerrdsDebtorsBetweenDate' && formData.dependent_id != 'ALL'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Support Email</th>
        <th>Support Country</th>
        <th>Support City</th>
        <th>Provider - Total Amount Owe</th>
        <th>Emedi - Total Amount Owe</th>
        <th>Total Amount Owe</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.fullname}}</td>
        <td>{{cov.contact}}</td>
        <td>{{cov.email}}</td>
        <td>{{cov.country}}</td>
        <td>{{cov.city}}</td>
        <td>{{cov.prov_total_bal}}</td>
        <td>{{cov.emedi_total_bal}}</td>
        <td>{{cov.total_bal}}</td>
      </tr>

      
    </tbody>

 
  </table>














     <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'PaymentsReceived'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Receipt Number</th>
        <th>Emedi Amount Received</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.depp_fullname}}</td>
        <td>{{cov.depp_contact}}</td>
        <td>{{cov.supp_fullname}}</td>
        <td>{{cov.supp_contact}}</td>
        <td>{{cov.receipt_num}}</td>
        <td>{{cov.emedi_amount_paid}}</td>
      </tr>

      
    </tbody>

 
  </table>













   <table width="100%" class="table table-hover table-striped" v-if="formData.report_cate == 'PaymentsReceivedBetweenDate'">
    <thead style="background-color: lightgray;">
      <tr>
        <th>Dependent Fullname</th>
        <th>Dependent Contact</th>
        <th>Support Fullname</th>
        <th>Support Contact</th>
        <th>Receipt Number</th>
        <th>Amount Received</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="(cov) in report_converts" :key="cov.id">
        <td>{{cov.depp_fullname}}</td>
        <td>{{cov.depp_contact}}</td>
        <td>{{cov.supp_fullname}}</td>
        <td>{{cov.supp_contact}}</td>
        <td>{{cov.receipt_num}}</td>
        <td>{{cov.emedi_amount_paid}}</td>
      </tr>

      
    </tbody>

 
  </table>







  <div class="rfooter">2022 © eMedipay Admin. - <a href="emedipay.com">emedipay.com</a></div>

</div>

</vue-easy-print>


                            </div>



                         




                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
// import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';
import vueEasyPrint from "vue-easy-print";


export default {
  name: "ReportView",
    beforeRouteEnter(to,from,next){
      let user_level =  null;
const user_data = JSON.parse(localStorage.getItem("users"));
  user_level = user_data.user_level;


  
    
    if(user_level=='ADMIN' ||  user_level=='ACCOUNTANT'  || user_level=='GLOBAL' || user_level=='COUNTRY' || user_level=='LOCAL' ||  user_level=='SUPPORT'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    // TableFilter,
    ActionButton,
    SelectAllComponent,
    vSelect,
    VueTailwindPagination,
    vueEasyPrint 
  },



   beforeMount(){
     this.showLoading(true);
   },


      mounted(){
     this.showLoading(false);
   },


  data(){
    return {

      grid_converts:[],
      report_converts:{},
      grid_dep_supp: [],
      report_dep_supp:{},
      totalConverts:"",
      view_grid:false,
      view_report:false,
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        app_portal:"",
        user_portal_info:{},
        getReportCate:[],
        getSearchBy:[],
        getColumns:[],
        getDependents:[],
        getSupports:[],
        getSingleDependentInfo:"",
        getSingleSupportInfo:"",
        getSupportData :[],
        getProviderData :[],
        getProviders: [],
        getCountries: [],
        total_bal:null,
        errorClient:[],
        errorServers:[],
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        //support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        login_user_id:"",
        selectPage:false,
        year:"",

         formData:{
          
            report_cate: "",
            //view: "",
            view_type: "",
            status: "",
            from_date:"",
            to_date:"",
            dependent_id: "",
            support_id:"",
            provider_id:"",
            country_id:"",
            user_level:"",
         },

         report_cateErr:"",
         dependentErr:"",
         supportErr:"",
         providerErr:"",
         //viewErr:"",
         view_typeErr:"",
         statusErr:"",
         from_dateErr:"",
         to_dateErr:"",
         countryErr:"",
         org_name:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),







    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.searchConvert();

    },











   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.searchConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },







    perpageval(value){
        this.table_per_page(value);
       this.searchConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.searchConvert();

    },

    statusval(value){

        this.table_status(value);
        this.searchConvert();

    },

 








      





      async get_dependent_by_support_id(){
           try{
       let response = await axios.get(`getDependentBySupportId?support_id=${this.formData.support_id}`);
        console.log(response);
        this.getDependents =  JSON.parse(JSON.stringify(response.data.data));
            this.getDependents.unshift({"id":"ALL", "full_name":"ALL"});
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},








    async get_supports(){
           try{
          
       let response = await axios.get(`getSupportData?country_id=${this.formData.country_id}&&user_level=${this.formData.user_level}`);
       // console.log(response);
        this.getSupports =  JSON.parse(JSON.stringify(response.data.data));
            this.getSupports.unshift({"id":"ALL", "full_name":"ALL"});
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},








    async get_dependents(){
           try{
          
       let response = await axios.get(`getDependentData?country_id=${this.formData.country_id}&&user_level=${this.formData.user_level}`);
       // console.log(response);
        this.getDependents =  JSON.parse(JSON.stringify(response.data.data));
            this.getDependents.unshift({"id":"ALL", "full_name":"ALL"});
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},







    



  async get_dependent_by_prov(){
           try{
       let response = await axios.get(`getDependentByProvider?provider_id=${this.formData.provider_id}`);
        console.log(response);
        this.getDependents =  JSON.parse(JSON.stringify(response.data.data));
            this.getDependents.unshift({"id":"ALL", "full_name":"ALL"});
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},








   async getLocalProviders(){
           try{
       let response = await axios.get(`getLocalProvider?user_id=${this.login_user_id}`);
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},







   async getCountriesRe(){
           try{
       let response = await axios.get(`getCountriesRe?user_id=${this.login_user_id}&&user_level=${this.formData.user_level}`);
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
       // console.log(this.getCountries);
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},







  async get_single_dependent(){
    if(this.getSingleDependentInfo != "ALL")
    {
           try{
       let response = await axios.get(`getSingleDependent?dependent_id=${this.formData.dependent_id}`);
        console.log(response);
        this.getSingleDependentInfo =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
    }
    else{
      this.getSingleDependentInfo = "";
    }
},














  async get_single_support(){
    if(this.getSingleSupportInfo != "ALL")
    {
           try{
       let response = await axios.get(`getSingleSupport?support_id=${this.formData.support_id}`);
        console.log(response);
        this.getSingleSupportInfo =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
    }
    else{
      this.getSingleSupportInfo = "";
    }
},











      report_cateVal(value){

             let field = "Report Cate";

                   if(typeof value == "object" && value!=null)
             {
              value = value.report_cate;
             }

      if(this.formData.report_cate != "ALL" && FieldValidateService.reqVal2(value,field)){
        this.report_cateErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.report_cateErr);
      }else{
        if(this.errorClient.includes(this.report_cateErr))
        {
        let pos = this.errorClient.indexOf(this.report_cateErr);
        this.errorClient.splice(pos,1);
        }
        this.report_cateErr ="";
       // this.get_church_id(); 
       this.checkCountrySupportDependent();
      }

    },









          dependentVal(value){

             let field = "Dependent";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if((this.formData.report_cate=='SupportPayment' || this.formData.report_cate=='SupportPaymentBetweenDate' || this.formData.report_cate=='DependentsVisit' || this.formData.report_cate=='ProvidersDependentsList') && FieldValidateService.reqVal2(value,field)){
        this.dependentErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.dependentErr);
      }else{
        if(this.errorClient.includes(this.dependentErr))
        {
        let pos = this.errorClient.indexOf(this.dependentErr);
        this.errorClient.splice(pos,1);
        }
        this.dependentErr ="";
                if(this.formData.dependent_id != 'ALL')
        {
        this.get_single_dependent();
        }
       // this.get_church_id(); 
      }

    },















           supportVal(value){

             let field = "Support";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if((this.formData.report_cate=='ListOfSupport') && FieldValidateService.reqVal2(value,field)){
        this.supportErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.supportErr);
      }else{
        if(this.errorClient.includes(this.supportErr))
        {
        let pos = this.errorClient.indexOf(this.supportErr);
        this.errorClient.splice(pos,1);
        }
        this.supportErr ="";
        if(this.formData.support_id != 'ALL')
        {
        this.get_single_support();
        }
       // this.get_church_id(); 
      }

    },





    providerVal(value){


                  let field = "Provider";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if((this.formData.user_level=='LOCAL') && FieldValidateService.reqVal2(value,field)){
        this.providerErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.providerErr);
      }else{
        if(this.errorClient.includes(this.providerErr))
        {
        let pos = this.errorClient.indexOf(this.providerErr);
        this.errorClient.splice(pos,1);
        }
        this.providerErr ="";
          this.get_dependent_by_prov();
        this.getProv(this.formData.provider_id);
      }



    },






    countryVal(value){


                  let field = "Country";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if((this.formData.user_level=='COUNTRY') && FieldValidateService.reqVal2(value,field)){
        this.countryErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.countryErr);
      }else{
        if(this.errorClient.includes(this.countryErr))
        {
        let pos = this.errorClient.indexOf(this.countryErr);
        this.errorClient.splice(pos,1);
        }
        this.countryErr ="";
        this.checkCountrySupportDependent();
    
        //   this.get_dependent_by_prov();
        // this.getProv(this.formData.provider_id);
      }



    },







    // viewVal(value){

    //          let field = "View";

    //                if(typeof value == "object" && value!=null)
    //          {
    //          value = value.id;
    //          }

    //   if((this.formData.report_cate=='ProvidersDependentsList' || this.formData.report_cate=='ListOfSupport' ||  this.formData.report_cate=='ListOfDependent' ||  this.formData.report_cate=='DependentsOwing') && FieldValidateService.reqVal2(value,field)){
    //     this.viewErr = FieldValidateService.reqVal2(value,field);
    //     this.errorClient.push(this.viewErr);
    //   }else{
    //     if(this.errorClient.includes(this.viewErr))
    //     {
    //     let pos = this.errorClient.indexOf(this.viewErr);
    //     this.errorClient.splice(pos,1);
    //     }
    //     this.viewErr ="";
    //    // this.get_church_id(); 
    //   }

    // },








   



    view_typeVal(value) {

      let max = 10;
      let field = "Value Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_typeErr);
     }else{

           if(this.errorClient.includes(this.view_typeErr))
           {
            let pos = this.errorClient.indexOf(this.view_typeErr);
           this.errorClient.splice(pos,1);
            this.view_typeErr =""; 
           }
          
          

     }
    },




        statusVal(value) {

      let max = 10;
      let field = "Status";
     
     if(this.formData.report_cate === 'SupportsDependentList' && FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.statusErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.statusErr);
     }else{

           if(this.errorClient.includes(this.statusErr))
           {
            let pos = this.errorClient.indexOf(this.statusErr);
           this.errorClient.splice(pos,1);
            this.statusErr =""; 
           }
          
          

     }
    },













          to_dateVal(value) {

      //let max = 10;
      let field = "To Date";
     
     if((this.formData.report_cate === 'SupportPayment' || this.formData.report_cate === 'SupportPaymentBetweenDate') && FieldValidateService.reqVal(value,field))
     {
       this.to_dateErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.to_dateErr);
     }else{

           if(this.errorClient.includes(this.to_dateErr))
           {
            let pos = this.errorClient.indexOf(this.to_dateErr);
           this.errorClient.splice(pos,1);
            this.to_dateErr =""; 
           }
          
          

     }
    },








              from_dateVal(value) {

      //let max = 10;
      let field = "From Date";
     
     if(this.formData.report_cate === 'SupportPaymentBetweenDate' && FieldValidateService.reqVal(value,field))
     {
       this.from_dateErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.from_dateErr);
     }else{

           if(this.errorClient.includes(this.from_dateErr))
           {
            let pos = this.errorClient.indexOf(this.from_dateErr);
           this.errorClient.splice(pos,1);
            this.from_dateErr =""; 
           }
          
          

     }
    },




   checkCountrySupportDependent(){
        if(this.formData.report_cate == 'ListOfSupport')
        {
        this.get_supports();
        }

       else if(this.formData.report_cate == 'ListOfDependent' ||  this.formData.report_cate == 'GerrdsDebtors' ||  this.formData.report_cate == 'GerrdsDebtorsBetweenDate')
        {
        this.get_dependents();
        }
   },











    







    printDiv(){

          this.$refs.easyPrint.print()

    },




    // async getTotalConver(){
    //     try{
    //    let response = await axios.get(`getTotalConverts?district_id=${this.formData.district_id}&&church_id=${this.formData.church_id}`);
    //     this.totalConverts =  JSON.parse(JSON.stringify(response.data.data));
    //    // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
    //    this.formData.district_id
       
      
    // }catch(_){
    //     console.log(_);
    // }
    // },


    async searchConvert(){

       this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.report_cateVal(this.formData.report_cate);
        this.dependentVal(this.formData.dependent_id);
        this.supportVal(this.formData.support_id);
        //this.viewVal(this.formData.view);
        this.view_typeVal(this.formData.view_type);
        this.statusVal(this.formData.status);
        this.to_dateVal(this.formData.to_date);
        this.from_dateVal(this.formData.from_date);
      console.log(this.formData.dependent_id);

          if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

       else{

   


                 try{

            console.log(this.formData)



            let response = await axios.post("report",this.formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                  console.log(response);


                   //this.getTotalConver();
                     //this.clearFormData();
        // notificationBox.succNotifications("Convert Added Successfully");
        // this.$emit("convertAdded");
                 this.grid_converts = [];
                   this.report_converts = [];
           

               if(this.formData.view_type == "GRID")
               {
               
              this.view_grid = true;
              this.view_report = false;
               if(response.data.data !="")
              {
               this.grid_converts = response.data.data;
               this.total_bal = response.data.total_bal;


                   this.table_per_page(response.data.data.per_page);
                   this.table_total(response.data.data.total)
              }

               
              
               }
               else{
                    
              this.view_grid = false;
              this.view_report = true;
              if(response.data.data !="")
              {
               this.report_converts = response.data.data;
               this.total_bal = response.data.total_bal;


              }
             
               }
              }


         }catch(error){

                  this.showLoading(false);
                 console.log(error);
      //      this.errorServers.push(error.response.data.error);
           
      //      this.showLoading(false);
           

      //             if(this.errorServers.length > 0)
      //  {
      //     notificationBox.errorNotifications("Some Errors Exist");
      //       this.getServerError();
     
      //  }
      //  else if(error.response.data.data_error){
        
      //      notificationBox.errorNotifications(error.response.data.data_error);
      //  }

         }

       }


    },





            async getSuppMem(support_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`supports/${support_id}`);
            console.log(response);
            this.showLoading(false);
                this.getSupportData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },






              async getProv(provider_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`providers/${provider_id}`);
            console.log(response);
            this.showLoading(false);
                this.getProviderData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },






  
  },





 async created(){


    //  try{
    //    let response = await axios.get(`getTotalConverts?app_portal=${this.app_portal}`);
    //     this.totalConverts =  JSON.parse(JSON.stringify(response.data.data));
    //    // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
       
       
      
    // }catch(_){
    //     console.log(_);
    // }


   const user_data = JSON.parse(localStorage.getItem("users"));
          this.formData.provider_id = user_data.provider_id;
         // console.log(this.provider_id);
          let user_idd = user_data.id;
          this.login_user_id = user_idd;
          //this.formData.user_level = user_data.user_level;
          this.formData.user_level = user_data.user_level;
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    //console.log(this.app_portal);



      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
 
        this.formData.support_id =  JSON.parse(JSON.stringify(response.data.data));



          let responsee = await axios.post("getDependentId",user_dataa);
 
        this.dependent_id =  JSON.parse(JSON.stringify(responsee.data.data));

        // if(this.user_level == "LOCAL")
        // {
        //    this.getLocalProviders();
        // }
        // if(this.user_level == "GLOBAL" || this.user_level == "COUNTRY")
        // {
        //    this.get_supports();
        // }


    //   if(this.app_portal == "Support")
    //   {
    // this.get_dependent_by_support_id();
    // this.getSuppMem(this.formData.support_id);
    //   }
    //   else if(this.app_portal == "Provider")
    //   {
    //     this.get_dependent_by_prov();
    //     this.getProv(this.formData.provider_id);
    //   }



    






       
      
// this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
// this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.table_current_page(1);



 if(this.formData.user_level == "SUPPORT")
 {
      this.get_dependent_by_support_id();
    this.getSuppMem(this.formData.support_id);
      this.getReportCate = [
        {
            //Support

                "report_cate":"SupportCreditors",
                "report_cate_by_act_name":"View Providers Support Is Owing",
            },
 
            {
                "report_cate":"DependentsVisit",
                "report_cate_by_act_name":"Providers Dependents Visit",
            },
            {
                "report_cate":"SupportsDependentList",
                "report_cate_by_act_name":"List Of Support Dependents",
            },
            {
                "report_cate":"SupportPayment",
                "report_cate_by_act_name":"Support Payments",
            },
            {
                "report_cate":"SupportPaymentBetweenDate",
                "report_cate_by_act_name":"Support Payment Between Date",
            },



           
    ];

 }


  if(this.formData.user_level == "ADMIN" || this.formData.user_level == "ACCOUNTANT")
{
        this.get_dependent_by_prov();
        this.getProv(this.formData.provider_id);
 this.getReportCate = [
              //Providers Portal
               {
                "report_cate":"ProvidersDependentsList",
                "report_cate_by_act_name":"Provider's List Of Dependents",
            },

            {
                "report_cate":"ProvidersDebtors",
                "report_cate_by_act_name":"Provider's Debtors",
            },
            {
                "report_cate":"ProvidersDebtorsBetweenDate",
                "report_cate_by_act_name":"Provider's Debtors Between Date",
            },
            {
                "report_cate":"ProvidersPaymentsReceived",
                "report_cate_by_act_name":"Provider's Payment Received",
            },

            {
                "report_cate":"ProvidersPaymentsReceivedBetweenDate",
                "report_cate_by_act_name":"Provider's Payment Received Between Date",
            },
 ];
}
else if(this.formData.user_level == "LOCAL")
{
  this.getLocalProviders();
  this.getReportCate = [
              //Providers Portal
               {
                "report_cate":"ProvidersDependentsList",
                "report_cate_by_act_name":"Provider's List Of Dependents",
            },

            {
                "report_cate":"ProvidersDebtors",
                "report_cate_by_act_name":"Provider's Debtors",
            },
            {
                "report_cate":"ProvidersDebtorsBetweenDate",
                "report_cate_by_act_name":"Provider's Debtors Between Date",
            },
            {
                "report_cate":"ProvidersPaymentsReceived",
                "report_cate_by_act_name":"Provider's Payment Received",
            },

            {
                "report_cate":"ProvidersPaymentsReceivedBetweenDate",
                "report_cate_by_act_name":"Provider's Payment Received Between Date",
            },
 ];
}


 



  else if(this.formData.user_level == "GLOBAL" || this.formData.user_level == "COUNTRY")
 {
     this.checkCountrySupportDependent();
     if(this.formData.user_level == "COUNTRY")
     {
     this.getCountriesRe();
     }

     this.getReportCate = [

        

             //Emedi Portal
               {
                "report_cate":"ListOfSupport",
                "report_cate_by_act_name":"List Of Supports",
            },

                    {
                "report_cate":"ListOfDependent",
                "report_cate_by_act_name":"List Of Dependents",
            },

            {
                "report_cate":"GerrdsDebtors",
                "report_cate_by_act_name":"Debtors",
            },
            {
                "report_cate":"GerrdsDebtorsBetweenDate",
                "report_cate_by_act_name":"Debtors Between Date",
            },
            {
                "report_cate":"PaymentsReceived",
                "report_cate_by_act_name":"Payments Received",
            },

            {
                "report_cate":"PaymentsReceivedBetweenDate",
                "report_cate_by_act_name":"Payments Received Between Date",
            },


            
     ]

 }



    this.getSearchBy = [
        {
                "search_by":"ALL",
                "search_by_act_name":"ALL",
            },
            // {
            //     "search_by":"church_evangelism_location.location",
            //     "search_by_act_name":"EVANGELISM LOCATION",
            // },
            // {
            //     "search_by":"denominations.denomination",
            //     "search_by_act_name":"DENOMINATION",
            // },
            // {
            //     "search_by":"churches.church",
            //     "search_by_act_name":"CHURCH",
            // },
            {
                "search_by":"converts.fullname",
                "search_by_act_name":"FULLNAME",
            },
            {
                "search_by":"converts.age",
                "search_by_act_name":"AGE",
            },
            {
                "search_by":"age>=",
                "search_by_act_name":"AGE>=",
            },
            {
                "search_by":"age<=",
                "search_by_act_name":"AGE<=",
            },
            {
                "search_by":"converts.gender",
                "search_by_act_name":"GENDER",
            },
            {
                "search_by":"converts.location",
                "search_by_act_name":"LOCATION",
            },
            {
                "search_by":"converts.residence_phone_number",
                "search_by_act_name":"RESIDENCE PHONE NUMBER",
            },
            {
                "search_by":"converts.office_phone_number",
                "search_by_act_name":"OFFICE PHONE NUMBER",
            },
            {
                "search_by":"converts.first_time_decision_for_christ",
                "search_by_act_name":"FIRST TIME DECISION FOR CHRIST",
            },
            {
                "search_by":"converts.best_time_to_visit",
                "search_by_act_name":"BEST TIME TO VISIT",
            },
            {
                "search_by":"converts.convert_needs",
                "search_by_act_name":"CONVERT NEEDS",
            },
    ];

   // console.log(JSON.parse(JSON.stringify(this.getSearchBy)));

    this.getColumns = [{
                "column":"evangelism_type",
                "column_view":"EVANGELISM TYPE",
                "column_field":"evangelism_types.evangelism_type",
            },
            {
                "column":"district",
                "column_view":"DISTRICT",
                "column_field":"districts.district",
            },
            {
                "column":"church",
                "column_view":"CHURCH",
                 "column_field":"churches.church",
            },
            
            {
                "column":"fullname",
                "column_view":"FULLNAME",
                "column_field":"converts.fullname",
            },
            {
                "column":"age",
                "column_view":"AGE",
                "column_field":"converts.age",
            },
            {
                "column":"gender",
                "column_view":"GENDER",
                "column_field":"converts.gender",
            },
            {
                "column":"location",
                "column_view":"LOCATION",
                "column_field":"converts.location",
            },
            {
                "column":"residence_phone_number",
                "column_view":"RESIDENCE PHONE NUMBER",
                "column_field":"converts.residence_phone_number",
            },
            {
                "column":"office_phone_number",
                "column_view":"OFFICE PHONE NUMBER",
                "column_field":"converts.office_phone_number",
            },
            {
                "column":"first_time_decision_for_christ",
                "column_view":"FIRST TIME DECISION FOR CHRIST",
                "column_field":"converts.first_time_decision_for_christ",
            },
            {
                "column":"best_time_to_visit",
                "column_view":"BEST TIME TO VISIT",
                "column_field":"converts.best_time_to_visit",
            },
            {
                "column":"convert_needs",
                "column_view":"CONVERT NEEDS",
                "column_field":"converts.convert_needs",
            }];


             if(this.app_portal == "CHURCH"){
     this.getSearchBy.push({"search_by":"church_evangelism_locations.location", "search_by_act_name":"EVANGELISM LOCATION"});

 }
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const evangelism_types = JSON.parse(JSON.stringify(this.evangelism_types));
            evangelism_types.forEach(evangelism_type=>{
                this.checked.push(evangelism_type.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "evangelism_types/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>


<style scoped>

   * {
        font-family: Verdana, Arial, sans-serif;
    }
    table{
        font-size: 12px;
    }
    tfoot tr td{
        font-weight: bold;
        font-size: 12px;
    }
    .gray {
        background-color: lightgray
    }



.rfooter {
   position: fixed;
   bottom: 0;
   width: 100%;
   height: 40px;   /* Height of the footer */
   background: #fff;
}


hr {
    display: block;
    height: 1px;
    width: 100%;
    border: none;
    border-top: solid 1px #000;
    border-style: double;
}

</style>