<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="userModal"    aria-labelledby="userModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel" v-if="this.view_type=='add'">Add User</h5>
                <h5 class="modal-title" id="userModalLabel" v-else-if="this.view_type=='edit'">Edit User</h5>
                <h5 class="modal-title" id="userModalLabel" v-else-if="this.view_type=='view'">View User</h5>
                <button type="button" ref="Usermodal" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#userModal" aria-label="Close"></button>
            </div>
              <div v-if="this.view_type=='add' || this.view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1>User Info <!---  {{formData.link}} --></h1><br/>
                      <p></p>
        
                  <span>
                  
                    <div class="row">
                      <div class="col">
                        <label>Fullname</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: fullnameErr  }"
                           v-if="fullnameErr">
                          {{ fullnameErr }} 
                          </div>
                      </div>






                          <div class="col">
                          <label><span class="text_error">*</span>Contact</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <vue-tel-input v-model.lazy="formData.contact" @blur="contactVal" ></vue-tel-input>
                          
                           

                        </div>
                        <div
                            :class="{ text_error: contactErr }"
                            v-if="contactErr"
                          >
                            {{ contactErr }}
                          </div><br/>

                           <div v-if="contactLoading && !user_idd">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_contact_info && user_idd && view_type=='add'" class="text-success">{{search_contact_info}}</span>
                        <span v-if="search_contact_fail && !user_idd && view_type=='add'" class="text_error : search_contact_fail">{{ search_contact_fail }}</span>

                      </div>





                        <div class="col">
                        <label>Email</label>
                        <div class="input-group">
                          
                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: emailErr}"
                            placeholder="Email"
                            @blur="emailVal($event.target.value)"
                            v-model.trim.lazy="formData.email"
                          />
                         
                            
                        </div>
                         <div
                            :class="{ text_error: emailErr}"
                            v-if="emailErr"
                          >
                            {{ emailErr }} 
                          </div><br/>

                             <div v-if="emailLoading && !user_idd && !search_email_fail">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>

                        <span v-if="search_email_info && user_idd && view_type=='add'" class="text-success">{{search_email_info}}</span>
                        <span v-if="search_email_fail && !user_idd && view_type=='add'" class="text_error : search_email_fail">{{ search_email_fail }}</span>

                      </div>


                    </div>


                          <div class="row" v-if="(!user_idd && view_type=='add') || view_type=='edit'">

                              <div class="col">
                                <label><span class="text_error">*</span>Country</label>
                             <div class="input-group">
                          
                          <!-- <select
                            class="form-control country"
                            :class="{ form_error: countryErr }"
                            ref="count"
                            @change="countryVal($event.target.value)"
                            v-model.trim="formData.country_id">
                            <option value="">--Choose a Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select> -->
                          <!-- <Select2 v-model="formData.country_id" :options="getCountries"  :settings="{ placeholder: 'Specifies the placeholder through settings', width: '50%', ajax: ajax }" @change="countryVal($event.target.value)"  /> -->
                         <v-select :options="getCountries"  class="form-control select1" v-model="formData.country_id"  :reduce="country => country.id" label="country" @option:selected="countryVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: countryErr }"
                            v-if="countryErr"
                          >
                            {{ countryErr }}
                          </div>

                      </div>


                    
                       



                             <div class="col">
                              <label>City/Town</label>
                              <div class="input-group">
                          
                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: cityErr }"
                            placeholder="City"
                            @blur="cityVal($event.target.value)"
                            v-model.trim="formData.city"
                          />
                         
                        </div>
                            <div :class="{ text_error: cityErr }" v-if="cityErr">
                            {{ cityErr }} 
                          </div>

                      </div>




                            <div class="col">
                              <label>Gender</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: genderErr}"
                             @change="genderVal($event.target.value)"
                            v-model.trim="formData.gender">
                            <option value="">--Choose An Option--</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: genderErr}"
                            v-if="genderErr"
                          >
                            {{ genderErr }}
                          </div>
                      </div>



                           






               


                    </div>



                    <div class="row" v-if="this.user_idd && this.view_type== 'add'">


                       <div class="col">
                        <label><span class="text_error">*</span>Please Enter The OTP/Verification Code Sent To Your Mail/Contact</label>
                        <div class="input-group">
                          
                          <input
                            type="text"
                            style="width:900px"
                            :readonly="!user_idd"
                            class="form-control"
                            :class="{ form_error: otpErr }"
                            placeholder="OTP"
                             @blur="otpVal($event.target.value)"
                            v-model.trim="formData.reg_otp"
                          />
                          <!-- <div :class="{ text_error: otpErr }" v-if="otpErr">
                            {{ otpErr }} 
                          </div><br/> -->

                        

                         

                        </div>
                        <div v-if="count_down && !otp_success_message" class="text-success">Count Down: {{count_down}} To Input Otp <button class="btn btn-primary" @click.prevent="verify_otp()">Verify Otp</button></div>
                        <div v-else-if="show_otp_button && !otp_success_message" class="text-success"><button class="btn btn-primary" @click.prevent="resend_otp()">Resend Otp</button></div>
                        
                        <span v-if="otp_success_message" class="text-success">{{otp_success_message}}</span>
                        <span v-else-if="otpErr" class="text_error">{{otpErr}}</span><br/>
                      </div>
              

          
                   


             


                    </div>
                
                  </span>
                




                      <span v-if="app_portal=='Gerdd' || app_portal=='Provider'">
                        <h1>User Info</h1><br/>
                      <p></p>
                    <div class="row">
                            <div class="col" v-if=" (app_portal=='Gerdd'  && view_type=='add') || (app_portal=='Gerdd' && view_type=='edit')">
                        <label>User Level </label>
                        <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: user_levelErr || 'user_level' in errorServers }"
                            @change="user_levelVal($event.target.value)"
                            v-model.trim="formData.user_level">
                            
                            <option value="" selected>--Choose An Option--</option>
                           <option value="GLOBAL">GLOBAL</option>
                            <option value="COUNTRY">COUNTRY</option>
                            <option value="LOCAL">LOCAL</option>
             
                          </select>
                          
                        </div>
                         <div :class="{ text_error: user_levelErr }" v-if="user_levelErr">
                            {{ user_levelErr }} 
                          </div>
                      </div>




                      <div class="col" v-else-if=" ( app_portal=='Provider' && view_type=='add') || (app_portal=='Provider' && view_type=='edit')">
                        <label>User Level </label>
                        <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: user_levelErr || 'user_level' in errorServers }"
                            @change="user_levelVal($event.target.value)"
                            v-model.trim="formData.user_level">
                            
                            <option value="" selected>--Choose An Option--</option>
                           <option value="ADMIN">ADMIN</option>
                            <option value="ACCOUNTANT">ACCOUNTANT</option>
             
                          </select>
                          
                        </div>
                           <div :class="{ text_error: user_levelErr }" v-if="user_levelErr">
                            {{ user_levelErr }} 
                          </div>
                           
                      </div>



                      <div class="col" v-if="formData.user_level=='COUNTRY' && view_type=='add'">
                        <label><span class="text_error">*</span>Country To Be Linked To</label>
                        <div class="input-group">

                          <!-- <label><span class="text_error">*</span>Country</label>
                          <select
                            class="form-control"
                            multiple
                            :class="{ form_error: linkErr }"
                            @change="linkVal($event.target.value)"
                            v-model.trim="formData.link">
                            <option value="">--Choose a Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select> -->


                        

                  <v-select :options="getCountries" multiple class="form-control select1" v-model="formData.link"  :reduce="country => country.id" label="country" @option:selected="linkVal" />



                        </div>
                        
                      </div>







                        <div class="col" v-else-if="formData.user_level=='LOCAL' && view_type=='add'">
                          <label><span class="text_error">*</span>Provider</label>
                        <div class="input-group">

                          
                          <!-- <select
                            class="form-control"
                            multiple
                            :class="{ form_error: linkErr }"
                            @change="linkVal($event.target.value)"
                            v-model.trim="formData.link">
                            <option value="">--Choose a Country--</option>
                            <option v-for="prov in getProviders" :key="prov.id" :value="prov.id">
                              {{ prov.provider }}
                            </option>
                          </select> -->


                          <v-select :options="getProviders" multiple  class="form-control select1" v-model="formData.link"  :reduce="provider => provider.id" label="provider" @option:selected="linkVal" />

                         

                        </div>
                         <div :class="{ text_error: linkErr }" v-if="linkErr">
                            {{ linkErr }} 
                          </div>
                      </div>





                       <div class="col">
                          <label><span class="text_error">*</span>Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: passwordErr }"
                            placeholder="Password"
                             @blur="passwordVal($event.target.value)"
                            v-model.trim="formData.password"
                          />
                              <i class="fa" @click="showPassword = !showPassword"   :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>
                         
                        </div>
                         <div
                            :class="{ text_error: passwordErr}"
                            v-if="passwordErr"
                          >
                            {{ passwordErr }}
                          </div>
                      </div>



                      <div class="col">
                        <label><span class="text_error">*</span>Confirm Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showConfPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: password_confirmationErr  }"
                            placeholder="Confirm Password"
                            @blur="password_confirmationVal($event.target.value)"
                            v-model.trim="formData.password_confirmation"
                          />
                           <i class="fa" @click="showConfPassword = !showConfPassword"   :class="[showConfPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>

                    
                        </div>
                              <div
                            :class="{ text_error: password_confirmationErr }"
                            v-if="password_confirmationErr"
                          >
                            {{ password_confirmationErr }}
                          </div>
                      </div>
                      
                    </div>
                   
                  </span>










                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="!user_idd && view_type=='add'">Submit</button>
                <button type="submit" class="btn btn-primary" v-if="view_type=='edit'">Update</button>
                <button type="submit" class="btn btn-primary" v-else-if="user_idd && view_type=='add'">Link</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="Usermodal" data-bs-dismiss="modal" v-if="view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Fullname</td>
                                <td>{{formData.fullname}}</td>
                                </tr>

                               <tr>
                                <td>Contact</td>
                                <td>{{formData.contact}}</td>
                                </tr>



                                <tr>
                                <td>Email</td>
                                <td>{{formData.email}}</td>
                                </tr>

          

                                <tr>
                                <td>Country</td>
                                <td>{{country_name}}</td>
                                </tr>

                                <!-- <tr>
                                <td>GPS Address</td>
                                <td>{{formData.gps_address}}</td>
                                </tr> -->

                        
                                <tr>
                                <td>User Level</td>
                                <td>{{formData.user_level}}</td>
                                </tr>
                              </tbody>
                                  </table>


                                           <table class="table table-hover table-striped" v-if="formData.user_level == 'COUNTRY'">
                          
                              <thead>
                                <tr>
                                    <th colspan="1">ACCESS PORTALS - {{formData.user_level}}</th>
                                </tr>
                                </thead>

                               
                               
                                <tbody>
                               

                                  <tr v-for="country_portal in employee_user_country_portal" :key="country_portal.id">
                                <td>{{country_portal.country}}</td>
                                <td>

                                  <span v-if="country_portal.is_active=='YES'">ACTIVE</span>
                                  <span v-else>INACTIVE</span>
                                  <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-ellipsis position-relative"></i>
                                </div>
                                <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                    <li class="d-flex">                                                    
                                        <a class="btn" title="edit" @click="userCountryEdit(country_portal.id)">
                                            <i class="fa-solid fa-pencil"></i>
                                        </a>                                                  
                                        <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteUserCountry(country_portal.id)">
                                            <i class="fa-solid fa-trash"></i>
                                        </div>                                                  
                                       
                                    </li>
                                </div>


                                </td>
                                </tr>
                                </tbody>
                                           </table>






                                             <table class="table table-hover table-striped" v-if="formData.user_level == 'LOCAL'">
                          
                              <thead>
                                <tr>
                                    <th colspan="1">ACCESS PORTALS - {{formData.user_level}}</th>
                                </tr>
                                </thead>

                               
                               
                                <tbody>
                             

                                  <tr v-for="provider_portal in employee_user_provider_portal" :key="provider_portal.id">
                                <td>{{provider_portal.provider}}</td>
                                <td>

                                  <span v-if="provider_portal.is_active=='YES'">ACTIVE</span>
                                  <span v-else>INACTIVE</span>
                                  <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-ellipsis position-relative"></i>
                                </div>
                                <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                    <li class="d-flex">                                                    
                                        <div class="btn" title="edit" @click="userProviderEdit(provider_portal.id)" data-bs-toggle="modal" data-bs-target="#userEmployeeProviderModal">
                                            <i class="fa-solid fa-pencil"></i>
                                        </div>                                                  
                                        <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteUserProvider(provider_portal.id)">
                                            <i class="fa-solid fa-trash"></i>
                                        </div>                                                  
                                       
                                    </li>
                                </div>


                                </td>
                                </tr>

                                </tbody>

                                           </table>
                               

                    
                        </div>

                      </div>

                 </div>




            </div>
        </div>


           



        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import vSelect from 'vue-select';
import notificationBox from "@/services/notificationBox";
// import intlTelInput from 'intl-tel-input';
import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';


    export default {

        name:'UserModals',
        props:{
          view_type:{
            required:true,
            type: String
          },


   


          getUserPortalData:{
            required:true,
          }


        },


        components:{
          SpinnerComp,
          vSelect,
        },


           mounted(){



           },

        async created() {
        //  this.showLoading(true);


      //    this.formData.fname = this.firstName;
      
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");

      const user_data = JSON.parse(localStorage.getItem("users"));
          this.formData.provider_id = user_data.provider_id;  
        //  console.log(this.formData.provider_id);
 //this.view_data();

    switch(this.app_portal) {
 case 'Dependent':
    this.formData.user_level = "DEPENDENT";
    break;
  case 'Support':
    this.formData.user_level = "SUPPORT";
    break;
  case 'Telemedicine':
    this.formData.user_level = "TELEMEDICINE";
    break;
  case 'Provider':
    this.formData.user_level = "ADMIN";
    break;
  default:
    this.formData.user_level = "";
}



   try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }





   try{
       let response = await axios.get("getProviders");
      // console.log(response.data.data);
         
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }


  // if(this.app_portal === 'Provider')
  // {
  //       try{
  //      let response = await axios.get("getHealthCares");
  //     // console.log(response.data.data);
         
  //       this.getHealthCares =  JSON.parse(JSON.stringify(response.data.data));
  //       //console.log(this.getCountries);
       
       
       
      
  //   }catch(error){
  //       console.log(error);
  //   }
  // }


  

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      search: "",
      searchErr: "",
      loading:false,
      getCountries:[],
      getProviders:[],
      country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
       contactLoading:false,
      emailLoading:false,
      search_fail:"",
      search_contact_info:"",
      search_email_info:"",
      search_contact_fail:"",
      search_email_fail:"",
      contact_edit_typed:"NO",
      email_edit_typed:"NO",
      otpServerError:"",
      user_idd:"",
      otp_success_message: "",
      count_down:"",
      show_otp_button: false,
      found_data:false,
      has_password:false,
      employee_user_country_portal:[],
      employee_user_provider_portal:[],
      showPassword: false,
      showConfPassword: false,


      modal:"modal",


   
      

      formData: {
        link:[],   //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        user_level:"",
        portal:"",
        is_logged_in:"NO",
        status:"APPROVED",
        otp_code:"",
        user_idy:"",
        user_portal_idy:"",
        action_type:"REG",

        provider_id:"",
        reg_otp:"",
        fullname: "",
        gender: "",
        country_id: null,
        gps_address: "",
        city:  "",
        contact: "",
        email:  "",
        password: "",
        password_confirmation: "",
        portal_id: "",


        view_portal:"",
        
        
      },



    //Errors  
      fullnameErr: "",
      genderErr: "",
      countryErr: "",
      cityErr: "",
      contactErr: "",
      emailErr: "",
      otpErr:"",
      passwordErr: "",
      password_confirmationErr: "",
      user_levelErr:"",
      linkErr:"",








    };
    },


      methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


        async reg(){

    
        
   
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];
      

       this.fullnameVal(this.formData.fullname);
       this.contactVal(this.formData.contact);
       this.emailVal(this.formData.email);
       this.countryVal(this.formData.country_id);
       this.cityVal(this.formData.city);
       this.genderVal(this.formData.gender);
       this.otpVal(this.formData.reg_otp);
       this.user_levelVal(this.formData.user_level);
       this.linkVal(this.formData.link);
       this.passwordVal(this.formData.password);
       this.password_confirmationVal(this.formData.password_confirmation);


       

                 if(this.errorClient.length > 0)
       {
        // clearTimeout(bbb);
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
         // console.log(this.errorClient);
          return true;
       }
       else{

          if(!this.user_idd && this.view_type=="add")
          {

            try{
               
             const response = await axios.post("user_portal_user_prov",this.formData);

            
                  
               this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
               this.showLoading(false);
            if(this.getMessage == 'success')
            {
         this.clearFormData();
        notificationBox.succNotifications("User Added Successfully");
        this.$emit("userAdded");
            }

            }catch(error){

            console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

            }

          }




         else if(this.user_idd && this.view_type=="add"){

                   try{
               
             const response = await axios.post("user_portal_user_exist_prov",this.formData);

            
                  
               this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
               this.showLoading(false);
            if(this.getMessage == 'success')
            {
         this.clearFormData();
        notificationBox.succNotifications("User Added Successfully");
        this.$emit("userAdded");
            }

            }catch(error){


                   console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

             if(this.errorServers.length > 0)
            {
          
            this.getServerError();
           }

            }

         }



         else if(this.view_type=="edit")
         {

       

          try{

            

            const response = await axios.post("edit_user_portal_user_prov",this.formData);
           // const getDataMess = response.data.message;

          //  console.log(response);
              this.showLoading(false);
            if(response.status == 200)
            {
                  this.clearFormData();

                   this.$refs.Usermodal.click();
                  this.$emit("userAdded");
                   notificationBox.succNotifications("User Updated Successfully");
                  
            }

          }catch(error){


              console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

             if(this.errorServers.length > 0)
            {       
            this.getServerError();
           }

          }

         }



       }

        },




        getServerError(){
             if(this.portal == "Provider"){
      this.providerErr = 'provider' in this.errorServers[0] ? this.errorServers[0].provider[0] : '';
      this.provider_countryErr = 'provider_country_id' in this.errorServers[0] ? this.errorServers[0].provider_country_id[0] : '';
      this.provider_gps_addressErr = 'provider_gps_address' in this.errorServers[0] ? this.errorServers[0].provider_gps_address[0] : '';
      this.provider_cityErr = 'provider_city' in this.errorServers[0] ? this.errorServers[0].provider_city[0] : '';
      this.provider_contactErr = 'provider_contact' in this.errorServers[0] ? this.errorServers[0].provider_contact[0] : '';
      this.provider_emailErr = 'provider_email' in this.errorServers[0] ? this.errorServers[0].provider_email[0] : '';
      this.health_careErr = 'health_care_id' in this.errorServers[0] ? this.errorServers[0].health_care_id[0] : '';
     }

       if(this.formData.user_level == "LOCAL" || this.formData.user_level == "COUNTRY"){
        this.linkErr = 'link' in this.errorServers[0] ? this.errorServers[0].link[0] : '';
       }

      this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
      this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
      this.countryErr = 'country_id' in this.errorServers[0] ? this.errorServers[0].country_id[0] : '';
      this.cityErr = 'city' in this.errorServers[0] ? this.errorServers[0].city[0] : '';
      this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
      this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
      this.otpErr = 'reg_otp' in this.errorServers[0] ? this.errorServers[0].reg_otp[0] : '';
      this.passwordErr = 'password' in this.errorServers[0] ? this.errorServers[0].password[0] : '';
      this.password_confirmationErr = 'password_confirmation' in this.errorServers[0] ? this.errorServers[0].password_confirmation[0] : '';
      this.user_levelErr = 'user_level' in this.errorServers[0] ? this.errorServers[0].user_level[0] : '';

     
           return true;
        },




        clearFormData(){

        this.link =[];   //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        this.user_level = "";
       // this.portal = "";
        this.is_logged_in = "NO";
        this.status = "APPROVED";
        this.otp_code = "";
        this.user_idy = "";
        this.user_portal_idy = "";
        this.action_type = "REG";

        // if(this.portal != "Provider")
        // {
        // this.formData.provider_id = "";
        // }
        this.formData.reg_otp = "";
        this.formData.fullname = "";
        this.formData.gender = "";
        this.formData.country_id = null;
        this.formData.gps_address = "";
        this.formData.city =  "";
        this.formData.contact = "";
        this.formData.email =  "";
        this.formData.user_level =  "";
        this.formData.password = "";
        this.formData.password_confirmation = "";



        this.errorClient = [];
      this.errorServers = [];
      this.search_fail = "";
      this.search_contact_info = "";
      this.search_email_info = "";
      this.search_contact_fail = "";
      this.search_email_fail = "";
      this.contact_edit_typed = "NO";
      this.otpServerError = "";
      this.user_idd = "";
      this.otp_success_message = "";
      this.count_down = "";
      this.show_otp_button = false;
      this.found_data = false;
      this.has_password = false;

        //view_portal:"",

        },



        fullnameVal(value){

      let max = 500;
      let field = "Full Name"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{

           this.fullnameErr =""; 
          this.errorClient.pop(this.fullnameErr);

     }


    },





     contactVal(){

            let field = "Contact"

           let value = this.formData.contact;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.contactErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.contactErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.contactErr =""; 

              if(this.view_type =="add")
           {
           this.getUser(value,"contact");
           }
          

     }

    },






     emailVal(value){


                 let field = "Email"

         if(FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.emailErr =""; 
           if(this.view_type =="add")
           {
            this.getUser(value,"email");
           }
         

     }


    },







    countryVal(value){

             let field = "Country";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.countryErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.countryErr);
      }else{
        if(this.errorClient.includes(this.countryErr))
        {
        let pos = this.errorClient.indexOf(this.countryErr);
        this.errorClient.splice(pos,1);
        }
        this.countryErr =""; 
      }

    },

   
    

    cityVal(value){

           let max = 250;
           let field = "City"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.cityErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.cityErr);
     }else{

             if(this.errorClient.includes(this.cityErr))
        {
        let pos = this.errorClient.indexOf(this.cityErr);
        this.errorClient.splice(pos,1);
        }
           this.cityErr =""; 
        

     }


    },





     genderVal(value) {

      let max = 100;
      let field = "Gender";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.genderErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.genderErr);
     }else{

           if(this.errorClient.includes(this.genderErr))
           {
            let pos = this.errorClient.indexOf(this.genderErr);
           this.errorClient.splice(pos,1);
            this.genderErr =""; 
           }
          
          

     }
    },






    otpVal(value){

           let field = "Otp"
          // console.log(this.user_idd)

         if(this.user_idd && this.view_type== "add" && FieldValidateService.reqValNum(value, field))
     {
       this.otpErr = FieldValidateService.reqValNum(value,field);
        this.errorClient.push(this.otpErr);
     }
     else if(!this.otp_success_message){
      this.otpErr = "Wrong Otp Code"
      this.errorClient.push(this.otpErr);
     }
     else{

          if(this.errorClient.includes(this.otpErr))
        {
        let pos = this.errorClient.indexOf(this.otpErr);
        this.errorClient.splice(pos,1);
        }
           this.otpErr =""; 
          

     }

    },






     user_levelVal(value) {

       let field = "User Level";

      if(FieldValidateService.reqVal(value,field)){
        this.user_levelErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.user_levelErr);
      }else{

          if(this.errorClient.includes(this.user_levelErr))
        {
        let pos = this.errorClient.indexOf(this.user_levelErr);
        this.errorClient.splice(pos,1);
        }
        this.user_levelErr =""; 
          
      }
    },











          linkVal(value){

             let field = "Link";

   
                

              if(typeof value == "object" && value!=null)
             {
             value.push(value.id);
             // value = value.id;
           //  this.formData.link.push(value)
             }

                      

                        
           
          //   console.log(this.formData.link)

      if((this.view_type=='add' && !this.user_idd && FieldValidateService.reqVal(value,field))  && (this.formData.user_level == "LOCAL" || this.formData.user_level == "COUNTRY")){
        this.linkErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.linkErr);
      }else{
        this.linkErr =""; 
          this.errorClient.pop(this.linkErr);
      }

    },






      passwordVal(value){

      let field = "Password"

         if(FieldValidateService.reqValPassword(value, field))
     {
       this.passwordErr = FieldValidateService.reqValPassword(value,field);
       this.errorClient.push(this.passwordErr);
     }else{

          if(this.errorClient.includes(this.otpErr))
        {
        let pos = this.errorClient.indexOf(this.otpErr);
        this.errorClient.splice(pos,1);
        }
           this.passwordErr =""; 
          

     }


    },


    password_confirmationVal(value){

           
          

      if(FieldValidateService.valPasswordConfirm(value, this.formData.password))
     {
       this.password_confirmationErr = FieldValidateService.valPasswordConfirm(value,this.formData.password);
        this.errorClient.push(this.password_confirmationErr);
     }else{

            if(this.errorClient.includes(this.password_confirmationErr))
        {
        let pos = this.errorClient.indexOf(this.password_confirmationErr);
        this.errorClient.splice(pos,1);
        }
           this.password_confirmationErr =""; 

     }


   },



   async getUser(value,field){

    if(field == "contact")
    {
      this.contactLoading = true;
      this.emailLoading = false;
    }
    else if(field == "email"){
        this.contactLoading = false;
      this.emailLoading = true;
    }

     const data = {
      search: value,
      portal: this.formData.portal,
      provider: this.formData.provider_id

     };


      try{
         

       let response = await axios.post('getUserPerPortal',data);
       const getMessage = JSON.parse(JSON.stringify(response.data.success_data));
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
     this.dependentLoading = false;
        const getUser =  JSON.parse(JSON.stringify(response.data.data));
        //this.dependent =  getCartData.fullname+" - "+getCartData.email+" - "+getCartData.contact;
      
        if(getMessage != "Not Found")
        {
            this.user_idd = getUser.id;
            this.formData.user_idy = getUser.id;
        this.formData.fullname = getUser.fullname;
        this.formData.gender = getUser.gender;
        this.formData.country_id = getUser.country_id;
        this.formData.gps_address = getUser.gps_address;
        this.formData.city = getUser.city;
        this.formData.contact = getUser.contact;
        this.formData.email = getUser.email;  
        this.formData.password = getUser.password;      
        this.formData.password_confirmation = getUser.password; 
       // this.execOtpCountDown();
 
        this.found_data = true;   
        
        this.execOtpCountDown();

        }  
       else{
       
        this.user_idd = "";
         if(this.found_data == true)
         {

        this.formData.fullname = "";
        this.formData.gender = "";
        this.formData.country_id = "";
        this.formData.gps_address = "";
        this.formData.city = "";
        if(field == "contact")
        {
       this.formData.email = ""; 
        }
         else if(field == "email")
         {
          this.formData.contact = ""; 
         }
        this.formData.password = getUser.password;      
        this.formData.password_confirmation = getUser.password; 

        this.found_data == false;

         }
       }

        if(field === "contact"){
                this.contactLoading = false;
                this.emailLoading = false;
          this.search_contact_info = getMessage;
          this.search_email_info = "";
           this.search_contact_fail = "";
        }
           if(field === "email"){
                    this.contactLoading = false;
                    this.emailLoading = false;
          
          this.search_email_info = getMessage;
          this.search_contact_info = "";
            this.search_email_fail = ""
        }
        
      
     
       
      
    }catch(error)
    {

      console.log(error);
      if(field === "contact"){
            
        this.search_contact_fail = JSON.parse(JSON.stringify(error.response.data.error));

      }
      else if(field === "email"){
        this.search_email_fail = JSON.parse(JSON.stringify(error.response.data.error));
      
      }

      // this.searchDependentErr = JSON.parse(JSON.stringify(error.response.data.error));
      // error.response.data.error;
    }



   },



  //  resend_otp(){
  //     this.execOtpCountDown();
  //  },


    async resend_otp(){

    try{

      const otpData = {
        user_id : this.user_idd
      }

      const response = await axios.post("resend_otp",otpData);
      console.log(response);
      this.execOtpCountDown();

    }catch(error){
   console.log(error);
    }


   },



  async send_empty_otp(){


    try{

      const otpData = {
        user_id : this.user_idd
      }

      const response = await axios.post("send_empty_otp",otpData);
      console.log(response);

    }catch(error){
   console.log(error);
    }




  },





      execOtpCountDown(){
          

          this.count_down = 60;
        let refreshId =    setInterval(() => {
  
     this.count_down--;
     if(this.count_down <= 0)
     {
        this.count_down = "" ;  
        this.show_otp_button = true;  
         this.send_empty_otp();
         clearInterval(refreshId);
        
     }
   }, 1000);




      
   },




    

   async verify_otp(){
    
    try{

      const otpData = {
        user_id: this.user_idd,
        otp: this.formData.reg_otp
      }

      const response = await axios.post("verifyUserExistOtp",otpData);
      console.log(response);
      this.show_otp_button = false;
      this.otp_success_message = JSON.parse(JSON.stringify(response.data.message));
      


    }catch(error){
      console.log(error);

      this.otpErr = error.response.data.error;
        
      this.otp_success_message = "";

    }

   },





  //  async getUserPort(user_portal_id){
  //           if(this.view_type == "edit" || this.view_type == "view")
  //         {

          
  //            try{
  //             console.log("From Watchers",user_portal_id);
  //             console.log("From Props",this.get_user_portal_id);
  //           this.showLoading(true);
  //           const response = await axios.get(`user_portals/${user_portal_id}`);
  //           const getData = response.data.data;
  //           this.showLoading(false);
  //           this.user_idd = getData.user_id;
  //           this.formData.user_idy = this.user_idd;
  //           this.formData.fullname = getData.fullname;
  //           this.formData.gender = getData.gender;
  //           this.formData.country_id = getData.country_id;
  //           this.formData.gps_address = getData.gps_address;
  //           this.formData.city = getData.city;
  //           this.formData.contact = getData.contact;
  //           this.formData.email = getData.email;
  //           this.formData.password = getData.password;
  //           this.formData.password_confirmation = getData.password;
  //           this.formData.user_level = getData.user_level;
  //           this.formData.reg_otp = getData.reg_otp;
  //           this.formData.portal_id = getData.id;
  //           this.formData.portal = getData.portal;

  //           if(this.formData.user_level == "COUNTRY")
  //           {

  //             const response = await axios.get(`getEmployeeCountryPortal/${this.user_idd}`);
  //              this.showLoading(false);
  //             this.employee_user_country_portal = response.data.data;
             

  //           }

  //             else if(this.formData.user_level == "LOCAL")
  //           {
  //             const response = await axios.get(`getEmployeeProviderPortal/${this.user_idd}`);
  //             this.showLoading(false);
  //             this.employee_user_provider_portal = response.data.data;

  //           }
             
  //            }catch(error){

  //             this.showLoading(false);
  //             console.log(error);
              
               
  //            }

  //         }
  //  },


   userCountryEdit(id){
 
       this.$emit("country_view_typee","edit",id);
   },



   userProviderEdit(id){
     this.$emit("provider_view_typee","edit",id);
   }








      },


    //    computed:{

    //     changes : {
    //         get : function(){
    //             return this.count_down;
    //         },
    //         set : function(vem){
             
    //             this.count_down =  vem;
    //              if(this.count_down <= 0){
    //             this.count_down = "";
    //              clearInterval(this.count_down);
    //           }
    //         }
    //     }
    // },

 


      watch:{
           view_type(value){

           if(value == "add")
           {
             this.clearFormData();
           }

          

        },

     


           async getUserPortalData(value){
            const data = value;

             this.user_idd = data.user_id;
            this.formData.user_idy = this.user_idd;
            this.formData.fullname = data.fullname;
            this.formData.gender = data.gender;
            this.formData.country_id = data.country_id;
            this.formData.gps_address = data.gps_address;
            this.formData.city = data.city;
            this.formData.contact = data.contact;
            this.formData.email = data.email;
            this.formData.password = data.password;
            this.formData.password_confirmation = data.password;
            this.formData.user_level = data.user_level;
            this.formData.reg_otp = data.reg_otp;
            this.formData.portal_id = data.id;
            this.formData.portal = data.portal;

            if(this.formData.user_level == "COUNTRY")
            {

              const response = await axios.get(`getEmployeeCountryPortal/${this.user_idd}`);
               this.showLoading(false);
              this.employee_user_country_portal = response.data.data;
             

            }

              else if(this.formData.user_level == "LOCAL")
            {
              const response = await axios.get(`getEmployeeProviderPortal/${this.user_idd}`);
              this.showLoading(false);
              this.employee_user_provider_portal = response.data.data;

            }
          }
      }
        
    }
</script>

<style scoped>


</style>