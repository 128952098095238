<template>

    <div class="unix-login">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="login-content">
                        <div class="login-logo">
                            <img src="@/assets/eMedipay-logo-small.png" alt="" style="height: 55px;"/>
                        </div>
                        <div class="card login-form">
                           <div class="card shadow-sm"  id="stylized">

                            <div class="card-body">

                            
                            <h4>{{ portal }} - Login </h4>
                            <form @submit.prevent="handleSubmit()">
                                <div v-if="!verified && verified_portal == portal && email">
                                    Please Verify Your Account Before You Login.
                                    <br/>Click link on the mail sent to you
                                    
                                </div>
                                <label>Email address / Contact</label>
                                <div class="input-group mb-3">
                                    <input type="text" v-model="email" class="form-control" placeholder="Email/Contact">
                                </div>

                                <label>Password</label>
                                <div class="input-group mb-3">
                                    <input v-bind:type="[showPassword ? 'text' : 'password']" v-model="password" class="form-control" placeholder="Password">
                                    <i class="fa" @click="showPassword = !showPassword"   :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>
                                </div>


                                <div class="form-group" v-if="route_name=='provider_login'">
                                    <label>Please Select Your Provider</label>
                                    <v-select :options="getProviders"  class="elect1" v-model="provider_id"  :reduce="provider => provider.id" label="provider" />
                                </div>




 

                                <div class="checkbox">
                                    <!-- <label>
                                        <input type="checkbox"> Remember Me
                                    </label> -->
                                    <label class="float-end">
                                        <router-link to="/forgot-password">Forgotten Password?</router-link>
                                    </label>

                                </div>
                                <button type="submit" class="btn btn-primary btn-flat m-b-30 m-t-30">Sign in</button>
                                <!-- <div class="social-login-content">
                                    <div class="social-button">
                                        <button type="button" class="btn btn-primary bg-google-plus btn-flat btn-addon m-b-10"><i class="ti-google"></i>Register with google</button>
                                        <button type="button" class="btn btn-primary bg-facebook btn-flat btn-addon m-t-10"><i class="ti-facebook"></i>Register with facebook</button>
                                        <button type="button" class="btn btn-primary bg-linkedin btn-flat btn-addon m-t-10"><i class="ti-linkedin"></i>Register with linkedin</button>
                                        <button type="button" class="btn btn-primary bg-twitter btn-flat btn-addon m-t-10"><i class="ti-twitter"></i>Register with twitter</button>
                                    </div>
                                </div> -->
                                <div class="register-link m-t-15 text-center" v-if="!page">
                                    <div>Don't have account ? <router-link to="/register"> Register Here</router-link></div>
                                    <div>Go To  <router-link to="/"> Portal</router-link></div>
                                </div>
                                
                            </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapMutations, mapState } from 'vuex';
import notificationBox from '@/services/notificationBox';
import { AUTH_LOGIN, LOADING } from '@/store/storeconstants';
import vSelect from 'vue-select'


export default {    
  name: "LoginView",
  components: {
    vSelect,
  },
  async created(){

           this.route_name = this.$route.name;
         if(this.$route.name == "em_login")
        {
            this.portal =   localStorage.setItem('Portall',"Gerdd")
            localStorage.setItem('page',true)
            this.page = true;
          
         }else{
          
                       localStorage.setItem('page',false)
                    this.page = false;
                }

                 if(this.$route.name == "provider_login")
            {
               this.portal =  localStorage.setItem('Portall',"Provider")
            }
            else if(this.$route.name == "support_login")
            {
              this.portal =  localStorage.setItem('Portall',"Support")  
            }
                 else if(this.$route.name == "dependent_login")
            {
              this.portal =  localStorage.setItem('Portall',"Dependent")  
            }
                else if(this.$route.name == "telemedicine_login")
            {
              this.portal =  localStorage.setItem('Portall',"Telemedicine")  
            }


            this.portal =  localStorage.getItem('Portall')
            if(this.verified_portal == this.portal)
            {
            this.email =  localStorage.getItem("login_email")
            }
            else{
                this.email =  "";
            }
          //  localStorage.removeItem("login_email")
          if(this.verified){
          //  this.is_verified = this.verified
          
            localStorage.setItem("verified",this.verified);
            this.is_verified = localStorage.getItem("verified");
          }
          else{
              localStorage.setItem("verified",false);
            this.is_verified = localStorage.getItem("verified");
          }
         

            if(this.verified_username){
           // this.email = this.verified_username
            console.log(this.portal.toUpperCase());
            localStorage.setItem("login_email",this.verified_username);
                 if(this.verified_portal == this.portal.toUpperCase())
            {
            this.email =  localStorage.getItem("login_email")
            }
            else{
                this.email =  "";
            }
          }
          else{
        
                this.email =  "";
            
          }


           try{
       let response = await axios.get("getProviders");
      // console.log(response.data.data);
         
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }
            
  },


  data: function(){
      return {
          portal:"",
          is_verified: false,
          clear:false,
          email:"",
          password:"",
          provider_id:"",
          getProviders:[],
          errorServers:[],
          page:"",
          route_name:"",
          showPassword:false,

          
      }
  },



  computed:{
    ...mapState('auth',['verified','verified_portal','verified_username'])
  },


  methods:{

    ...mapActions({
          loginAct: 'auth/'+AUTH_LOGIN,
    }),

    
     
    ...mapMutations({
       showLoading:LOADING,
   }),


    async handleSubmit(){

           this.errorServers = [];
   
        const formData = {
            email: this.email,
            password: this.password,
            provider_id: this.provider_id,
            portal:this.portal
         }

      


            await this.loginAct(formData).catch(error => {
           this.errorServers.push(error.response.data.errors);
          console.log(error);
           this.showLoading(false);
           notificationBox.errorNotifications(error.response.data.message);

    });

    


        if(this.errorServers.length < 1){

           

             this.showLoading(false);
             this.errorServers = [];
             this.errorClient = [];
             localStorage.setItem("login_provider_idd",this.provider_id);
            this.clear_all();
            notificationBox.succNotifications("Login Successful");
            this.$router.push("/login_otp");

           }
  },


     clear_all(){
      this.clear = true;
      this.errorServers = [];
      this.password = "";
      this.email = "";
      this.provider_id = "";
      this.clear = false;
        },


  }
}
</script>