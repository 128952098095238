<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Providers" pageDesc="list of all providers"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" :status_prop="status_prop" portall="provider" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton :num_checked="checked.length" portall="provider" @massApproveProvider="approveProviders"  @massRejectProvider="rejectProviders" />









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->

                                         <!-- <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeCountryModal" data-bs-toggle="modal" href="#userEmployeeCountryModal" >
                                            <i class="fas fa-plus"></i> Link User To Country Portal
                                        </a>    

                                          <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a>  -->
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">



<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="nav-home-tab" ref="show_all" data-bs-toggle="tab" @click="show_providers('')" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
    <button class="nav-link" id="nav-active-provider-tab" ref="show_approved" data-bs-toggle="tab" @click="show_providers('APPROVED')" data-bs-target="#nav-active-provider" type="button" role="tab" aria-controls="nav-active-provider" aria-selected="false">Active</button>
    <button class="nav-link" id="nav-pending-provider-tab" ref="show_pending" data-bs-toggle="tab"  @click="show_providers('PENDING')" data-bs-target="#nav-pending-provider" type="button" role="tab" aria-controls="nav-active-provider" aria-selected="false">Pending</button>
    <button class="nav-link" id="nav-inactive-provider-tab" ref="show_rejected" data-bs-toggle="tab"  @click="show_providers('REJECTED')"  data-bs-target="#nav-inactive-provider" type="button" role="tab" aria-controls="nav-inactive-provider" aria-selected="false">Rejected</button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-home"  role="tabpanel" aria-labelledby="nav-home-tab">



                          <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('proividers.provider_contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='proividers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='proividers.provider_contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('health_cares.health_care')">Health Care</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='health_cares.health_care'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='health_cares.health_care'">&darr;</span>
                                                </th>

                                             

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Health Professional</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(provider_port) in providers" :key="provider_port.id" :class="isChecked(provider_port.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="provider_port.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{provider_port.provider}}</td>
                                                    <td>{{ provider_port.country }}</td>
                                                    <td>{{ provider_port.provider_contact }}</td>
                                                    <td>{{ provider_port.health_care}}</td>
                                                    <td>{{ provider_port.status }}</td>
                                                    <td>{{ provider_port.fullname }}</td>
                                                    <td v-if="user_level == 'COUNTRY' || user_level == 'GLOBAL'">
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <!-- <div class="btn"  title="edit" @click="providerApprove(user_portal.id, user_portal.user_id)" data-bs-toggle="modal" data-bs-target="#userModal">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <div onclick="confirm('Are You Sure You Want To Approve This Provider?') || event.stopImmediatePropagation()" class="btn" title="approve" @click="approveSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Reject This Provider?') || event.stopImmediatePropagation()" class="btn" title="reject" @click="rejectSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="note" @click="providerNote(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-note-sticky"></i>
                                                                </a>

                                                                     <a class="btn" title="view" @click="providerView(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>

     

                                                    <!-- <td v-else-if="">

                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>



  </div>
  <div class="tab-pane fade" id="nav-active-provider" role="tabpanel" aria-labelledby="nav-active-provider-tab">
            


                                      <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('proividers.provider_contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='proividers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='proividers.provider_contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('health_cares.health_care')">Health Care</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='health_cares.health_care'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='health_cares.health_care'">&darr;</span>
                                                </th>

                                             

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Health Professional</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(provider_port) in providers" :key="provider_port.id" :class="isChecked(provider_port.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="provider_port.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{provider_port.provider}}</td>
                                                    <td>{{ provider_port.country }}</td>
                                                    <td>{{ provider_port.provider_contact }}</td>
                                                    <td>{{ provider_port.health_care}}</td>
                                                    <td>{{ provider_port.status }}</td>
                                                    <td>{{ provider_port.fullname }}</td>
                                                    <td v-if="user_level == 'COUNTRY' || user_level == 'GLOBAL'">
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <!-- <div class="btn"  title="edit" @click="providerApprove(user_portal.id, user_portal.user_id)" data-bs-toggle="modal" data-bs-target="#userModal">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <!-- <div onclick="confirm('Are You Sure You Want To Approve This Provider?') || event.stopImmediatePropagation()" class="btn" title="approve" @click="approveSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <div onclick="confirm('Are You Sure You Want To Reject This Provider?') || event.stopImmediatePropagation()" class="btn" title="reject" @click="rejectSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="note" @click="providerNote(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-note-sticky"></i>
                                                                </a>

                                                                     <a class="btn" title="view" @click="providerView(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>

     

                                                    <!-- <td v-else-if="">

                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>


  </div>
  <div class="tab-pane fade" id="nav-pending-provider" role="tabpanel" aria-labelledby="nav-pending-provider-tab">



                                         <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('proividers.provider_contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='proividers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='proividers.provider_contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('health_cares.health_care')">Health Care</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='health_cares.health_care'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='health_cares.health_care'">&darr;</span>
                                                </th>

                                             

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Health Professional</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(provider_port) in providers" :key="provider_port.id" :class="isChecked(provider_port.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="provider_port.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{provider_port.provider}}</td>
                                                    <td>{{ provider_port.country }}</td>
                                                    <td>{{ provider_port.provider_contact }}</td>
                                                    <td>{{ provider_port.health_care}}</td>
                                                    <td>{{ provider_port.status }}</td>
                                                    <td>{{ provider_port.fullname }}</td>
                                                    <td v-if="user_level == 'COUNTRY' || user_level == 'GLOBAL'">
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <!-- <div class="btn"  title="edit" @click="providerApprove(user_portal.id, user_portal.user_id)" data-bs-toggle="modal" data-bs-target="#userModal">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <div onclick="confirm('Are You Sure You Want To Approve This Provider?') || event.stopImmediatePropagation()" class="btn" title="approve" @click="approveSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Reject This Provider?') || event.stopImmediatePropagation()" class="btn" title="reject" @click="rejectSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="note" @click="providerNote(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-note-sticky"></i>
                                                                </a>

                                                                     <a class="btn" title="view" @click="providerView(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>

     

                                                    <!-- <td v-else-if="">

                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>




  </div>
  <div class="tab-pane fade" id="nav-inactive-provider" role="tabpanel" aria-labelledby="nav-inactive-provider-tab">


          <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('proividers.provider_contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='proividers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='proividers.provider_contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('health_cares.health_care')">Health Care</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='health_cares.health_care'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='health_cares.health_care'">&darr;</span>
                                                </th>

                                             

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Health Professional</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(provider_port) in providers" :key="provider_port.id" :class="isChecked(provider_port.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="provider_port.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{provider_port.provider}}</td>
                                                    <td>{{ provider_port.country }}</td>
                                                    <td>{{ provider_port.provider_contact }}</td>
                                                    <td>{{ provider_port.health_care}}</td>
                                                    <td>{{ provider_port.status }}</td>
                                                    <td>{{ provider_port.fullname }}</td>
                                                    <td v-if="user_level == 'COUNTRY' || user_level == 'GLOBAL' || user_level == 'ADMIN'">
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <!-- <div class="btn"  title="edit" @click="providerApprove(user_portal.id, user_portal.user_id)" data-bs-toggle="modal" data-bs-target="#userModal">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <div onclick="confirm('Are You Sure You Want To Approve This Provider?') || event.stopImmediatePropagation()" class="btn" title="approve" @click="approveSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                  
                                                                <!-- <div onclick="confirm('Are You Sure You Want To Reject This Provider?') || event.stopImmediatePropagation()" class="btn" title="reject" @click="rejectSingleRecord(provider_port.id)">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </div>                                                   -->
                                                                <a class="btn" title="note" @click="providerNote(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-note-sticky"></i>
                                                                </a>

                                                                     <a class="btn" title="view" @click="providerView(provider_port.id)"   data-bs-toggle="modal"  href="#providerCommentModal">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>

     

                                                    <!-- <td v-else-if="">

                                                    </td> -->
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>


  </div>
</div>



                                   
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div>


        <ProviderNote @provider_added="provider_added" />
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import ProviderNote from '@/components/modalForms/ProviderNote.vue' 
import { mapActions, mapMutations, mapState } from 'vuex'
import { APPROVE_PROVIDER, GET_PROVIDER_ID, GET_PROVIDER_VIEW_TYPE, LOADING, MASS_APPROVE_PROVIDER, MASS_REJECT_PROVIDER, REJECT_PROVIDER, SEARCH_USER_ID, SEARCH_USER_USER_PORTAL, SET_PROVIDER_INFO, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "ProvidersView",
  beforeRouteEnter(to,from,next){
     
       let user_level =  null;
     const user_data = JSON.parse(localStorage.getItem("users"));
      user_level = user_data.user_level;
    
    



        if(user_level == 'GLOBAL' || user_level == 'COUNTRY' || user_level == 'LOCAL' || user_level == 'ADMIN'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    ProviderNote,
  },



  data(){
    return {
        providers:{},
        showUserModals:false,
        user_level:"",
        country_levels:[],
        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        status_prop:"",
        checked:[],
        app_portal:"",
        provider_id:"",
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',
    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result']),
    ...mapState("provider",['provider_message'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       get_provider_id: 'provider/'+GET_PROVIDER_ID,
       get_provider_view_type: 'provider/'+GET_PROVIDER_VIEW_TYPE,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),


   ...mapActions({
             search_user_id: 'user/'+SEARCH_USER_ID,
       search_user_portal_id:'user_portal/'+SEARCH_USER_USER_PORTAL,
       approve_provider:'provider/' + APPROVE_PROVIDER,
       reject_provider:'provider/'+ REJECT_PROVIDER,
       mass_approve_provider:'provider/'+ MASS_APPROVE_PROVIDER,
       mass_reject_provider:'provider/'+MASS_REJECT_PROVIDER,
       set_provider_info:'provider/'+SET_PROVIDER_INFO,
   }),

    selectAllRecords(){

        axios.get('providers/all?status=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getProvider();

    },



//    deleteSingleRecord(id){

//     //console.log(id);

//        axios.delete(`providers/${id}`)
//        .then( (response) => {
//         this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
//         //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
//         console.log(response.data.success)
//         notificationBox.succNotifications("Deleted Successfully");
//          this.getProvider();
//        }).catch(error => {
//         console.log(error.message)
//          notificationBox.errorNotifications(error)
//        })


//    },



   deleteRecords(){
      axios.delete(`providers/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Providers were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getProvider();

         }
      })
   },






  async approveSingleRecord(id){

    const formData={
        "provider_id" : id,
        "comment" : null
    }




              await this.approve_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
         console.log(error);
    
           notificationBox.errorNotifications("Provider Could Not Be Approved");
           return true
       });

           this.getProvider();
          notificationBox.succNotifications(this.provider_message);


  },




  async rejectSingleRecord(id){

            const formData={
        "provider_id" : id,
        "comment" : null
    }


         await this.reject_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
         console.log(error);
    
           notificationBox.errorNotifications("Provider Could Not Be Approved");
           return true
       });

          this.getProvider();
          notificationBox.succNotifications(this.provider_message);

    

  },



  async approveProviders(){


            const formData={
        "providers" : this.checked,
        "comment" : null
    }


         await this.mass_approve_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
         console.log(error);
    
           notificationBox.errorNotifications("Provider(s) Could Not Be Approved");
           return true
       });


          this.getProvider();
          notificationBox.succNotifications(this.provider_message);

  },




  async rejectProviders(){


         const formData={
        "providers" : this.checked,
        "comment" : null
    }


         await this.mass_reject_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
         console.log(error);
    
           notificationBox.errorNotifications("Provider(s) Could Not Be Rejected");
           return true
       });


          this.getProvider();
          notificationBox.succNotifications(this.provider_message);

  },



  providerNote(id){
    this.get_provider_id(id);
    this.get_provider_view_type("add");
  },
   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getProvider();

    },



    provider_added(){
        this.getProvider();
    },




   isChecked(id){

    return  this.checked.includes(id);

   },




    getProvider(){

         this.showLoading(true); 
   
        axios.get(
            'providers?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&status=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field 
            )
        .then(response => {

             this.showLoading(false); 

            this.providers = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    show_providers(status){
       this.status_prop = status;
       this.statusval(status)
        //this.getProvider();
    },

    perpageval(value){
        this.table_per_page(value);
       this.getProvider();

    },

    searchval(value){

         this.table_search(value);
        this.getProvider();

    },

    statusval(value){

        this.table_status(value);
        if(value == '')
        {
          this.$refs.show_all.click();
        }

        else if(value == 'APPROVED')
        {
          this.$refs.show_approved.click();
        }
        else if(value == 'PENDING')
        {
          this.$refs.show_pending.click();
        }
        else if(value == 'REJECTED')
        {
          this.$refs.show_rejected.click();
        }
        this.getProvider();

    },

 







       added_providers(){
        this.getProvider();
    },




    providerView(id){
        this.set_provider_info(id);
        this.get_provider_view_type("view");
    },





    edit_user_provider_portal(){
        this.$refs.userEditEmployeeProviderModal.click();
    },



//    user_employee_country_edit(){
//              console.log("okkkkkkkkkkkk")
//              this.view_type = "view";
//            //this.userView(portal_id, user_id)
//            this.$refs.userCountryModal.click();
//     }


  },





  created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
       // console.log(user_data)
          this.user_level = user_data.user_level;
          this.provider_id = user_data.provider_id;
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getProvider();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const users = JSON.parse(JSON.stringify(this.providers));
            users.forEach(user=>{
                this.checked.push(user.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "providers/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>