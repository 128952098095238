<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Debtors" pageDesc="list of all debtors"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />



                                    <TableFilter @statusValue="statusval" portall="debtors" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->


                                           <a style="display:none" class="btn btn-outline-success" ref="add_edit_user" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add From Function User
                                        </a>   

                                         <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeCountryModal" data-bs-toggle="modal" href="#userEmployeeCountryModal" >
                                            <i class="fas fa-plus"></i> Link User To Country Portal
                                        </a>    

                                          <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a> 
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-condensed table-hover table-striped" v-if="getstatus==''">
                                            <thead>
                                                <tr>
                                               <th scope="col">View Related Support Member</th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Dependent Full Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.gender'">&darr;</span>
                                                </th>

                                    

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.city')">City</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.city'">&darr;</span>
                                                </th>


                                               <th scope="col" v-if="app_portal!='Provider'">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                                <th scope="col"  v-if="app_portal!='Provider'">
                                                <a href="#" @click.prevent="change_sort('providers.provider_contact')">Provider Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider_contact'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi Charge</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>




                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('prov_total_bal')">Providers Charge</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>
                                

                                    


                                                   <th scope="col">
                                                <a href="#" @click.prevent="change_sort('total_bal')">Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>

                                            

                                                </tr>
                                            </thead>
                                            <tbody v-for="debtor in debtors"  :key="debtor.id">
                                                <tr>
                                               <td @click="show_support_dependent_debtor(debtor.dependent_id,debtor.provider_id,null); debtor.created_at = !debtor.created_at" style="cursor:pointer"><button class="btn btn-default btn-xs"><span class="fa fa-eye" title="Click To View Support"></span></button></td>   
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{debtor.fullname}}</td>
                                                    <td>{{ debtor.contact }}</td>
                                                    <td>{{ debtor.email }}</td>
                                                    <td>{{ debtor.gender }}</td>
                                                    <td>{{ debtor.city }}</td>
                                                    <td  v-if="app_portal!='Provider'">{{ debtor.provider }}</td>
                                                    <td  v-if="app_portal!='Provider'">{{ debtor.provider_contact }}</td>
                                                    <td>{{ debtor.emedi_total_bal }}</td>
                                                    <td>{{ debtor.prov_total_bal }}</td>
                                                    <td>{{ debtor.total_bal }}</td>
                                                    
                                                </tr>

                                                <tr v-if="debtor.created_at">
                                                    <td colspan="12">


                                                            <table class="table table-striped">

                                                                <thead>
                                                                <tr class="info">
                                                                <th>Support Fullname</th>
                                                                <th>Support Contact</th>
                                                                <th>Support Email</th>		
                                                                <th>Support Gender</th>	
                                                                <th>Support Country</th>	
                                                                <th>Support City</th>	
                                                            </tr>
                                                        </thead>	

                                                        <tbody>
                                                            <tr v-for="support_debtors in support_dependent_debtors.filter(element => element.dependent_id == debtor.dependent_id)" :key="support_debtors.id">

                                                                <td>{{support_debtors.fullname}}</td>
                                                                <td>{{support_debtors.contact}}</td>
                                                                <td>{{support_debtors.email}}</td>
                                                                <td>{{support_debtors.gender}}</td>
                                                                <td>{{support_debtors.country}}</td>
                                                                <td>{{support_debtors.city}}</td>

                                                            </tr>
                                                        </tbody>

                                                            </table>

                                                        

                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>





                                             <table class="table table-condensed table-hover table-striped" v-else-if="getstatus=='BILLS'">
                                            <thead>
                                                <tr>
                                               <th scope="col">View Related Support Member</th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Dependent Full Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.gender'">&darr;</span>
                                                </th>

                                    

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.city')">City</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.city'">&darr;</span>
                                                </th>


                                               <th scope="col" v-if="app_portal!='Provider'">
                                                <a href="#" @click.prevent="change_sort('providers.provider')">Provider</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider'">&darr;</span>
                                                </th>

                                                <th scope="col"  v-if="app_portal!='Provider'">
                                                <a href="#" @click.prevent="change_sort('providers.provider_contact')">Provider Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.provider_contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.provider_contact'">&darr;</span>
                                                </th>



                                                 <th scope="col">
                                                <a href="#" @click.prevent="change_sort('billings.bill_number')">Billing Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='billings.bill_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='billings.bill_number'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('billings.trans_date')">Billing Date</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='billings.trans_date'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='billings.trans_date'">&darr;</span>
                                                </th>


                                                       <th scope="col">
                                                <a href="#" @click.prevent="change_sort('emedi_total_bal')">Emedi Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='emedi_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='emedi_total_bal'">&darr;</span>
                                                </th>




                                                       <th scope="col">
                                                <a href="#" @click.prevent="change_sort('prov_total_bal')">Provider Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='prov_total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='prov_total_bal'">&darr;</span>
                                                </th>

                                    


                                                   <th scope="col">
                                                <a href="#" @click.prevent="change_sort('total_bal')">Total Balance</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='total_bal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='total_bal'">&darr;</span>
                                                </th>

                                            

                                                </tr>
                                            </thead>
                                            <tbody v-for="debtor in debtors"  :key="debtor.id">
                                                <tr>
                                               <td @click="show_support_dependent_debtor(debtor.dependent_id,debtor.provider_id,debtor.billing_id); debtor.created_at = !debtor.created_at"><button class="btn btn-default btn-xs"><span class="fa fa-eye"></span></button></td>   
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{debtor.fullname}}</td>
                                                    <td>{{ debtor.contact }}</td>
                                                    <td>{{ debtor.email }}</td>
                                                    <td>{{ debtor.gender }}</td>
                                                    <td>{{ debtor.city }}</td>
                                                    <td  v-if="app_portal!='Provider'">{{ debtor.provider }}</td>
                                                    <td  v-if="app_portal!='Provider'">{{ debtor.provider_contact }}</td>
                                                    <td>{{ debtor.bill_number }}</td>
                                                    <td>{{ debtor.trans_date }}</td>
                                                    <td>{{ debtor.emedi_total_bal }}</td>
                                                    <td>{{ debtor.prov_total_bal }}</td>
                                                    <td>{{ debtor.total_bal }}</td>
                                                    
                                                </tr>

                                                <tr v-if="debtor.created_at">
                                                    <td colspan="12">


                                                            <table class="table table-striped">

                                                                <thead>
                                                                <tr class="info">
                                                                <th>Support Fullname</th>
                                                                <th>Support Contact</th>
                                                                <th>Support Email</th>		
                                                                <th>Support Gender</th>	
                                                                <th>Support Country</th>	
                                                                <th>Support City</th>	
                                                            </tr>
                                                        </thead>	

                                                        <tbody>
                                                            <tr v-for="support_debtors in support_dependent_debtors.filter(element => element.billing_id == debtor.billing_id)" :key="support_debtors.id">

                                                                <td>{{support_debtors.fullname}}</td>
                                                                <td>{{support_debtors.contact}}</td>
                                                                <td>{{support_debtors.email}}</td>
                                                                <td>{{support_debtors.gender}}</td>
                                                                <td>{{support_debtors.country}}</td>
                                                                <td>{{support_debtors.city}}</td>

                                                            </tr>
                                                        </tbody>

                                                            </table>

                                                        

                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>


                                    </div>
                                    <!-- <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div>

        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
// import UserModals from "@/components/modalForms/Users.vue"
// import EmployeeUserCountry from "@/components/modalForms/EmployeeUserCountry.vue"
// import EmployeeUserProvider from "@/components/modalForms/EmployeeUserProvider.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
//import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
//import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "AllDebtorsView",
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
   // UserModals,
   //EmployeeUserCountry,
  // EmployeeUserProvider,
    TableFilter,
    ActionButton,
    SelectAllComponent,
 //   VueTailwindPagination,
  },



  data(){
    return {
        debtors:{},
        support_dependent_debtors:[],
        showUserModals:false,
        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        checked:[],
        user_portal_id:"",
        user_id:"",
        getUserPortalData:{},
        user_table_data:{},
        user_portal_table_data:{},
        view_type:"",
        app_portal:"",
        user_level:"",
        show_dependent: false,
        selectPage:false,
        dependent_id:"",
        dependent_idd:[],
        billing_idd:[],
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',
    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    // selectAllRecords(){

    //     axios.get('user_portals/all?status=' + this.getstatus  + '&portal=' + this.app_portal +
    //         '&provider_id=' + this.provider_id +
    //         '&support_id=' + this.support_id +
    //         '&dependent_id=' + this.dependent_id)
    //          .then(response => {
    //              console.log(response.data);
    //               this.checked = response.data;
    //               // this.selectAll = true;
    //              // this.table_checked(response.data);
    //                this.table_select_all(true);
    //          });
    // },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getDebtors();

    },



//    deleteSingleRecord(id){

//     //console.log(id);

//        axios.delete(`user_portals/${id}`)
//        .then( (response) => {
//         this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
//         //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
//         console.log(response.data.success)
//         notificationBox.succNotifications("Deleted Successfully");
//          this.getUser();
//        }).catch(error => {
//         console.log(error.message)
//          notificationBox.errorNotifications(error)
//        })


//    },



//    deleteRecords(){
//       axios.delete(`user_portals/massDestroy/${this.checked}`)
//       .then((response) => {
//          if(response.status == 204){
//             console.log(response.data.success)
//             notificationBox.succNotifications("Selected Users were Deleted Successfully");
//             this.checked = [];
//             //this.table_checked([])
//             this.getUser();

//          }
//       })
//    },





   


    // pageChange(pageNumber){

    //     //this.currentPage = pageNumber;
    //     this.table_current_page(pageNumber);
    //     this.getUser();

    // },




   isChecked(id){

    return  this.checked.includes(id);

   },



    async getDebtors(){
       try{
      const response = await axios.get(
            'getDebtorsDependent?portal=' + this.app_portal + 
            '&provider_id=' + this.provider_id +
            '&search_term=' +this.getsearch + 
            '&status=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&login_user_id=' + this.user_id +
            '&user_level=' + this.user_level
            );
       
          

            this.debtors = response.data.data;
         
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        

       }catch(error){

           console.log(error);

       }

    },




    async show_support_dependent_debtor(dependent_id,provider_id,billing_id){
    this.show_dependent = !this.show_dependent

      if(this.getstatus=='')
      {
       if(this.dependent_idd.includes(dependent_id)){
                    let pos = this.dependent_idd.indexOf(dependent_id);
                    this.dependent_idd.splice(pos,1);
                 }else{
                    this.dependent_idd.push(dependent_id)
                 }

                 
                
          if(this.dependent_idd.length > 0)
          {

         try{
           
              const formData={
                 provider_id : provider_id,
                 dependent_id : this.dependent_idd,
                 billing_id : billing_id
              }
            
      const response = await axios.post('getSupportDependentDebtor',formData);
       
          
            
            this.support_dependent_debtors = response.data.data;
              //console.log(this.support_dependent_debtors);
         
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        

       }catch(error){

           console.log(error);

       }

          }
      }

      else if(this.getstatus=='BILLS'){

               if(this.billing_idd.includes(billing_id)){
                    let pos = this.billing_idd.indexOf(billing_id);
                    this.billing_idd.splice(pos,1);
                 }else{
                    this.billing_idd.push(billing_id)
                 }



                  if(this.billing_idd.length > 0)
          {

         try{
           
              const formData={
                 provider_id : provider_id,
                 dependent_id : dependent_id,
                 billing_id : this.billing_idd
              }
            
      const response = await axios.post('getSupportDependentDebtor',formData);
       
          
            
            this.support_dependent_debtors = response.data.data;
              //console.log(this.support_dependent_debtors);
         
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        

       }catch(error){

           console.log(error);

       }

          }

      }
        

    },



    perpageval(value){
        this.table_per_page(value);
       this.getDebtors();

    },


    searchval(value){

         this.table_search(value);
        this.getDebtors();

    },

    statusval(value){

        this.table_status(value);
        this.getDebtors();

    },

 

   userEdit(portal_id,user_id){

    this.user_portal_id = portal_id;
    this.user_id = user_id;
    this.getUserPort(portal_id);
    this.view_type = "edit";
    this.$refs.add_edit_user.click();

   },



   //user_employee_country_updated(portal_id,user_id)



   userView(portal_id,user_id){

    this.user_portal_id = portal_id;
    this.user_id = user_id;
    this.view_type = "view";
    this.getUserPort(portal_id);
    
    this.$refs.add_edit_user.click();

   },





              async getUserPort(user_portal_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`user_portals/${user_portal_id}`);
            this.showLoading(false);
                this.getUserPortalData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },


     userAdd(){

        this.view_type = "add";

     },


     userAdded(){
        this.getDebtors();
     },



     country_view_typee(edit_value,id){

        this.country_view_type = edit_value;
        this.employee_user_id = id;
        this.$refs.userEditEmployeeCountryModal.click();
     },



     provider_view_typee(edit_value,id){

         this.provider_view_type = edit_value;
        this.employee_provider_user_id = id;
        this.$refs.userEditEmployeeProviderModal.click();

     }

  
  },





 async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
          this.provider_id = user_data.provider_id;
         // console.log(this.provider_id);
          let user_idd = user_data.id;
          this.user_id = user_idd;
          this.user_level = user_data.user_level;
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
  console.log(this.app_portal);


      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
 
        this.support_id =  JSON.parse(JSON.stringify(response.data.data));



          let responsee = await axios.post("getDependentId",user_dataa);
 
        this.dependent_id =  JSON.parse(JSON.stringify(responsee.data.data));
      
       
       
       
      


    //this.currentPage = 1;
    this.table_current_page(1);
    this.getDebtors();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const users = JSON.parse(JSON.stringify(this.users));
            users.forEach(user=>{
                this.checked.push(user.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "user_portals/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>