<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="health_careModal"    aria-labelledby="health_careModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="health_careModalLabel" v-if="health_care_view_type=='add'">Add Health Care</h5>
                <h5 class="modal-title" id="health_careModalLabel" v-else-if="health_care_view_type=='edit'">Edit Health Care</h5>
                <h5 class="modal-title" id="health_careModalLabel" v-else-if="health_care_view_type=='view'">View Health Care</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#health_careModal" aria-label="Close"></button>
            </div>
              <div v-if="this.health_care_view_type=='add' || this.health_care_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1 v-if="health_care_view_type == 'add'">Add Health Care</h1>
                      <h1 v-else-if="health_care_view_type == 'edit'">Edit Health Care</h1>
                      <br/>
                      <p></p>
        
                  <span>
                  
           




                      <div class="row">

                <div class="col">
                        <label>Health Care</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: health_careErr}"
                            placeholder="Health Care"
                            @blur="health_careVal($event.target.value)" 
                            v-model.trim="formData.health_care"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: health_careErr  }"
                           v-if="health_careErr">
                          {{ health_careErr }} 
                          </div>
                      </div>







                     <div class="col">
                              <label>Is Active</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: is_activeErr}"
                             @change="is_activeVal($event.target.value)"
                            v-model.trim="formData.is_active">
                            <option value="">--Choose An Option--</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: is_activeErr}"
                            v-if="is_activeErr"
                          >
                            {{ is_activeErr }}
                          </div>
                      </div>

         



                     




                    </div>



                  </span>
                    </div>



                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="health_care_view_type=='add'">Submit</button>
                <button type="submit" class="btn btn-primary" v-if="health_care_view_type=='edit'">Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="health_care_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="health_care_modal_ref" data-bs-dismiss="modal" v-if="health_care_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Health Care</td>
                                <td>{{formData.health_care}}</td>
                                </tr>

                               <tr>
                                <td>Is Active</td>
                                <td>{{formData.is_active}}</td>
                                </tr>




                 
                              </tbody>
                                  </table>









                                                                

                    
                        </div>

                      </div>

                 </div>


            </div>
        </div>


        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
//import SpinnerComp from "@/components/SpinnerComp.vue";
import {mapMutations } from 'vuex';
import {  LOADING } from '@/store/storeconstants';
//import VueMultiselect from 'vue-multiselect'
//import Select2 from 'vue3-select2-component';
//import vSelect from 'vue-select'

    export default {

        name:'HealthCareModals',
        props:{
            health_care_view_type:{
                required:true,
            },

            health_care_data:{
                required:true,
            }
        },


           mounted(){

              // const input = document.querySelector("#telephone");
              // console.log(input);
              // intlTelInput(input,{
              //   // any initialisation options go here
              //       preferredCountries: ["gh", "us", "ca"],
                
                  
              // });

             //   console.log(localStorage.getItem("token"));

           },

        async created() {

  
  const user_data = JSON.parse(localStorage.getItem("users"));
      
          this.formData.user_id = user_data.id;
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      app_portal: "",
      loading:false,
      errorMess: "",
      succMess:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
       modal:"modal",


   
      

      formData: {
        portal:"",
        health_care_id:"",

        health_care: "",
        is_active: "",
        view_type:"",
        user_id:"",
        
        
      },



    //Errors  
      health_careErr: "",
      is_activeErr: "",






    };
  },


      methods: {

        //   ...mapActions({
        //     search_dependent: 'dependent/'+SEARCH_DEPENDENT,
        //     link_dependent_support: 'dependent/'+DEPENDENT_LINK_REG,
        //     edit_link_dependent_support:'dependent/'+EDIT_DEPENDENT_LINK_REG
        //   }),




         ...mapMutations({
                 showLoading:LOADING,
         }),







       async reg(){
      
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.health_careVal(this.formData.health_care);
        this.is_activeVal(this.formData.is_active);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   




    if(this.health_care_view_type=="add"){


         try{
            const response = await axios.post("health_cares",this.formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     this.clearFormData();
        notificationBox.succNotifications("Health Care Added Successfully");
        this.$emit("health_careAdded");
              }


         }catch(error){

               //  console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);


               if(error.response.data.data_error)
       {
        notificationBox.errorNotifications(error.response.data.data_error);
       }
          

                  else if(this.errorServers.length > 0)
       {
           notificationBox.errorNotifications("Some Errors Exist");
            this.getServerError();
     
       }
    

         }
 



    }
    else if(this.health_care_view_type=="edit")
    {





         try{
            const response = await axios.patch(`health_cares/${this.formData.health_care_id}`,this.formData);
              if(response.status == 200)
              {

                     this.showLoading(false);
                     this.$refs.health_care_modal_ref.click();
                     this.clearFormData();
        notificationBox.succNotifications("Health Care Edited Successfully");
        this.$emit("health_careAdded");
              }


         }catch(error){

                // console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
        

          
               if(error.response.data.data_error)
       {
        notificationBox.errorNotifications(error.response.data.data_error);
       }
          

           else  if(this.errorServers.length > 0)
       {
             notificationBox.errorNotifications("Some Errors Exist");
            this.getServerError();
     
       }
     

         }
     

           


    }




    
    },





     clearFormData(){
        this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.health_care = "";
      this.formData.health_care_id = "";
      this.formData.is_active = "";
      this.health_careErr = "";
      this.is_activeErr = "";

    },




   getServerError(){

       this.health_careErr = 'health_care' in this.errorServers[0] ? this.errorServers[0].health_care[0] : '';
      this.is_activeErr = 'is_active' in this.errorServers[0] ? this.errorServers[0].is_active[0] : '';


     
           return true;

   },





     health_careVal(value){

      let max = 500;
      let field = "Health Care"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.health_careErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.health_careErr);
     }else{

           this.health_careErr =""; 
          this.errorClient.pop(this.health_careErr);

     }


    },







     is_activeVal(value) {

      let max = 10;
      let field = "Is Active";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.is_activeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.is_activeErr);
     }else{

           if(this.errorClient.includes(this.is_activeErr))
           {
            let pos = this.errorClient.indexOf(this.is_activeErr);
           this.errorClient.splice(pos,1);
            this.is_activeErr =""; 
           }
          
          

     }
    },







    




      





  

      },

      
  computed:{


    // ...mapState('dependent',['dependent_view_type','dependent_info','search_message','search_dependent_result']),
    // ...mapState('dependent_support',['dependent_support_view_type','dependent_support_info']),

    // firstName(){
    //   return this.checked_user_data.fullname;
    // }
  },  

    components: {
      //SpinnerComp,
      //vSelect
      //VueMultiselect
     // Select2
    },

    watch:{

 


   




        crusade_type_view_type(value){
          console.log(value);
           if(value == "add"){
        
           this.clearFormData();
           


           }
        },

        health_care_data(value){
       
         let data = value;
            //console.log(data);
            this.clearFormData();
            this.formData.health_care_id = data.id;
            this.formData.health_care = data.health_care;
            this.formData.is_active = data.is_active;
        
       
          
          
        },


    



    }
        
    }
</script>

<style scoped>


</style>