import Swal from 'sweetalert2'
export default class {
  static succNotifications(mess){

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 6000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


Toast.fire({
  icon: 'success',
  title: mess
})

}





static errorNotifications(mess){
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  
  Toast.fire({
    icon: 'error',
    title: mess
  })
  
  }






  static successAlert(title,mess){
       new Swal({
      title: title,
      text: mess,
      icon: 'success',
      showCancelButton: false,
     // confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
 
    }).then((result) => {
      if (result.isConfirmed) {
        if(localStorage.getItem("Portall") == "Provider")
        {
           window.location = "provider_login";
        }
        else if(localStorage.getItem("Portall") == "Support")
        {
           window.location = "support_login"; 
        }
        else if(localStorage.getItem("Portall") == "Dependent")
        {
           window.location = "dependent_login"; 
        }
        else if(localStorage.getItem("Portall") == "Telemedicine")
        {
           window.location = "telemedicine_login"; 
        }
      }
    })



  
  
  
  }



  

  static showPassword(value) {
    var x = value;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }



  // static delete(id,url){

  //   axios.delete(`url?id=id`)
  //     .then(response => {

  //        return response.data.data

  //     }).catch(error => {
  //        return error.data.data
  //     })
      

  // }



  // static deleteNotification(id){
  //         new Swal({
  //     title: 'Are you sure you want to delete this data?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.$swal.fire(
  //         'Deleted!',
  //         'Your file has been deleted.',
  //         'success'
  //       )
  //     }
  //   })
    
  // }

 

}
        





