<template>
  <div class="dropdown dib">
      <li class="header-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="user-avatar">{{ first_name }}
              <i class="ti-angle-down f-s-10"></i>
          </span>
      </li>
      <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
          <ul class="p-0 mb-0">
              <li>
                  <router-link to="/">
                    <div class="p-2 user-drop-item">
                      <i class="fa-regular fa-user"></i>
                       Profile
                    </div> 
                  </router-link>
              </li>
              <li>
                  <router-link to="/">
                    <div class="p-2 user-drop-item">
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                       Settings
                    </div> 
                  </router-link>
              </li>
              <li>
                  <a href="#" @click="handleLogOut">
                    <div class="p-2 user-drop-item">
                      <i class="fa-solid fa-key"></i>
                       Logout
                    </div> 
                  </a>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
 import axios from 'axios';
 import notificationBox from "@/services/notificationBox";
export default {
  name: "UserDropDownComp",
  props: ['first_name'],
  methods: {
   async handleLogOut(){

          try{
        let response = await axios.post("logout");
        console.log(response);
        if(response.statusText != 'OK')
        {
          throw new Error("Something Went Wrong!!!");
        }
 

            if(localStorage.getItem("Portall")  == "Gerdd"){
         localStorage.clear();
         this.$router.push("/em_login")
      }else if(localStorage.getItem("Portall")  == "Provider"){
        localStorage.clear();
         this.$router.push("/provider_login")
      }else if(localStorage.getItem("Portall")  == "Support"){
        localStorage.clear();
         this.$router.push("/support_login")
      }else if(localStorage.getItem("Portall")  == "Dependent"){
        localStorage.clear();
         this.$router.push("/dependent_login")
      }



        

      }catch(err){

        console.log(err)

     notificationBox.errorNotifications("Could Not Logout");

      }
    }
  },
};
</script>

<style scoped>
.dropdown-menu{
    width: 150px;
    padding: 0;
}

.dropdown-menu li:last-child{
    border-bottom: 0;
}

.user-drop-item{
  color: #444;
}

.user-drop-item:hover{
  color: #6b51df;
}
</style>