<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="makeFinalAllPaymentModal"    aria-labelledby="makeFinalAllPaymentModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="makeFinalAllPaymentModalLabel" v-if="this.view_type=='pay_all'">Make All Payment</h5>
                <button type="button" ref="makeFinalAllPaymentModal" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#makeFinalAllPaymentModal" aria-label="Close"></button>
            </div>
              <div>
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1>Make Final Payment (All Bill)</h1><br/>
                      <p></p>
        
                  <span>
                  
                    <div class="row">

                              <div class="col">
                                <label>Mode Of Payment</label>
                        <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: mode_paymentErr}"
                             @change="mode_paymentVal($event.target.value)"
                            v-model.trim="formData.mode_payment">
                            <option value="">--Choose An Option--</option>
                            <option value="CREDIT CARD">CREDIT CARD</option>
                            <option value="MOBILE MONEY">MOBILE MONEY</option>
                          </select>
                          
                      
                        </div>
                            <div
                            :class="{ text_error: mode_paymentErr}"
                            v-if="mode_paymentErr"
                          >
                            {{ mode_paymentErr }}
                          </div>
                      </div>








                     
                             <div class="col">
                         <label v-if="formData.mode_payment=='CREDIT CARD'">Card Number</label>
                          <label v-if="formData.mode_payment=='MOBILE MONEY'">Mobile Number</label>
                        <div class="input-group">
                          <input
                            v-if="formData.mode_payment=='CREDIT CARD'"
                            type="text"
                            class="form-control"
                            :class="{ form_error: card_mobile_numberErr }"
                            placeholder="CARD Number"
                            @blur="card_mobile_numberVal($event.target.value)"
                            v-model.trim="formData.card_mobile_number"
                          />

                           <input
                            v-if="formData.mode_payment=='MOBILE MONEY'"
                            type="text"
                            class="form-control"
                            :class="{ form_error: card_mobile_numberErr }"
                            placeholder="Mobile Number"
                            @blur="card_mobile_numberVal($event.target.value)"
                            v-model.trim="formData.card_mobile_number"
                          />
                      
                        </div>

                            <div :class="{ text_error: card_mobile_numberErr }" v-if="card_mobile_numberErr">
                            {{ card_mobile_numberErr }} 
                          </div>
                      </div>



             




                    </div>


                    <div class="row">


                                 <div class="col">
                        <label>Over All Emedi Charge</label>
                        <div class="input-group">
                          <input
                          readonly
                            type="text"
                            class="form-control"
                            :class="{ form_error: emedi_balErr }"
                            placeholder="Over All Emedi Charge"
                            @blur="emedi_balVal($event.target.value)"
                            v-model.trim="formData.emedi_bal"
                          />

                       
                        </div>
                           <div :class="{ text_error: emedi_balErr }" v-if="emedi_balErr">
                            {{ emedi_bal }} 
                          </div>
                      </div>










                        <div class="col">
                        <label>Over All Provider's Charge</label>
                        <div class="input-group">
                          <input
                          readonly
                            type="text"
                            class="form-control"
                            :class="{ form_error: prov_balErr }"
                            placeholder="Over All Provider's Charge"
                            @blur="prov_balVal($event.target.value)"
                            v-model.trim="formData.prov_bal"
                          />

                       
                        </div>
                           <div :class="{ text_error: prov_balErr }" v-if="prov_balErr">
                            {{ prov_bal }} 
                          </div>
                      </div>



                        <div class="col">
                        <label>Over All Total Cost</label>
                        <div class="input-group">
                          <input
                          readonly
                            type="text"
                            class="form-control"
                            :class="{ form_error: total_costErr }"
                            placeholder="Over All Total Cost"
                            @blur="total_costVal($event.target.value)"
                            v-model.trim="formData.total_cost"
                          />

                       
                        </div>
                           <div :class="{ text_error: total_costErr }" v-if="total_costErr">
                            {{ total_cost }} 
                          </div>
                      </div>

                    </div>




                
                  </span>
                










                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Make Payment</button>
                <button type="button" class="btn btn-secondary" ref="makeSinglePaymentRemovemodal" data-bs-dismiss="modal">Close</button>
            </div>

                  </form>
                  </div>


              



            </div>
        </div>


           



        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
// import vSelect from 'vue-select';
import notificationBox from "@/services/notificationBox";
// import intlTelInput from 'intl-tel-input';
// import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';


    export default {

        name:'MakeFinalSinglePayment',
        props:{
          view_type:{
            required:true,
            type: String
          },





         billing_id:{
           required:true
         },



        
          emedi_bal:{
            required:true,
          },



            prov_bal:{
            required:true,
          },


   


          total_cost:{
            required:true,
          },

          support_id:{
            required:true,
          }


        },


        components:{
        //  SpinnerComp,
         // vSelect,
        },


           mounted(){



           },

        async created() {
        //  this.showLoading(true);


      //    this.formData.fname = this.firstName;
      
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");

      const user_data = JSON.parse(localStorage.getItem("users"));
          this.formData.provider_id = user_data.provider_id;  
          this.formData.user_id = user_data.id;
        //  console.log(this.formData.provider_id);
 //this.view_data();

    switch(this.app_portal) {
 case 'Dependent':
    this.formData.user_level = "DEPENDENT";
    break;
  case 'Support':
    this.formData.user_level = "SUPPORT";
    break;
  case 'Telemedicine':
    this.formData.user_level = "TELEMEDICINE";
    break;
  case 'Provider':
    this.formData.user_level = "ADMIN";
    break;
  default:
    this.formData.user_level = "";
}



   try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }





   try{
       let response = await axios.get("getProviders");
      // console.log(response.data.data);
         
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }


  // if(this.app_portal === 'Provider')
  // {
  //       try{
  //      let response = await axios.get("getHealthCares");
  //     // console.log(response.data.data);
         
  //       this.getHealthCares =  JSON.parse(JSON.stringify(response.data.data));
  //       //console.log(this.getCountries);
       
       
       
      
  //   }catch(error){
  //       console.log(error);
  //   }
  // }


  

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      loading:false,
      getCountries:[],
      getProviders:[],
      country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      


 


      modal:"modal",


   
      

      formData: {
        mode_payment:"",
        card_mobile_number:"",
        emedi_bal:"",
        prov_bal:"",
        total_cost:"",
        billing_id:[],
        support_id:"",
        user_id:"",
        
        
      },



    //Errors  
      mode_paymentErr:"",
      card_mobile_numberErr:"",
      emedi_balErr:"",
      prov_balErr:"",
      total_costErr:"",









    };
    },


      methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


        async reg(){

    
        
   
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];
      

       this.mode_paymentVal(this.formData.mode_payment);
       this.card_mobile_numberVal(this.formData.card_mobile_number);
       

       

                 if(this.errorClient.length > 0)
       {
        // clearTimeout(bbb);
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
         // console.log(this.errorClient);
          return true;
       }
       else{

        

            try{
               
             const response = await axios.post("makeSinglePayment",this.formData);

                  
               this.showLoading(false);
            if(response.status == 200)
            {
         this.clearFormData();
        notificationBox.succNotifications("Payment Done Successfully");
        this.$emit("paySucc");
        this.$refs.makeSinglePaymentRemovemodal.click();
            }

            }catch(error){

              console.log(error);
             this.errorServers.push("Some Error Exist");
           
           this.showLoading(false);
           notificationBox.errorNotifications(error.response.data.error);

           
             if(this.errorServers.length > 0)
            {       
            this.getServerError();
           }

     

            }

          








       }

        },




       
       getServerError(){

      this.mode_paymenytErr = 'mode_payment' in this.errorServers[0] ? this.errorServers[0].mode_payment[0] : '';
      this.card_mobile_numberErr = 'card_mobile_number' in this.errorServers[0] ? this.errorServers[0].card_mobile_number[0] : '';
      this.emedi_balErr = 'emedi_bal' in this.errorServers[0] ? this.errorServers[0].emedi_bal[0] : '';
      this.prov_balErr = 'prov_bal' in this.errorServers[0] ? this.errorServers[0].prov_bal[0] : '';
      this.total_costErr = 'total_cost' in this.errorServers[0] ? this.errorServers[0].total_cost[0] : '';

       },








        clearFormData(){
        this.formData.mode_payment = "";
        this.formData.card_mobile_number = "";
        this.mode_paymentErr = "";
        this.card_mobile_numberErr = "";





        this.errorClient = [];
      this.errorServers = [];


        //view_portal:"",

        },



        mode_paymentVal(value){

      let field = "Mode Of Payment"

         if(FieldValidateService.reqVal(value, field))
     {
       this.mode_paymentErr = FieldValidateService.FieldValidateService.reqVal(value, field);
        this.errorClient.push(this.mode_paymentErr);
     }else{

           this.mode_paymentErr =""; 
          this.errorClient.pop(this.mode_paymentErr);

     }


    },






     card_mobile_numberVal(){
             let field = "";
            if(this.formData.mode_payment == 'CREDIT CARD')
            {
               field = "Card Number"
            }
            else if(this.formData.mode_payment == 'MOBILE MONEY')
            {
                 field = "Mobile Money"
           }

           let value = this.formData.card_mobile_number;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.card_mobile_numberErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.card_mobile_numberErr);
     }else{

          if(this.errorClient.includes(this.card_mobile_numberErr))
        {
        let pos = this.errorClient.indexOf(this.card_mobile_numberErr);
        this.errorClient.splice(pos,1);
        }
           this.card_mobile_numberErr =""; 

          

     }

    },









   total_costVal(){

       
            let field = "Total Cost"
       

           let value = this.formData.total_cost;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.total_costErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.total_costErr);
     }else{

          if(this.errorClient.includes(this.total_costErr))
        {
        let pos = this.errorClient.indexOf(this.total_costErr);
        this.errorClient.splice(pos,1);
        }
           this.total_costErr =""; 

          

     }

    },








      emedi_balVal(){

       
            let field = "Over All Emedi Charge"
       

           let value = this.formData.emedi_bal;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.emedi_balErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.emedi_balErr);
     }else{

          if(this.errorClient.includes(this.emedi_balErr))
        {
        let pos = this.errorClient.indexOf(this.emedi_balErr);
        this.errorClient.splice(pos,1);
        }
           this.emedi_balErr =""; 

          

     }

    },











          prov_balVal(){

       
            let field = "Over All Provider's Charge"
       

           let value = this.formData.prov_bal;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.prov_balErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.prov_balErr);
     }else{

          if(this.errorClient.includes(this.prov_balErr))
        {
        let pos = this.errorClient.indexOf(this.prov_balErr);
        this.errorClient.splice(pos,1);
        }
           this.prov_balErr =""; 

          

     }

    },






      },


   


      watch:{
           view_type(value){

           if(value == "pay_all")
           {
             this.clearFormData();
           }

          

        },




        billing_id(value){

            this.formData.billing_id = value;
        },

        emedi_bal(value){
         this.formData.emedi_bal = value;
        },


        prov_bal(value){
         this.formData.prov_bal = value;
        },

        total_cost(value){

            this.formData.total_cost = value;
        },

        support_id(value){

            this.formData.support_id = value;
        },

     

      }
        
    }
</script>

<style scoped>


</style>